
import React from "react";

// Icons
import { BsDownload } from "react-icons/bs";
import UserContext from '../../../../../../dataContext'

import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import "react-pdf/dist/esm/Page/TextLayer.css";

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        padding: "10mm 0"

    },

    section: {
        width: '90%',
        margin: "auto",
    },
    sectionText: {
        margin: "0 auto",
        color: '#000',
        fontWeight: "black",
        fontSize: 25,
        padding: 10
    },
    inputTitle: {
        borderCollapse: 'collapse',
        width: '100%',
        flexDirection: 'row'
    },
    area: {
        border: '1 solid #ddd'
    },
    input: {
        border: '0',
        display: 'block',
        padding: 8
    },

    category: {
        flexDirection: 'row',
        gapColumn: '2rem',
        padding: '15 0',
        fontSize: '15',
        borderTop: "1px solid #363538",
        marginTop: "5",
    },
    categoryView: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '2 0',
        fontSize: '15'
    },
    name: {
        width: '100%'
    },
    hr: {
        width: '100%'
    },
    inputDiv: {
        display: 'flex',
        backgroundColor: '#fff',
        width: "100%",
        flexDirection: "row",
    },
    inputDivcheck: {
        width: "45%",
    },
    inputDivdropdown: {
        width: "45%",
    },
    iconDiv: {
        margin: "0",
        backgroundColor: '#eee',
        color: '#777',
        paddingVertical: 8,
        paddingHorizontal: 8,
        textAlign: 'left',
        flex: 2,
        fontSize: '12',
        height: '100%',

    },
    areaDiv: {
        width: '100%',
        paddingVertical: 0,
        paddingHorizontal: 12,
        flex: 3,
        fontSize: '12',
        backgroundColor: "#f4f4f450",
    },
    areaDivcheck: {
        flex: 1
    },
    areaDivdropdown: {
        flex: 1
    },
    iconText: {
        alignSelf: 'center',
        margin: "auto 0"
    },
    inputImage: {
        width: 30,
        height: 30,
        margin: "auto 0"
    },
    imageContainer: {
        display: 'flex',
        flexDirection: "row",
        flexWrap: 'wrap',
        width: '100%',
        gap: "10"
    },
    imageDiv: {
        width: "250"
    },
    image: {
        width: "100%"
    },

});

export default function HomeViewNewPDF(props) {
    const context = React.useContext(UserContext)
    const burl = context.getBurl()
    try {
        JSON.parse(props.data)
    }
    catch (e) {
        console.log(e)
        return <div></div>
    }
    const list = JSON.parse(props.data)
    const document = HomeViewPDFFile(burl, list)

    return (
        <PDFDownloadLink document={document} fileName={props.title} width={"100%"} height={"92%"}>
            {({ blob, url, loading, error }) =>
                loading ? 'Chargement...' : <DownloadButton />
            }
        </PDFDownloadLink >
    )
}

function DownloadButton() {
    return (
        <button className={"btn btn-warning"}>
            <BsDownload />
        </button>
    )
}

function HomeViewPDFFile(burl, list) {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View key={burl} style={styles.section}>
                    {list?.map((item, indexCategory) => {
                        const images = item.images?.map((image) => {
                            return burl + "/uploads/" + image
                        })
                        return (
                            <View key={indexCategory}>
                                <Text style={styles.sectionText}>{item.title}</Text>
                                {item.content?.map((item, indexSection) => {
                                    return (
                                        <View key={indexSection}>

                                            <Text style={styles.category}>{item.title}</Text>
                                            <View>
                                                {item.type === "text" ? (
                                                    item.fields?.map((field, indexSingle) => {
                                                        if (field.type === "check") {
                                                            field.value = Boolean(field.value);
                                                        }
                                                        let inputDiv = `inputDiv${field.type}`;
                                                        let areaDiv = `areaDiv${field.type}`;
                                                        let iconDiv = `iconDiv${field.type}`;
                                                        return (
                                                            <View style={styles.categoryView} key={item}>
                                                                <View style={[styles.inputDiv, styles[inputDiv]]}>
                                                                    <View style={[styles.iconDiv, styles[iconDiv]]}>
                                                                        <Text style={styles.iconText}>{field.title}</Text>
                                                                    </View>
                                                                    <View style={[styles.areaDiv, styles[areaDiv]]}>
                                                                        {
                                                                            (() => {
                                                                                switch (field.type) {
                                                                                    case "input":
                                                                                        return (<Text style={styles.input}>{field.value}</Text>)
                                                                                    case 'textarea':
                                                                                        const chunks = splitText(field.value, 600);
                                                                                        return (
                                                                                            <View>
                                                                                                {
                                                                                                    chunks?.map((chunk, index) => (
                                                                                                        <Text key={index}>{chunk}</Text>
                                                                                                    ))
                                                                                                }
                                                                                            </View>
                                                                                        )
                                                                                    case 'check':
                                                                                        return <Image src={field.value ? "https://i.ibb.co/sQTd23q/check-Black.png" : "https://i.ibb.co/LzK7SdM/cross-svgrepo-com-1.png"}
                                                                                            style={[styles.input, styles.inputImage]}></Image>
                                                                                    default:
                                                                                        return <Text style={styles.input}>{field.value === "" ? field.options[0] : field.value}</Text>
                                                                                }
                                                                            })()
                                                                        }
                                                                    </View>
                                                                </View>

                                                            </View>)


                                                    })
                                                ) : (

                                                    <View style={styles.categoryView} key={item}>
                                                        {
                                                            item.fields?.map((field, indexSingle) => {
                                                                return (

                                                                    <View style={[styles.inputDiv, styles.inputDiv + field.type]}>
                                                                        <View style={styles.iconDiv}>
                                                                            <Text style={styles.iconText}>{field.title}</Text>
                                                                        </View>
                                                                        <View style={[styles.areaDiv,]}>
                                                                            {field.type === "input" ? (
                                                                                <Text style={styles.input}>{field.value}</Text>
                                                                            )
                                                                                : field.type === "textarea" ? (
                                                                                    <Text style={styles.input}>{field.value}</Text>
                                                                                )
                                                                                    : field.type === "check" ? (
                                                                                        <Image src={field.value ? "https://i.ibb.co/sQTd23q/check-Black.png" : "https://i.ibb.co/LzK7SdM/cross-svgrepo-com-1.png"}
                                                                                            style={[styles.input, styles.inputImage]}></Image>
                                                                                    ) : (
                                                                                        <Text style={styles.input}>{field.value === "" ? field.options[0] : field.value}</Text>
                                                                                    )}
                                                                        </View>
                                                                    </View>

                                                                )

                                                            })
                                                        }
                                                    </View>
                                                )}
                                            </View>
                                        </View>
                                    );


                                })}
                                <Text style={styles.category}>Images</Text>
                                <View style={styles.imageContainer}>
                                    {images?.map((url, index) => (
                                        <View style={styles.imageDiv}>
                                            <Image style={styles.image} src={url} />
                                        </View>
                                    ))}
                                </View>
                            </View>
                        )
                    })}
                </View>
            </Page>
        </Document>
    )
}

function splitText(text, maxLength) {
    let chunks = [];
    let value = text;
    if (value.length <= maxLength) {
        return [value];
    } else {
        while (value.length > maxLength) {
            let splitIndex = findLast(value.substr(0, maxLength), new Set([" ", "\n"]));
            if (splitIndex === null) {
                chunks.push(value.substr(0, maxLength));
                value = value.substr(maxLength);
            } else {
                chunks.push(value.substr(0, splitIndex + 1));
                value = value.substr(splitIndex + 1);
            }
        }
        chunks.push(value)
        return chunks;
    }
}


/**
 * @param {string} string
 * @param {Set<string>} chars
 */
function findLast(string, chars) {
    for (let i = string.length - 1; i >= 0; i--) {
        if (chars.has(string[i])) return i;
    }
    return null;
}