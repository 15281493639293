import React from "react";
import Overlay from "../../Subcomponents/Overlay/Overlay";
import Modal from "../../Modals/Modal";

export default function ListItemsSeparated(props) {
    const [max] = React.useState(props.max ? props.max : 10)
        , [list, setList] = React.useState([])
        , [itemModification, setItemModification] = React.useState({})
        , [maxModal, setMaxModal] = React.useState(6)
        , [selected, setSelected] = React.useState()


    React.useEffect(() => {
        if (list.length === 0) {
            setList(groupBy(props.data))
        }
    }, [list, setList, props])

    return (
        <div className={"w-100 mt-1"}>
            <div className={"row ps-2"}>
                {list.map((li, index) => {
                    return (
                        <div className={"col-6 col-md mt-1"} key={index}>
                            {li.title ? <h5 className={"text-capitalize black"}>{li.title}</h5> : null}
                            <ul className={"list-group"}>
                                {
                                    li.items.map((i, index2) => {
                                        if (index2 >= max) return null
                                        else return (
                                            <li className="list-group-item list-item" key={index2} onClick={() => {
                                                const item = i.data
                                                item.choices = "InterventionModify"
                                                setItemModification(item)
                                            }}>
                                                <h5>{i.title}</h5>
                                                <p>{i.description}</p>
                                            </li>
                                        )
                                    })
                                }
                                {moreIcon(li.items, max, li.title, setSelected)}
                            </ul>
                        </div>
                    )
                })}
            </div>
            {ModalSelection(selected, setSelected, maxModal, setMaxModal, setItemModification)}
            <Overlay item={itemModification}
                     close={() => {
                         setItemModification(false)
                     }}
                     edit={(value) => {
                         setItemModification(value)
                     }}/>
        </div>
    )
}

function moreIcon(list, max, title, selected) {
    if (!list) return null
    const len = list.length - max
    if (list.length === 0) {
        return (
            <li className="list-group-item more-icon">
                <div className={"row"}>
                    Pas de nouvelles réservatons
                </div>
            </li>
        )

    } else if (len <= 0) {
        return (
            <></>
        )

    } else {
        return (
            <li className="list-group-item more-icon">
                <div className={"row"} onClick={() => {
                    selected({title: title, items: list})
                }}>
                    + {len}
                </div>
            </li>
        )
    }
}

function toLocal(date) {
    const options = {weekday: 'long', day: 'numeric', month: '2-digit'}
    return date.toLocaleDateString('fr-FR', options)
}

function groupBy(list) {
    const l = []
    list.forEach(item => {
        const date = toLocal(item.date)
        if (l.some(i => i.title === toLocal(item.date))) {
            l.filter((i) => i.title === date)[0].items.push(item)
        } else {
            l.push({
                title: date,
                items: [item]
            })
        }
    })
    return l
}

function ModalSelection(list, setList, max, setMax, setItemModification) {
    if (!list) return null
    return (
        <Modal show={list} close={() => setList()}
               classes={{
                   title: "light",
                   header: "warning-bg"
               }} title={list.title}>
            <ul className={"list-group"}>
                {list ?
                    list.items.map((item, index) => {
                        if (index < max) {
                            return (
                                <div>
                                    <li className="list-group-item">
                                        <div className={"row"} onClick={() => {
                                            const sel = item.data
                                            sel.choices = "InterventionModify"
                                            setItemModification(sel)
                                        }}>
                                            <div className={"col-10"}>
                                                <h5>{item.title}</h5>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    </li>
                                    {index + 1 === list.items.length ?
                                        <li className="list-group-item more-icon">
                                            <div className={"row"}>
                                                <p>...</p>
                                            </div>
                                        </li>
                                        :
                                        null
                                    }
                                </div>
                            )
                        }
                        else if (index === max) {
                            return (
                                <li className="list-group-item more-icon" onClick={() => {
                                    setMax(max + 2)
                                }}>
                                    <div className={"row"}>
                                        <button type="button" className="btn btn-warning">
                                            En voir plus
                                        </button>
                                    </div>
                                </li>
                            )
                        }
                        else {
                            return null
                        }
                    })
                    :
                    null
                }
            </ul>
        </Modal>
    )
}