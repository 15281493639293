// External components
import React from "react";

// Internal Components
import Overlay from "../../Subcomponents/Overlay/Overlay";
import { BsInfoCircleFill } from "react-icons/bs";

import "bootstrap"


export default function ListItemPriority(props) {
    const [itemModification, setItemModification] = React.useState({})

    return (
        <div className={"card-container first-component"} {...props}>

            {props.data.map((list, index) => {
                return (
                    <div key={index} style={{ display: "contents" }}>
                        {
                            list.items
                                .sort((a, b) => b.priority - a.priority)
                                .map((item, index) => {
                                    let date = new Date(item.data.dates.start)
                                    let limit = new Date()
                                    limit.setMonth(limit.getMonth() + 3)
                                    if (index < 3 && !(limit.getTime() - date.getTime() < 0)) {
                                        return (
                                            <li className="list-group-item" key={index + item.title} onClick={() => {
                                                setItemModification(item.data)
                                                console.log(item.data)
                                            }}>
                                                <div>
                                                    <div>
                                                        {circleColor(item.priority)}
                                                    </div>
                                                    <div>
                                                        <h5>{item.title} - <span className="big-title">{item.subtitle}</span></h5>
                                                        {/* <p>{item.description}</p> */}
                                                        <button style={{ background: 'none', border: 'none', padding: 0, color: '#069', textDecoration: 'underline', cursor: 'pointer' }} title="" data-tooltip={ReturnErrors(item.data.errors)} data-tooltip-location="right">
                                                            <p className="info">{item.description}<BsInfoCircleFill className="info"></BsInfoCircleFill></p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    } else {
                                        return null
                                    }
                                })

                        }</div>
                )
            })
            }
            <Overlay item={itemModification}
                close={() => {
                    setItemModification(false)
                }}
                edit={(value) => {
                    setItemModification(value)
                }} />
        </div>
    )
}





function circleColor(value) {
    if (value >= 30) {
        return <div className={"colorDiv danger-bg"} />
    }
    if (value >= 10) {
        return <div className={"colorDiv warning-bg"} />
    }
    if (value >= 0) {
        return <div className={"colorDiv success-bg"} />
    } else return <div className={"colorDiv dark-bg"} />
}


function ReturnErrors(ErrorsArray) {
    let ErrorsString = ''
    ErrorsArray.forEach(error => ErrorsString += String(error.name) + '\n')
    return ErrorsString
}