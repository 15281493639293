import React from "react";
import { HiQuestionMarkCircle } from "react-icons/hi";

export default function BooleanTextError(props) {
    if (props.value) {
        return (
            <p style={{ fontWeight: (props.error ? "600" : "") }} className={(!props.error ? "BooleanText " : "BooleanText error ") + props.className}>
                {props.error ?
                    <HiQuestionMarkCircle data-bs-toggle="tooltip" title={props.tooltips} />
                    : null}
                {props.title} : <br /> <span>{props.value} </span>
            </p>
        )
    }
    return (
        <p className={(!props.error ? "BooleanText " : "BooleanText error ") + props.className}>
            {props.error ?
                <HiQuestionMarkCircle data-bs-toggle="tooltip" title={props.tooltips} />
                : null}
            {props.title} :
        </p>
    )
}