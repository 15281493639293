import React from "react";
// Icons

import {BsCheck} from "react-icons/bs";
import SendButton from "../../Button/SendButton";


export default function ValidateIntervention(props) {
    const [loading, setLoading] = React.useState(false)
        , [report, setReport] = React.useState("")
        , [list, setList] = React.useState([])

    const handleResize = function (e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }


    return (
        <form className={"home-view-add-container"} onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (loading) return null
            setLoading(true)
            try {
                props.send({report: report, list: list})
                setLoading(false)
            } catch (err) {
                setLoading(false)
            }
        }}>
            <div className="input">
                <div className="icon">Description</div>
                <div className="area">
                                    <textarea className={"input-overlay"}
                                              rows={5}
                                              required
                                              onChange={(e) => {
                                                  handleResize(e)
                                                  setReport(e.target.value)
                                              }}/>
                </div>
            </div>
            <div className="input">
                <div className="icon">Photos</div>
                <div className="area">
                    <input className={"input-overlay"} onChange={(e) => {
                        setList(e.target.files)
                    }} type={"file"}
                           required
                           accept="image/png, image/jpeg" multiple/>
                </div>
            </div>
            <hr/>
            <SendButton loading={loading} type={"submit"}
                        className={"btn btn-warning w-100"}><BsCheck/> Envoyer la validation</SendButton>
        </form>
    )
}