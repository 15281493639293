//External components
import React from "react";

//Internal components
import Header from "../Components/Menu/Header";
import SideBar from "../Components/Menu/SideBar";

export default function Menu(props) {
    const [active, setActive] = React.useState(false)

    return (
        <div className={"wrapper"}>
            <SideBar active={{ active: active, setActive: setActive }} />
            <div id={"content"}>
                <Header active={{ active: active, setActive: setActive }} />
                <div className={"align-items-center min-content pt-4"} style={{ background: "#f6f6f6" }}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}