import React from "react";

// Components

import SendButton from "../../../../Button/SendButton";
import ImagesFits from "../../../../ImagesLists/ImagesFits";

// Icons
import { BsTrashFill, BsCheck } from "react-icons/bs";


const defaultSection = {
    title: "",
    images: [],
    text: "",
    deletable: true
}


export default function ModifyHomeView(props) {
    const [list, setList] = React.useState(props.homeView)
    const [loading, setLoading] = React.useState(false)
    const [, r] = React.useState({})


    const reload = function () {
        r({})
    }

    const handleResize = React.useCallback((e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }, [])

    const addSection = function () {
        const listTemp = list
        listTemp.push(defaultSection)
        setList(listTemp)
        reload()
    }

    const deleteSection = function (index) {
        const listTemp = list
        listTemp.splice(index, 1)
        setList(listTemp)
        reload()
    }

    const changeValue = function (e, index, value) {
        const listTemp = list
        listTemp[index]["text"] = value ? value : e.target.value
        setList(listTemp)
        reload()
    }

    React.useEffect(() => {
        const tas = document.getElementsByTagName("textarea")
        for (let i = 0; i < tas.length; i++) {
            tas[i].style.height = `${tas[i].scrollHeight}px`
        }
    }, [handleResize])
    return (
        <>
            <form className={"home-view-add-container"} onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (loading) return null
                setLoading(true)
                try {
                    props.send(list)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                }
            }}>
                <div className={"home-view-header"}>
                    <h3>Publier la fiche home </h3>
                    <SendButton loading={loading} type={"submit"}
                        className={"btn"}><BsCheck /></SendButton>
                </div>
                {list?.map((item, index) => {
                    return (
                        <div className={"section"} key={index}>

                            <div className="input-title">
                                <div className="area">
                                    <input name={"title"} type={"text"}
                                        onChange={(e) => {
                                            changeValue(e, index)
                                        }} defaultValue={item.title} />
                                </div>
                                <button className={"btn-delete"} hidden={!item.deletable} onClick={() => {
                                    deleteSection(index)
                                }}><BsTrashFill />
                                </button>
                            </div>
                            <div className="category">
                                <div className="input type-textarea">
                                    <div className="area">
                                        <textarea
                                            name={"textarea"}
                                            onChange={(e) => {
                                                handleResize(e);
                                                changeValue(e, index);
                                            }}
                                            value={item.text}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ImagesFits data={item.defaultImages?.map((photo => props.burl + '/uploads/' + photo))}
                                    onError={(e) => {
                                        e.target.onerror = null; // prevents looping
                                        e.target.src = "https://i.ibb.co/3NxyCrY/img-Not-Found.png";
                                    }}
                                    errorText={"texte d'erreur"} />
                            </div>
                            <div className="input">
                                <div className="icon">Photos</div>
                                <div className="area">
                                    <input name={"images"} onChange={(e) => {
                                        changeValue(e, index, e.target.files)
                                    }} type={"file"}
                                        accept="image/png, image/jpeg" multiple />
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className={"section"}>
                    <button className={"btn-add"} type={"button"} onClick={() => {
                        addSection()
                    }}>Ajouter une nouvelle section
                    </button>
                </div>
            </form>
        </>
    )
}