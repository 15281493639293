// EXTERNAL MODULES
import React from "react";
import Calendar from "@toast-ui/react-calendar";

// CSS

import "tui-calendar/dist/tui-calendar.css";
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import moment from "moment";
import { BsArrowLeftShort, BsArrowRightShort, BsPlus, BsSearch } from "react-icons/bs";
import Suggestion from "../Subcomponents/input/Suggestions";

//context
import UserContext from '../../dataContext'
import { keyPerformanceIndicators } from "../../Utils/functions";

export default function IndividualCustom(props) {

    // console.log("PROPS",props)
    let calendar = React.createRef()
    const context = React.useContext(UserContext)
    // console.log("CONTEXT",context)

    const [selectedItem, setSelectedItem] = React.useState({})
        , [loading, setLoading] = React.useState(true)
        , [active, setActive] = React.useState(false)
        , [search, setSearch] = React.useState('')
        , [type, setType] = React.useState('')
    //Filtrage des types de personnes
    const orderData = React.useCallback(() => {
        const list = []
        if (selectedItem.type === "Client") {
            for (const property of selectedItem.properties) {
                for (const booking of property.bookings) {
                    if (!booking.cancelled) {
                        const start = new Date(booking.dates.start)
                        const end = new Date(booking.dates.end)
                        list.push({
                            id: booking.id,
                            calendarId: '0',
                            category: 'time',
                            dueDateClass: '',
                            title: booking.platform + " " + property.name,
                            raw: {
                                item: booking,
                                type: "booking"
                            },
                            start: start.toISOString(),
                            end: end.toISOString()
                        })
                    }
                }
                for (const intervention of property.interventions) {
                    const start = new Date(intervention.date.dateStart)
                    const end = new Date(intervention.date.dateStart)
                    end.setHours(end.getHours() + parseInt(intervention.date.duration.split(":")[0]))
                    end.setMinutes(end.getMinutes() + parseInt(intervention.date.duration.split(":")[1]))
                    const isCancelledTitle = intervention.cancelled ? "[🚨 Annulé 🚨] " : ""

                    list.push({
                        id: intervention.id,
                        calendarId: '1',
                        title: isCancelledTitle + intervention.type + ' ' + property.name,
                        category: 'time',
                        dueDateClass: '',
                        raw: {
                            item: intervention,
                            type: "intervention"
                        },
                        start: start.toISOString(),
                        end: end.toISOString()
                    })
                }
            }
        }
        if (selectedItem.type === "Concierge") {
            for (const client of context.data.people.Client) {
                if (!client.archive) {
                    for (const property of client.properties) {
                        for (const intervention of property.interventions) {
                            if (intervention.people.some(el => {
                                if (!el) return false
                                return el.people === selectedItem.id
                            })) {
                                const start = new Date(intervention.date.dateStart)
                                const end = new Date(intervention.date.dateStart)
                                end.setHours(end.getHours() + parseInt(intervention.date.duration.split(":")[0]))
                                end.setMinutes(end.getMinutes() + parseInt(intervention.date.duration.split(":")[1]))
                                const isCancelledTitle = intervention.cancelled ? "[🚨 Annulé 🚨] " : ""
                                list.push({
                                    id: intervention.id,
                                    calendarId: '1',
                                    title: isCancelledTitle + intervention.type + ' ' + property.name,
                                    category: 'time',
                                    dueDateClass: '',
                                    raw: {
                                        item: intervention,
                                        type: "intervention"
                                    },
                                    start: start.toISOString(),
                                    end: end.toISOString()
                                })
                            }
                        }
                    }
                }
            }
        }
        const item = selectedItem
        item.eventsList = list
        setSelectedItem(item)
        setLoading(false)
    }, [selectedItem, setSelectedItem, setLoading, context])

    React.useEffect(() => {
        if (calendar.current && calendar.current.calendarInst) {
            calendar.current.calendarInst.setDate(new Date(props.options.filters.date))
        }
        if (selectedItem.type) {
            orderData()
        } else {
            setLoading(false)
        }
    }, [props, calendar, selectedItem, setLoading, orderData])

    if (loading) {
        return (
            <p>
                Chargement
            </p>
        )
    }

    if (!selectedItem.name) {
        return (
            <div className={"w-100 bg-light black full-width-grid"}>
                <div className={"col 12"}>
                    <div className={"row"}>
                        <h3 className={'text-center'}>Selectionner un individu</h3>
                    </div>
                    <div className={"row p-3"}>
                        <div className={"input-group"}>
                            <form className={"filter-suggestions"}>
                                <label>Recherche parmis: </label>
                                <select name="typeOfPeople" id="peopleChoice" placeholder={"Selectionner"} onChange={(e) => { setType(e.target.value) }}>
                                    <option value="">-- Tous --</option>
                                    <option value="Client">Clients</option>
                                    <option value="Equipe">Equipe</option>
                                    <option value="Concierge">Concierges</option>
                                    <option value="Prestataires">Prestataires</option>
                                </select>
                            </form>
                            <input type={"search"} id={"form1"}
                                className={"form-control"} placeholder={"Rechercher"}
                                name={"search"}
                                autoComplete={"off"}
                                onChange={(e) => setSearch(e.target.value)}
                                onFocus={() => {
                                    setActive(true)
                                }} />
                            <button type={"button"} className={"btn btn-tertiary"}>
                                <BsSearch />
                            </button>
                            <Suggestion
                                active={active}
                                id={"suggestion"} data={context.data.list}
                                search={search} type={type}
                                onFocus={() => {
                                    setActive(true)
                                }}
                                onBlur={() => {
                                    setActive(false)
                                }}
                                click={(value) => {
                                    setLoading(true)
                                    setSelectedItem(value)
                                }} />
                        </div>

                    </div>
                </div>
            </div>
        )
    }
    let [validateSchedules, plannedSchedules, totalSchedules, regularSchedules, cancelledSchedules] = keyPerformanceIndicators(selectedItem, props.date);

    return (
        <div className={"w-100 bg-light black full-width-grid"}>
            <div className={"col 12"}>
                <div className={"row row-cols-auto justify-content-between mx-1 align-items-center"}>
                    <div className={"d-flex justify-content-around my-4 align-items-center"}>
                        <button className={"btn btn-secondary w-100"} style={{ borderRadius: 0 }} onClick={props.previous}>
                            <h3><BsArrowLeftShort /></h3>
                        </button>
                        <button className={"btn btn-secondary w-100"} style={{ borderRadius: 0 }} onClick={() => {
                            props.add({ choices: "-" })
                        }}>
                            <h3><BsPlus /></h3>
                        </button>
                        <button className={"btn btn-secondary w-100"} style={{ borderRadius: 0 }} onClick={props.next}>
                            <h3><BsArrowRightShort /></h3>
                        </button>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <h3 className={"text-center py-2"}>Infos interventions</h3>
                        <ul className="d-flex flex-wrap justify-content-center align-items-center w-50 gap-3">
                            <li className="">Heures validées : <strong>{validateSchedules}</strong></li>
                            <li className="">Heures plannifiées: <strong>{plannedSchedules}</strong> </li>
                            <li className="">Heures totales: <strong>{totalSchedules}</strong></li>
                            <li className="" title="attention, pour le moment fluctue car on enlève des heures aux contrats parfois pour les mettre en 'P' faute de pouvoir créer une inter dans l'abonnement sans récurrence ">Heures régulières : <strong>{regularSchedules}</strong></li>
                            <li className="">Heures annulées: <strong>{cancelledSchedules}</strong></li>
                        </ul>

                    </div>
                    <div className={"d-flex h-100 justify-content-center"}>
                        <h3>{selectedItem.lastName} {selectedItem.name}
                            <button type={"button"} className={"btn btn-tertiary mx-2"} onClick={() => {
                                setSelectedItem({})
                            }}>x
                            </button>
                        </h3>

                    </div>
                </div>
            </div>
            <Calendar
                ref={calendar}
                height={props.options.height}
                calendars={[
                    {
                        id: '0',
                        name: 'Bloquage',
                        bgColor: '#9e5fff',
                        borderColor: '#9e5fff'
                    },
                    {
                        id: '1',
                        name: 'Intervention',
                        bgColor: '#00a9ff',
                        borderColor: '#00a9ff'
                    }
                ]}
                disableDblClick={true}
                disableClick={false}
                onClickSchedule={function (schedule) {
                    props.select({
                        item: schedule.schedule.raw.item,
                        type: schedule.schedule.raw.type,
                        position: {
                            x: schedule.event.clientX,
                            y: schedule.event.clientY
                        }
                    })
                }}
                view={props.options.filters.view}
                template={{
                    timegridDisplayPrimaryTime: function (time) {
                        return time.hour + ':00';
                    },
                    monthDayname: function (dayname) {
                        return '<span class="calendar-week-dayname-name">' + dayname.label + '</span>';
                    },
                    popupDetailDate: function (isAllDay, start, end) {
                        let isSameDate = moment(start).isSame(end);

                        if (isAllDay) {
                            return moment(start).format('YYYY.MM.DD') + (isSameDate ? '' : ' - ' + moment(end).format('YYYY.MM.DD'));
                        }

                        if (start.getFullYear() === end.getFullYear() &&
                            start.getMonth() === end.getMonth() &&
                            start.getDate() === end.getDate()) {
                            return (new Date(start).toLocaleString('fr', {
                                month: "long",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit"
                            }) + " - " + new Date(end).toLocaleString('fr', {
                                hour: "2-digit",
                                minute: "2-digit"
                            }))
                        } else {
                            return (new Date(start).toLocaleString('fr', {
                                month: "long",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit"
                            }) + " - " + new Date(end).toLocaleString('fr', {
                                month: "long",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit"
                            }))
                        }
                    },
                    popupEdit: function () {
                        return 'Modifier';
                    },
                    popupDetailBody: function (schedule) {
                        return 'Body : ' + schedule.body;
                    }
                }}
                option={{
                    zones: [
                        {
                            timezoneName: 'Europe/Paris',
                            displayLabel: 'UTC+01:00',
                            tooltip: 'Paris'
                        }
                    ],
                    setDate: (e) => {
                        console.log(e)
                    }
                }}
                month={{
                    daynames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                    startDayOfWeek: 1
                }}
                week={{
                    daynames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                    hourStart: 5,
                    startDayOfWeek: 1
                }}
                scheduleView={['time']}
                schedules={selectedItem.eventsList}
                isReadOnly={true}
                useCreationPopup={false}
                useDetailPopup={false} />
        </div>
    )
}