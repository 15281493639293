import React from 'react'
import UserContext from "../dataContext";

//icons
import { BsPlus } from "react-icons/bs";
import Overlay from "../Components/Subcomponents/Overlay/Overlay";
import Spinner from '../Components/Spinner';

export default function Users(props) {
    const userContext = React.useContext(UserContext)

    const [item, setItem] = React.useState({})

    if (userContext.loading) {
        return (
            <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div className={"col-auto\n"}>
                        <h1>Equipe interne</h1>
                    </div>
                    <div className={"col-auto\n"}>
                        <button type={"button"} className={'btn btn-outline-dark'} onClick={() => {
                            let object = {}
                            object.choices = "Equipe"
                            setItem(object)
                        }}>
                            <h3><BsPlus /></h3>
                        </button>
                    </div>
                </div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Téléphone</th>
                            <th>Email</th>
                            <th>Contract</th>
                            <th>Ville de résidence</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={6}>
                                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
            <div className={"row justify-content-between"}>
                <div className={"col-auto\n"}>
                    <h1>Equipe interne</h1>
                </div>
                <div className={"col-auto\n"}>
                    <button type={"button"} className={'btn btn-outline-dark'} onClick={() => {
                        let object = {}
                        object.choices = "Equipe"
                        setItem(object)
                    }}>
                        <h3><BsPlus /></h3>
                    </button>
                </div>
            </div>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Téléphone</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {userContext.data.people.Equipe.map((user) => {
                        if (user.archive) return null
                        return (
                            <tr onClick={() => {
                                let object = user
                                object.choices = "EquipeModify"
                                setItem(object)
                            }}>
                                <td>{user.lastName}</td>
                                <td>{user.name}</td>
                                <td>{user.phone}</td>
                                <td>{user.email}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Overlay item={item}
                close={() => {
                    setItem(false)
                }}
                edit={(value) => {
                    setItem(value)
                }} />
        </div>
    )
}