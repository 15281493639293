import { useEffect, useRef, useState } from "react";
import { BsTrashFill, BsPencilSquare, BsCheckLg, BsFillXCircleFill } from "react-icons/bs";

export default function Option({ option, handleOptionDelete, handleOptionSave, isEditingField }) {
    const [editedOption, setEditedOption] = useState(option);
    const [isEditing, setIsEditing] = useState(false);
    const originalOption = useRef(option);

    const handleEdit = () => {
        setIsEditing(true);
        originalOption.current = editedOption;
    }

    const handleCancel = () => {
        setIsEditing(false);
        setEditedOption(originalOption.current);
    }

    const handleSave = () => {
        handleOptionSave(editedOption, option);
        setIsEditing(false);
    }

    useEffect(() => {
        setEditedOption(option)
    }, [option])

    return (
        <div className='my-1 dropdown-element' key={option}>
            <input
                className='form-control col-6'
                value={editedOption}
                onChange={(e) => setEditedOption(e.target.value)}
                disabled={!isEditing}
            />
            <div>
                {isEditing ? (
                    <>
                        <button className='btn btn-primary btn-delete px-2 me-2 btn-accordion' onClick={handleCancel}><BsFillXCircleFill /></button>
                        <button className='btn btn-primary btn-save btn-accordion' onClick={handleSave}><BsCheckLg /></button>
                    </>
                ) : (
                    <>
                        <button hidden={!isEditingField} className='btn btn-primary btn-delete px-2 me-2 btn-accordion' onClick={() => handleOptionDelete(option)}><BsTrashFill /></button>
                        <button hidden={!isEditingField} className='btn btn-primary btn-edit btn-accordion' onClick={handleEdit}><BsPencilSquare /></button>
                    </>
                )}
            </div>
        </div>
    )
}
