// External components
import React from "react";

// Icons
import { BsXCircle } from "react-icons/bs";

// Context
import DataContext from "../../dataContext";
import "../../assets/scss/General/fancyButton.scss"

export default function ErrorModal(props) {
    const context = React.useContext(DataContext)

    if (!context.error.message) return null

    return (
        <div className={"overlay-modal"}>
            <div id="myModal" className={"modal fade show"} aria-modal="true">
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div
                            className={"modal-header justify-content-center " + (context.error.level ? context.error.level : 'danger')}>
                            <div className="icon-box">
                                <BsXCircle className={'icon'} />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <div className={'row'}>
                                <h4>Oooops!</h4>
                            </div>
                            <div className={'row'}>
                                <p>{context.error.message}</p>
                            </div>
                            <div className={'buttons'}>
                                <button className="btn btn-success fancy-button-blue" onClick={() => {
                                    if (context.error.btn1Action) {
                                        context.error.btn1Action()
                                    }
                                    context.setError({})
                                }}>{context.error.btn1Text ? context.error.btn1Text : "Recharger la page"}
                                </button>
                                <button hidden={context.error.btn2hidden} className="btn btn-warning fancy-button-red" onClick={() => {
                                    if (context.error.btn2Action) {
                                        context.error.btn2Action()
                                    }
                                    if (!context.error.btn2ActionAvoidClose) context.setError({})
                                }}>{context.error.btn2Text ? context.error.btn2Text : "fermer"}
                                </button>
                                {context.error.btn3Action ?
                                    <button className="btn btn-warning fancy-button-red" onClick={() => {
                                        if (context.error.btn3Action) {
                                            context.error.btn3Action()
                                        }
                                        if (!context.error.btn3ActionAvoidClose) context.setError({})
                                    }}>{context.error.btn3Text ? context.error.btn3Text : "fermer"}
                                    </button>

                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}