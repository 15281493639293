//External components
import React from "react";
import { BsFillPeopleFill, BsPersonBoundingBox, BsWrench } from "react-icons/bs"

//Internal components
import ContentCard from "../Components/Cards/ContentCard";
import RowContent from "../Components/Cards/RowContent";
import ResourcesUpdate from "../Components/Cards/DataVisualization/ResourcesUpdate";
import ListItemPriority from "../Components/Cards/DataVisualization/listItemPriority";
import ChartsPie from "../Components/Cards/DataVisualization/ChartsPie";
import ListItem from "../Components/Cards/DataVisualization/ListItem";
import ListItemsSeparated from "../Components/Cards/DataVisualization/ListItemsSeparated";

//Context
import UserContext from '../dataContext'
import Loader from "../Components/Subcomponents/loader/loader";


export default function Dashboard() {
    const userContext = React.useContext(UserContext)

    const [loading, setLoading] = React.useState(true)
        , [lastBookings, setLastBookings] = React.useState([])
        , [nextIntervention, setNextIntervention] = React.useState([])
        , [bookingError, setBookingError] = React.useState([])
        , [pie, setPie] = React.useState({
            labels: ['Location', 'Abonnements', 'Prestations', 'Externes'],
            labelSuffix: " €",
            datasets: [{
                label: "Revenues du mois",
                data: [],
                backgroundColor: [
                    '#ff4747',
                    '#408697',
                    '#f5a623',
                    '#71c016'
                ],
                borderColor: [
                    '#00000000',
                    '#00000000',
                    '#00000000',
                    '#00000000',
                ]
            }],
        })

    React.useEffect(() => {
        if (Object.keys(userContext.data).length === 0 && !userContext.loading) {
            userContext.apiReducer('fetchData')
        }
        if (userContext.loading) {
            return null
        }
        if ((lastBookings.length === 0 || nextIntervention.length === 0 || bookingError.length === 0) && !!userContext.data.people) {
            const listBookings = []
            const listIntervention = []
            const listPie = [0, 0, 0, 0]
            let listErrors = []

            const today = new Date()
            today.setHours(0, 0, 0, 0)

            const dMinus7 = new Date()
            dMinus7.setDate(today.getDate() - 7)

            const dPlus7 = new Date()
            dPlus7.setDate(today.getDate() + 7)

            const dPlus30 = new Date()
            dPlus30.setDate(today.getDate() + 30)

            const dBeginMonth = new Date(today.getFullYear(), today.getMonth(), 1)
            const dEndMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

            userContext.data.people.Client.map((client) => {
                client.properties.map((property) => {
                    property.bookings.map((booking) => {
                        const start = new Date(booking.dates.start)
                        const end = new Date(booking.dates.end)
                        if (!booking.cancelled) {
                            if (
                                start >= today
                                && booking.errors.length > 0
                                && !client.archive
                            ) {
                                listErrors.push({
                                    title: formatDate(start) + ' - ' + formatDate(end) + " : " + client.lastName + "." + client.name[0],
                                    subtitle: property.name,
                                    description: booking.errors.length + " Problèmes détectés.",
                                    priority: getDaysBetween(start, dPlus30),
                                    data: booking
                                })
                            }
                            if (start >= dBeginMonth && start <= dEndMonth && booking.prices.night) {
                                listPie[0] += booking.prices.night
                            }

                            if (booking.created_at) {
                                if (new Date(booking.created_at) > dMinus7) {
                                    listBookings.push({
                                        data: booking,
                                        title: formatDate(start) + ' - ' + formatDate(end) + " : " + client.lastName + "." + client.name[0] + ' - ' + property.name,
                                        description: booking.platform,
                                        startDate: formatDate(start)
                                    })
                                }
                            }
                        }
                        return null
                    })
                    property.interventions.map((intervention) => {
                        if (!intervention.cancelled) {
                            if (
                                new Date() < new Date(intervention.date.dateStart)
                                && new Date(intervention.date.dateStart) < dPlus7
                                && !client.archive
                            ) {
                                const peopleList = intervention.people.map(people => {
                                    const user = userContext.data.list.find(item => item.id === people.people)
                                    return user ? user.lastName + " " + user.name : people.people
                                })

                                listIntervention.push({
                                    title: peopleList.join(', '),
                                    description: client.lastName + "." + client.name[0] + " - " + property.address.city + " " + intervention.description.description,
                                    date: new Date(intervention.date.dateStart),
                                    data: intervention
                                })
                            }
                        }
                        return null
                    })
                    return null
                })
                return null
            })
            const listErrorsRefined = [{
                title: "",
                items: []
            }, {
                title: "",
                items: []
            }, {
                title: "",
                items: []
            }, {
                title: "",
                items: []
            }]
            listErrors = listErrors.sort((a, b) => {
                return a.priority > b.priority ? -1 : 1
            })
            listErrors.forEach((item, index) => {
                if (index < 3) {
                    listErrorsRefined[0].items.push(item)
                } else if (index < 6) {
                    listErrorsRefined[1].items.push(item)
                } else if (index < 9) {
                    listErrorsRefined[2].items.push(item)
                } else {
                    listErrorsRefined[3].items.push(item)
                }
            })

            const item = pie
            item.datasets.data = listPie
            setPie(item)

            setBookingError(listErrorsRefined)
            setLastBookings(listBookings)
            setNextIntervention(listIntervention)
            setLoading(false)
        }


    }, [userContext, lastBookings, setLastBookings, nextIntervention, setNextIntervention, bookingError, setBookingError, pie, setPie])

    if (loading || userContext.loading || Object.keys(userContext.data).length === 0) {
        return <Loader />
    }
    return (
        <div>
            <RowContent>
                <ContentCard className="list-priority">
                    <ListItemPriority data={bookingError} />
                </ContentCard>
            </RowContent>
            <RowContent>
                <ContentCard>
                    <h3>Dernières réservations</h3>
                    <p className={"legend"}>Réservatrion reçu durant les 7 derniers jours</p>
                    <ListItem data={lastBookings} title={"Dernières réservations"}
                        sortFn={(a, b) => a.startDate - b.startDate} max={3} />

                </ContentCard>
                <ContentCard>
                    <ResourcesUpdate name={"Concierges"} icon={<BsPersonBoundingBox />}
                        toAdd={0}
                        count={userContext.data.people.Concierge.filter(item => !item.archive).length} />
                    <ResourcesUpdate name={"Prestataires"} icon={<BsWrench />}
                        toAdd={0}
                        count={userContext.data.people.Prestataires.filter(item => !item.archive).length} />
                    <ResourcesUpdate name={"Clients"} icon={<BsFillPeopleFill />}
                        toAdd={0}
                        count={userContext.data.people.Client.filter(item => !item.archive).length} />
                    <p className={"legend"}>Nombre total plus évolution sur 30 jours glissants</p>
                </ContentCard>
                <ContentCard className={"disableContent"}>
                    <h2 className={" text-middle"}>Répartition du CA
                        de {new Date().toLocaleDateString('fr-FR', { month: 'long' })}</h2>
                    <div className={"doughNut"}>
                        <ChartsPie data={pie} />
                    </div>
                </ContentCard>
            </RowContent>
            <RowContent>
                <ContentCard>
                    <h3>Prochaines interventions</h3>
                    <p className={"legend"}>Prochaines interventions dans les 7 jours glissants</p>
                    <ListItemsSeparated data={nextIntervention.sort((a, b) => a.date - b.date)}
                        sep={["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]}
                        max={5} />
                </ContentCard>
            </RowContent>
        </div>
    )
}

function getDaysBetween(date1, date2) {
    const Difference_In_Time = date2.getTime() - date1.getTime();

    return Difference_In_Time / (1000 * 3600 * 24)
}

function formatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
