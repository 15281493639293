import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import TableRow from './TableRow';
import numberToWords from './NumInWords';

Font.register({
    family: 'monts', fonts: [
        { src: "https://api2.mypacome-manager.fr/uploads/fonts/MontserratBold.ttf", fontWeight: 700 },
        { src: "https://api2.mypacome-manager.fr/uploads/fonts/Montserrat-Regular.ttf", fontWeight: 600 },
    ]
});
Font.register({
    family: 'arial', fonts: [
        { src: "https://api2.mypacome-manager.fr/uploads/fonts/arial.ttf", fontWeight: 600 },
    ]
});

Font.register({
    family: "avenir", fonts: [
        { src: "https://api2.mypacome-manager.fr/uploads/fonts/Avenir-Book.ttf", fontWeight: 600 },
    ]
})

const blue = "#003952"
// const gold = "#FABA79"

const styles = StyleSheet.create({
    page: {
        padding: 0,
        paddingBottom: 50,
        paddingTop: 30,
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        fontFamily: 'monts',
        position: 'absolute',
        height: '100%',
    },
    footer: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 8,
        padding: 10,
    },
    footerText: {
        color: '#000',
        fontWeight: 600,
    },
    section: {
        marginHorizontal: 20,
        padding: 0,
    },
    topLeftText: {
        color: blue,
        fontSize: 20,
        fontFamily: 'monts',
        fontWeight: 800,
        textAlign: 'right',
        marginBottom: 15,
        width: "auto",
    },
    topRightLogo: {
        width: 150,
        position: 'absolute',
        left: 10,
        top: 0,
        marginBottom: 10,
    },
    bold: {
        fontWeight: 700,
        fontSize: 10,
        color: blue
    },
    textTopIsh: {
        fontSize: 9,
    },
    mt: {
        fontFamily: 'monts',
    },
    ar: {
        fontFamily: 'arial',
        // fontSize: 11
    },
    av: {
        fontFamily: 'monts',
        fontWeight: 600
    },
    datesAndStuff: {
        marginBottom: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: "100%",
        gap: 20
    },
    clientInfo: {
        width: "50%",
        fontFamily: 'monts',
        marginLeft: "50%"
    },
    table: {
        borderBottom: 1,
        borderStyle: "solid",
        borderColor: '#000000',
        marginHorizontal: 20,
        marginVertical: 10,
    },
    footerPageText: {
        position: 'absolute',
        fontSize: 8,
        bottom: 0,
        right: 0,
        textAlign: 'right',
        color: '#000',
        padding: 10,
    },
    header: {
        position: 'absolute',
        left: 0,
        right: 0,
        textAlign: 'center',
        paddingHorizontal: 10,
        paddingVertical: 5,

    },
    headerText: {
        color: blue,
        fontWeight: 500,
        fontSize: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontFamily: 'monts',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 5,
    },
    padd: {
        paddingHorizontal: 20,
        paddingVertical: 5,
        width: '100%',
    },
    details: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    detail: {
        width: '50%',
        fontFamily: 'monts',
        fontSize: 8,
    },
    detailRight: {
        width: '30%',
    },
    headerDetails: {
        backgroundColor: blue,
        color: '#fff',
        width: '100%',
        padding: 5,
        marginBottom: 5,
    },
    tr: {
        textAlign: 'right'
    },
    legal1: {
        fontSize: 8,
    },
    legal2: {
        fontSize: 6,
    },
});

const Footer = () => (
    <View style={styles.footer} fixed>
        <Text style={styles.footerText}> CF CONCIERGERIE- 3 impasse de Grieu Parc d'activité de Launay 14130 PONT L EVEQUE France- IBAN FR7618306002253610854624186- Code NAF (APE) 8299Z- SARL au capital social de 1000 €- Siret : 84864867100024- N° TVA FR23848648671
        </Text>
        <Text style={styles.footerPageText} render={({ pageNumber, totalPages }) => (
            `Page ${pageNumber} / ${totalPages}`
        )} fixed />
    </View>
);

const Header = ({ type, number }) => (
    <View style={styles.header} fixed render={({ pageNumber }) => (
        pageNumber === 1 ? null : (
            <View style={styles.headerText}>
                {/* {console.log(type, number)} */}

                <Text style={styles.headerText}>MYPacôme</Text>
                <Text style={styles.headerText}>Client facturé</Text>
                <Text style={styles.headerText}>{type === 'invoice' ? `Devis N°${number}` : `Facture N°${number}`}</Text>
                <Text style={styles.headerText}>Date : 18/11/2024</Text>
            </View>
        )
    )}>
    </View>
);

const MyDocument = (bill, type) => {
    let date, validity
    if (!bill) return null
    else {
        type = bill.type
        bill = bill.bill
        if (!bill) return null
        if (!bill.date) return null
        date = new Date(bill.date)
        validity = new Date(bill.validity)

    }
    if (bill.billingAddress && bill.billingAddress.isTheSameAsFullAddress) {
        bill.billingAddress = bill.fullAddress;
    }

    const document = JSON.parse(bill.document)

    const paymentType = (type) => {
        switch (type) {
            case "PRE":
                return "Prélèvement"
            case "VIR":
                return "Virement"
            case "CB":
                return "Carte bancaire"
            case "CHQ":
                return "Chèque"
            case "ESP":
                return "Espèces"
            default:
                break;
        }
    }
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Header
                    type={type}
                    number={bill.code}
                />
                <View style={[styles.section]}>
                    <Image style={styles.topRightLogo} source="https://api2.mypacome-manager.fr/uploads/images/image.png" />
                    <Text style={styles.topLeftText}>{type === 'invoice' ? 'DEVIS' : 'FACTURE'}</Text>
                </View>
                <View style={styles.section}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ width: "50%" }}>
                            <Text style={[styles.bold, styles.mt]}>CF Conciergerie</Text>
                            <Text style={[styles.av, styles.textTopIsh]}>14 Bis Rue Georges Clemenceau</Text>
                            <Text style={[styles.av, styles.textTopIsh]}>14130 Pont-l'Évêque</Text>
                        </View>
                        <View style={{ width: "25%" }}>
                            <View style={[styles.datesAndStuff]}>
                                <Text style={[styles.bold, styles.mt]}>{type === 'invoice' ? 'Devis' : 'Facture'} N°</Text>
                                <Text style={[styles.av, styles.textTopIsh]}>{bill.code}</Text>
                            </View>
                            <View style={[styles.datesAndStuff]}>
                                <Text style={[styles.bold, styles.mt]}>Client N°</Text>
                                <Text style={[styles.av, styles.textTopIsh]}>{bill.clientId}</Text>
                            </View>
                            <View style={[styles.datesAndStuff]}>
                                <Text style={[styles.bold, styles.mt]}>Date</Text>
                                <Text style={[styles.av, styles.textTopIsh]}>{`${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`}</Text>
                            </View>
                            <View style={[styles.datesAndStuff]}>
                                <Text style={[styles.bold, styles.mt]}>{type === 'invoice' ? "Valide jusqu'au" : 'Échéance'}</Text>
                                <Text style={[styles.av, styles.textTopIsh]}>{`${validity.getDate()}/${validity.getMonth()}/${validity.getFullYear()}`}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={styles.clientInfo}>
                            <Text style={[styles.bold, styles.mt]}>{bill.fullName}</Text>
                            <Text style={[styles.av, styles.textTopIsh]}>{bill?.billingAddress?.roadAndNumber}</Text>
                            <Text style={[styles.av, styles.textTopIsh]}>{bill?.billingAddress?.zipCode + " " + bill?.billingAddress?.city}</Text>
                            <Text style={[styles.av, styles.textTopIsh]}>{bill?.billingAddress?.country}</Text>
                            <Text style={[styles.av, styles.textTopIsh]}>{bill.clientMail}</Text>
                        </View>
                    </View>
                </View>

                {bill.reference ? (<View style={[styles.section, { flexDirection: "row", alignItems: "center", marginBottom: 0 }]}>
                    <Text style={[styles.bold, styles.mt]}>Réf : </Text>
                    <Text style={[styles.av, styles.textTopIsh]}>{bill.reference}</Text>
                </View>) : null}


                <View style={[styles.section, { marginTop: 0 }]}>
                    <Text style={[styles.av, styles.textTopIsh]}>{bill.description}</Text>
                </View>

                <View style={[styles.table]}>
                    <TableRow
                        data={['Libellé', 'Qté', 'Unité', 'PU HT', 'TVA', 'TTC']} isHeader={true}
                        widths={['55%', '7%', '8%', '10%', '10%', '10%']}
                    />
                    {
                        document.map((item, index) => {
                            // Handle different types of items, e.g., text vs. service objects
                            if (item.text) {
                                // console.log(item, "item") 
                                return (
                                    <TableRow
                                        key={index}
                                        data={[item.text, '', '', '', '', '']}
                                        widths={['55%', '7%', '8%', '10%', '10%', '10%']}
                                        tableStyle={item.type ? "comment" : "subheader"}
                                    />
                                );
                            } else {
                                return (
                                    <TableRow
                                        key={index}
                                        data={[
                                            item.label,
                                            item.quantity,
                                            '',
                                            `${item.priceFull}€`,
                                            `${item.taxes}%`,
                                            `${(item.priceFull * item.quantity).toFixed(2)}€`
                                        ]}
                                        widths={['55%', '7%', '8%', '10%', '10%', '10%']}
                                    />
                                );
                            }
                        })

                    }
                    {bill.type === 'invoice' && (
                        <Text style={[styles.legal2, styles.ar, styles.padd]}>Devis gratuit</Text>
                    )}

                </View>

                <View style={{ flex: 1, alignContent: "flex-end", justifyContent: "flex-end" }}>
                    <View style={[{ height: 'auto', alignToBottom: true }]} wrap={false}>
                        <View style={[styles.padd, styles.details]}>
                            <View style={styles.detail}>
                                <Text style={[styles.headerDetails, styles.mt]}>Détails de la TVA</Text>
                                <View style={[styles.row]}>
                                    <Text style={[styles.mt]}>Code</Text>
                                    <Text style={[styles.mt]}>Base HT</Text>
                                    <Text style={[styles.mt]}>Taux</Text>
                                    <Text style={[styles.mt]}>Montant</Text>
                                </View>
                                <View style={[styles.row]}>
                                    <Text style={[styles.av]}>TVA20</Text>
                                    <Text style={[styles.av]}>{bill.totals.totalPreTax}€</Text>
                                    <Text style={[styles.av]}>20%</Text>
                                    <Text style={[styles.av]}>{bill.totals.taxesTotal.toFixed(2)}€</Text>
                                </View>
                            </View>
                            {bill.reduction && bill.reduction.amount > 0 && (
                                <View style={[styles.detail, styles.detailRight]}>
                                    <Text style={[styles.headerDetails, styles.mt]}>Détails de la réduction</Text>
                                    <View style={[styles.row]}>
                                        <Text style={[styles.mt]}>Total remise :</Text>
                                        <Text style={[styles.av]}>{bill.reduction.amount + bill.reduction.currency}</Text>
                                    </View>
                                    <View style={[styles.row]}>
                                        <Text style={[styles.mt]}>Montant de la réduction :</Text>
                                        <Text style={[styles.av]}>{bill.reduction.currency === "%" ? (bill.totals.rawTotal * (bill.reduction.amount / 100)).toFixed(2) : bill.reduction.currency === "€" ? (bill.reduction.amount) : 0}€</Text>
                                    </View>
                                    <View style={[styles.row]}>
                                        <Text style={[styles.mt]}>Prix HT après réd. :</Text>
                                        <Text style={[styles.av]}>{(bill.totals.totalPreTax).toFixed(2)}€</Text>
                                    </View>
                                </View>
                            )}
                        </View>

                        <View style={[styles.padd, styles.details]}>
                            <View style={styles.detail}>
                                <Text style={[styles.headerDetails, styles.mt]}>{type === 'invoice' ? 'Règlement du devis' : 'Règlement de la facture'}</Text>
                                <View style={[styles.row]}>
                                    <Text style={[styles.mt]}>Règlement :</Text>
                                    <Text style={[styles.av]}>{paymentType(bill.payingMethod)}</Text>
                                </View>
                                {type !== 'invoice' && bill.acomptes && bill.acomptes.amount > 0 ? (
                                    <>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.mt]}>Échéance (Acompte) :</Text>
                                            <Text style={[styles.av]}>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</Text>
                                        </View>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.mt]}>Montant Acompte :</Text>
                                            <Text style={[styles.av]}>{bill.acomptes.currency === '€' ? `${bill.acomptes.amount}€` : `${(bill.acomptes.amount / 100 * bill.totals.total).toFixed(2)}€`}</Text>
                                        </View>
                                        <View style={[styles.row]}>
                                            <Text style={[styles.mt]}>Échéance (Solde) :</Text>
                                            <Text style={[styles.av]}>{`${validity.getDate()}/${validity.getMonth() + 1}/${validity.getFullYear()}`}</Text>
                                        </View>
                                        <View wrap={false} style={[styles.row]}>
                                            <Text style={[styles.mt]}>Montant Solde :</Text>
                                            <Text style={[styles.av]}>{bill.acomptes.currency === '€' ? `${(bill.totals.total - bill.acomptes.amount).toFixed(2)}€` : `${(bill.totals.total - (bill.acomptes.amount / 100 * bill.totals.total)).toFixed(2)}€`}</Text>
                                        </View>
                                    </>
                                ) : (
                                    <View style={[styles.row]}>
                                        {/* <Text style={[styles.mt]}>Échéance :</Text>
                                    <Text style={[styles.av]}>{`${validity.getDate()}/${validity.getMonth() + 1}/${validity.getFullYear()}`}</Text> */}
                                    </View>
                                )}
                            </View>
                            <View style={[styles.detail, styles.detailRight]}>
                                <View style={[styles.row]}>
                                    <Text style={[styles.headerDetails, styles.mt]}>Total HT :</Text>
                                    <Text style={[styles.headerDetails, styles.tr]}>{(bill.totals.totalPreTax - (bill.reduction?.amount ?? 0)).toFixed(2)}€</Text>
                                </View>
                                <View style={[styles.row]}>
                                    <Text style={[styles.mt]}>Total TVA :</Text>
                                    <Text style={[styles.av]}>{bill.totals.taxesTotal.toFixed(2)}€</Text>
                                </View>
                                <View style={[styles.row]}>
                                    <Text style={[styles.mt]}>Total TTC :</Text>
                                    <Text style={[styles.av]}>{bill.totals.total.toFixed(2)}€</Text>
                                </View>
                                <View style={[styles.row]}>
                                    <Text style={[styles.legal1, styles.ar]}>{numberToWords(bill.totals.total)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[styles.padd, styles.details]}>
                            <View style={[styles.detail]}>
                                <Text style={[styles.headerDetails, styles.mt]}>Coordonnées bancaires</Text>
                                <View style={[styles.row]}>
                                    <Text style={[styles.mt]}>Nom :</Text>
                                    <Text style={[styles.av]}>CF CONCIERGERIE</Text>
                                </View>
                                <View style={[styles.row]}>
                                    <Text style={[styles.mt]}>IBAN :</Text>
                                    <Text style={[styles.av]}>FR7618306002253610854624186</Text>
                                </View>
                                <View style={[styles.row]}>
                                    <Text style={[styles.mt]}>BIC :</Text>
                                    <Text style={[styles.av]}>AGRIFRPP883</Text>
                                </View>
                            </View>
                            {type === 'invoice' && (
                                <>
                                    <View style={[styles.detail, styles.detailRight]}>
                                        <Text style={[styles.headerDetails, styles.mt]}>Bon pour accord</Text>
                                        <Text style={[styles.mt]}>Date et signature</Text>
                                        <View style={{ height: 50 }} /> {/* Empty space for date and signature */}
                                    </View>
                                </>
                            )}
                        </View>

                        <Text style={[styles.legal2, styles.ar, styles.padd]}>
                            Pas d'escompte pour règlement anticipé. En cas de retard de paiement, une pénalité égale à 3 fois le taux intérêt légal sera exigible (Article L 441-10, alinéa 12 du Code de Commerce). Pour tout professionnel, en sus des indemnités de retard, toute somme, y compris l'acompte, non payée à sa date d'exigibilité produira de plein droit le paiement d'une indemnité forfaitaire de 40 euros due au titre des frais de recouvrement (Art. 441-6, I al. 12 du code de commerce et D. 441-5 ibidem).

                        </Text>

                    </View>
                </View>


                <Footer />
            </Page>
        </Document >

    )
};

export default MyDocument;
