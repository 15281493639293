import React from "react";
import "../../assets/scss/modal.scss"

export default function Modal(props) {
    const [defOverlayClose] = React.useState(props.defOverlayClose || true)
        , [classes] = React.useState(props.classes || {
            title: "",
            header: ""
        })

    if (!props.show) return null

    return (
        <div className={"overlay-modal"}>
            <div className={"modal fade show"} aria-modal="true" onClick={(e) => {
                if (e.target === e.currentTarget) {
                    if (defOverlayClose) props.close()
                }
            }}>
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className={"modal-header justify-content-center " + classes.header}>
                            <div className="icon-box ">
                                <h1 className={classes.title}>{props.title}</h1>
                            </div>
                            <button className={"btn close"} onClick={() => {
                                props.close()
                            }}>×
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}