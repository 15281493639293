import { memo } from "react";
import { useEffect, useRef } from "react"
import { compareTwoStringsToInt, slugify } from "../../../Utils/functions";


export default memo(function BillingSuggestions({ data, search, focused, onBlur, click, setFocused }) {
    const ref = useRef()
    if (!search) search = ""
    useEffect(() => {
        const handleClick = (e) => {
            e.stopImmediatePropagation();
            e.stopPropagation()
            if (ref.current && !ref.current.contains(e.target)) {
                onBlur()
            }
        }
        window.addEventListener("mousedown", handleClick)

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener("mousedown", handleClick);
        }
    }, [onBlur])

    if (focused) {

        if (data) {
            let productFiltered = []
            data.forEach(product => {
                const productString = product.Code + " " + product.Libellé
                if (slugify(productString).includes(search) && product.Code && product.Libellé) {
                    productFiltered.push(product)
                }
            });
            productFiltered.sort(compareTwoStringsToInt('Code'))
            return (
                <ul className="suggestions" ref={ref} onFocus={() => {
                    setFocused(true);
                }}>
                    {
                        productFiltered.map((product) => {
                            return (
                                <li onClick={() => click(product)} key={product.Code + " - " + product.Libellé}>{` ${product.Code} - ${product.Libellé} `}</li>
                            )
                        })
                    }
                </ul>

            )
        } else {
            return (
                <div ref={ref}>
                    <p>Data not loaded</p>
                </div>
            )
        }
    } else return null
});