import React from 'react'
import RowContent from "../../Cards/RowContent";
import ContentCard from "../../Cards/ContentCard";
import Spinner from '../../Spinner'
export default function Loader() {
    return (
        <div>
            <RowContent>
                <ContentCard>
                    <Spinner strokeWidth={9} width={40} color={"#003952"} />
                    <h2 className={'dark text-center'}>Chargement en cours</h2>
                </ContentCard>
            </RowContent>
        </div>
    )
}