//External components
import React from "react";
import Image from 'react-image-webp';
import { useHistory } from "react-router-dom";

//internal Components
import LoginBox from "../Components/LoginBox";

//Context
import DataContext from "../dataContext";

import "../assets/scss/login.scss"

export default function Reset() {
    const context = React.useContext(DataContext)
        , history = useHistory()

    const [formData, setFormData] = React.useState({
        email: ''
    }),
        [message, setMessage] = React.useState({ value: "", error: false })

    const handleChange = (e) => {
        if (e.target.name === "remember") {
            setFormData({
                ...formData,
                [e.target.name]: e.target.checked
            })
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value.trim()
            })
        }
    }

    return (
        <div className="parent">
            <div className={'background'} />
            <LoginBox>
                <Image webp={"https://i.ibb.co/3CgD7G4/new-Colors-Logo-2.webp"} src={"https://i.ibb.co/3CgD7G4/new-Colors-Logo-2.webp"} alt={"logo"} className={"col-6"} />
                <div className={"col-12"}>
                    <h3>MYPacôme</h3>
                    <h4>Réinitialiser son mot de passe</h4>
                </div>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    context.apiReducer('reset', { email: formData.email })
                        .then(() => {
                            setMessage({
                                value: 'Si ce compte existe un email lui a été envoyé. Regardez votre boite mail.',
                                error: false
                            })
                        })
                        .catch((err) => {
                            console.log(err)
                            setMessage({ value: 'Une erreur s\'est produite', error: true })
                        })
                }}>
                    <div className={"form-group"}>
                        <input type={"email"} className={"form-control mt-lg-2"}
                            id={"email"} name={"email"} onChange={handleChange}
                            defaultValue={formData.email}
                            aria-describedby={"emailHelp"} placeholder={"Email"} />
                    </div>

                    <div className={"m-2"}>
                        <p className={message.error ? "warning" : "success"}>{message.value}</p>
                    </div>

                    <div className={"form-group mt-lg-4 d-flex justify-content-between"}>
                        <button type={'button'} className={"btn btn-outline-light"} onClick={() => {
                            history.push('/login')
                        }}> Se connecter</button>
                        <button type={'submit'} className={"btn btn-warning"}> Réinitialiser</button>
                    </div>

                </form>
            </LoginBox>
        </div>
    );
}