import { BsPlusCircle, BsFileEarmarkFont, BsPersonFill, BsTrash, BsArrowDownSquare, BsArrowUpSquare } from "react-icons/bs";
import Spinner from "../../../Components/Spinner";
import "../../../assets/scss/Billing/popUp.scss"

import React, { useState, useEffect, useCallback, useRef } from 'react';

export default function PopUp({
    showPopUp, popUpContent, setShowPopUp, userContext, handleModif,
    formatDate, setShowClientPopUp, action, setShowProductPopUp,
    document, setDocument, showProductPopUp, reference, setReference, siret, setSiret, address,
    setAddress, type, payingMethod, setPayingMethod, billingAddress, setBillingAddress,
    isTheSameAsFullAddress, setFullAddress, fullAddress, setFullSecondAddress, fullSecondAddress, setIsTheSameAsFullAddress,
    fullName, setFullName, clientMail, setClientMail, clientId, setClientId
}) {
    const what = popUpContent?.what;
    const thing = popUpContent?.thing;

    const [documentState, setDocumentState] = useState("Brouillon");
    const [code, setCode] = useState("");
    const [date, setDate] = useState(new Date());
    const [validity, setValidity] = useState(new Date());
    const [description, setDescription] = useState("");
    const [rawTotal, setRawTotal] = useState(0);
    const [taxesTotal, setTaxesTotal] = useState(0);
    const [totalPreTax, setTotalPreTax] = useState(0);
    const [total, setTotal] = useState(0);
    const [currency, setCurrency] = useState("");
    const [amount, setAmount] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showDescriptionDropdown, setShowDescriptionDropdown] = useState(false);
    const [_id, set_Id] = useState("");
    const [reduction, setReduction] = useState({});
    const [showReduction, setShowReduction] = useState(false);
    const [billLoading, setBillLoading] = useState(false);
    const formRef = useRef(null);
    const [triggerSubmit, setTriggerSubmit] = useState(false);

    const calculateValidityDate = useCallback((date) => {
        let nextValidityDate = new Date(date);
        if (date.getDate() < 5) {
            nextValidityDate.setDate(5);
        } else {
            nextValidityDate.setMonth(nextValidityDate.getMonth() + 1);
            nextValidityDate.setDate(5);
        }
        return nextValidityDate;
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (thing?.object) {
                let newState = {
                    documentState: thing.object.documentState || "Brouillon",
                    date: thing.object.date || new Date(),
                    validity: type === "bill" ? calculateValidityDate(new Date()) : thing.object.validity || new Date(new Date().setMonth(new Date().getMonth() + 2)),
                    clientId: thing.object.clientId || "",
                    reference: thing.object.reference || "",
                    siret: thing.object.siret || "",
                    payingMethod: thing.object.payingMethod || "",
                    description: thing.object.description || "",
                    address: thing.object.address || "",
                    rawTotal: thing.object.totals?.rawTotal || 0,
                    taxesTotal: thing.object.totals?.taxesTotal || 0,
                    totalPreTax: thing.object.totals?.totalPreTax || 0,
                    total: thing.object.totals?.total || 0,
                    currency: thing.object.acomptes?.currency || "%",
                    amount: thing.object.acomptes?.amount || 0,
                    _id: thing.object._id || "",
                    reduction: thing.object.reduction || {},
                    fullAddress: thing.object.fullAddress || { roadAndNumber: "", zipCode: "", city: "", country: "", complement: "" },
                    fullSecondAddress: thing.object.fullSecondAddress || { roadAndNumber: "", zipCode: "", city: "", country: "", complement: "" },
                    billingAddress: thing.object.billingAddress || { roadAndNumber: "", zipCode: "", city: "", country: "", complement: "", isTheSameAsFullAddress: false },
                    isTheSameAsFullAddress: thing.object.billingAddress?.isTheSameAsFullAddress || false,
                    document: JSON.parse(thing.object.document || "[]"),
                    fullName: thing.object.fullName || "",
                    clientMail: thing.object.clientMail || "",
                };

                if ((action === "addBill" || action === "addInvoice") && (!thing?.object.code || thing?.object.code === "" || !newState.code)) {
                    try {
                        let response = await userContext.apiReducer(action === "addBill" ? "getBillCode" : "getInvoiceCode");
                        if (!response.length || !response) response = "DEV-2024001"
                        setCode(response);
                    } catch (err) {
                        console.log(err, "error");
                    }
                } else {
                    setCode(thing?.object.code || "");
                }
                // use newState to set all the states at once:
                setDocumentState(newState.documentState);
                setDate(newState.date);
                setValidity(newState.validity);
                setClientId(newState.clientId);
                setReference(newState.reference);
                setSiret(newState.siret);
                setPayingMethod(newState.payingMethod);
                setDescription(newState.description);
                setAddress(newState.address);
                setRawTotal(newState.rawTotal);
                setTaxesTotal(newState.taxesTotal);
                setTotalPreTax(newState.totalPreTax);
                setTotal(newState.total);
                setCurrency(newState.currency);
                setAmount(newState.amount);
                setReduction(newState.reduction);
                setFullAddress(newState.fullAddress);
                setFullSecondAddress(newState.fullSecondAddress);
                setBillingAddress(newState.billingAddress);
                setIsTheSameAsFullAddress(newState.isTheSameAsFullAddress);
                setDocument(newState.document);
                setFullName(newState.fullName);
                setClientMail(newState.clientMail);
                set_Id(newState._id);
            }
        };

        fetchData();
    }, [thing, action, userContext, type, calculateValidityDate, code]);

    useEffect(() => {
        if (triggerSubmit) {
            formRef.current.requestSubmit()
            setTriggerSubmit(false)
        }
    }, [triggerSubmit])
    const handleButtonClick = () => {
        setDocumentState("Facturé")
        setIsSubmitting(true)
        setTriggerSubmit(true)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsSubmitting(true);
        const documentString = JSON.stringify(document);
        const data = {
            documentState,
            code,
            date,
            validity,
            clientId,
            reference,
            siret,
            payingMethod,
            description,
            totals: { rawTotal, taxesTotal, totalPreTax, total },
            acomptes: { currency, amount },
            document: documentString,
            reduction,
            billingAddress,
            fullSecondAddress,
            fullAddress,
            fullName,
            clientMail
        }
        if (type === "invoice") {
            data.fullAddress = fullAddress
        } else {
            data.address = address
        }

        // Client side validation, yeah its not pretty fight me
        switch (true) {
            case !clientId:
                alert("Veuillez renseigner un client");
                setIsSubmitting(false);
                return;
            case !code:
                alert("Veuillez renseigner un code");
                setIsSubmitting(false);
                return;
            case !date:
                alert("Veuillez renseigner une date");
                setIsSubmitting(false);
                return;
            case !validity:
                alert("Veuillez renseigner une date de validité");
                setIsSubmitting(false);
                return;
            case !payingMethod:
                alert("Veuillez renseigner un mode de paiement");
                setIsSubmitting(false);
                return;
            case !documentString:
                alert("Veuillez renseigner un document");
                setIsSubmitting(false);
                return;
            case !fullAddress.roadAndNumber:
                alert("Veuillez renseigner un numéro et une rue pour l'adresse principale");
                setIsSubmitting(false);
                return;
            case !fullAddress.zipCode:
                alert("Veuillez renseigner un code postal pour l'adresse principale");
                setIsSubmitting(false);
                return;
            case !fullAddress.city:
                alert("Veuillez renseigner une ville pour l'adresse principale");
                setIsSubmitting(false);
                return;
            case !fullAddress.country:
                alert("Veuillez renseigner un pays pour l'adresse principale");
                setIsSubmitting(false);
                return;
            case !fullSecondAddress.roadAndNumber:
                alert("Veuillez renseigner un numéro et une rue pour l'adresse secondaire");
                setIsSubmitting(false);
                return;
            case !fullSecondAddress.zipCode:
                alert("Veuillez renseigner un code postal pour l'adresse secondaire");
                setIsSubmitting(false);
                return;
            case !fullSecondAddress.city:
                alert("Veuillez renseigner une ville pour l'adresse secondaire");
                setIsSubmitting(false);
                return;
            case !fullSecondAddress.country:
                alert("Veuillez renseigner un pays pour l'adresse secondaire");
                setIsSubmitting(false);
                return;
            default:
                break;
        }

        if (_id) {
            data._id = _id;
        }
        // console.log(documentState, "documentState")
        // setIsSubmitting(false);
        if (action === "addBill" || action === "addInvoice") {
            data.new = true
        }

        userContext.apiReducer((type === "invoice" ? "postInvoice" : "postBill"), JSON.stringify(data))
            .then((response) => {
                console.log(response, "response2");
                setIsSubmitting(false);
                setShowPopUp(false);
                handleModif(response, action);
            })
            .catch((err) => {
                console.log(err, "error");
                setIsSubmitting(false);
            });
    };

    useEffect(() => {
        console.log(clientId, "clientId")
    }, [clientId])

    // useEffect(() => {
    //     const calculateValidityDate = (date) => {
    //         let nextValidityDate = new Date(date);
    //         if (date.getDate() < 5) {
    //             nextValidityDate.setDate(5);
    //         } else {
    //             nextValidityDate.setMonth(nextValidityDate.getMonth() + 1);
    //             nextValidityDate.setDate(5);
    //         }
    //         return nextValidityDate;
    //     };
    //     if (thing?.object) {
    //         setState(thing.object.state || "Brouillon");
    //         setDate(thing.object.date || new Date());
    //         if (type === "bill") {
    //             setValidity(calculateValidityDate(new Date()));
    //         } else {
    //             setValidity(thing.object.validity || new Date(new Date().setMonth(new Date().getMonth() + 2)));
    //         }
    //         setClientId(thing.object.clientId || "");
    //         setReference(thing.object.reference || "");
    //         setSiret(thing.object.siret || "");
    //         setPayingMethod(thing.object.payingMethod || "");
    //         setDescription(thing.object.description || "");
    //         setAddress(thing.object.address || "");
    //         setRawTotal(thing.object.totals?.rawTotal || 0);
    //         setTaxesTotal(thing.object.totals?.taxesTotal || 0);
    //         setTotalPreTax(thing.object.totals?.totalPreTax || 0);
    //         setTotal(thing.object.totals?.total || 0);
    //         setCurrency(thing.object.acomptes?.currency || "");
    //         setAmount(thing.object.acomptes?.amount || 0);
    //         set_id(thing.object._id || "");
    //         setReduction(thing.object.reduction || {});
    //         setFullAddress({
    //             roadAndNumber: thing.object.fullAddress?.roadAndNumber || "",
    //             zipCode: thing.object.fullAddress?.zipCode || "",
    //             city: thing.object.fullAddress?.city || "",
    //             country: thing.object.fullAddress?.country || "",
    //             complement: thing.object.fullAddress?.complement || "",
    //         })
    //         setFullSecondAddress({
    //             roadAndNumber: thing.object.fullSecondAddress?.roadAndNumber || "",
    //             zipCode: thing.object.fullSecondAddress?.zipCode || "",
    //             city: thing.object.fullSecondAddress?.city || "",
    //             country: thing.object.fullSecondAddress?.country || "",
    //             complement: thing.object.fullSecondAddress?.complement || "",
    //         })
    //         setBillingAddress({
    //             roadAndNumber: thing.object.billingAddress?.roadAndNumber || "",
    //             zipCode: thing.object.billingAddress?.zipCode || "",
    //             city: thing.object.billingAddress?.city || "",
    //             country: thing.object.billingAddress?.country || "",
    //             complement: thing.object.billingAddress?.complement || "",
    //             isTheSameAsFullAddress: thing.object.billingAddress?.isTheSameAsFullAddress || false
    //         })
    //         setIsTheSameAsFullAddress(thing.object.billingAddress?.isTheSameAsFullAddress || false)

    //         if ((action === "addBill" || action === "addInvoice") && (!thing?.object.code || !code)) {
    //             userContext.apiReducer(action === "addBill" ? "getBillCode" : "getInvoiceCode")
    //                 .then((response) => {
    //                     setCode(response);
    //                 })
    //                 .catch((err) => {
    //                     console.log(err, "error");
    //                 });
    //         } else {
    //             setCode(thing?.object.code || "");
    //         }
    //     }

    //     try {
    //         setDocument(JSON.parse(thing?.object?.document) || []);
    //     } catch (e) {
    //     }
    // }, [thing, setAddress, setDocument, setPayingMethod, setReference, setSiret,
    //     setState, set_id, setRawTotal, setTaxesTotal, setTotal, setTotalPreTax,
    //     setValidity, setCurrency, setAmount, setDescription, action, userContext, type,
    //     setFullAddress, setFullSecondAddress, setBillingAddress, setIsTheSameAsFullAddress, code]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showDescriptionDropdown && !event.target.closest('.description-dropdown')) {
                setShowDescriptionDropdown(false);
            }
            if (showProductPopUp && !event.target.closest('.billing-popup')) {
                setShowProductPopUp(false);
            }
        };

        window.document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDescriptionDropdown, showProductPopUp, setShowProductPopUp]);

    // useEffect(() => {
    //     if (action === "addBill" || action === "addInvoice") {
    //         userContext.apiReducer(action === "addBill" ? "getBillCode" : "getInvoiceCode")
    //             .then((response) => {
    //                 setCode(response.code);
    //             })
    //             .catch((err) => {
    //                 console.log(err, "error");
    //             });
    //     }
    // }, [action, setCode, userContext]);

    const handleDescriptionClick = (index) => {
        setShowDescriptionDropdown((prev) => (prev === index ? null : index));
    };


    const handleInputChange = (e, index, field) => {
        let { value } = e.target;
        value = value.replace(/[%€]/g, '');
        setDocument((prevProducts) => {
            const updatedProducts = [...prevProducts];
            if (updatedProducts[index]._id) {
                updatedProducts[index] = { ...updatedProducts[index], [field]: value };
            } else if (updatedProducts[index].text) {
                updatedProducts[index] = { ...updatedProducts[index], text: value };
            } else if (updatedProducts[index].comment) {
                updatedProducts[index] = { ...updatedProducts[index], comment: value };
            }
            return updatedProducts;
        });
    };


    const handleDelete = (index) => {
        setDocument((prevProducts) => {
            const updatedProducts = [...prevProducts];
            updatedProducts.splice(index, 1);
            return updatedProducts;
        });
    };

    const addSection = (section) => {
        setDocument([...document, section]);
    }

    const addComment = (comment) => {
        comment = { ...comment, type: "comment" };
        setDocument([...document, comment]);
    }

    useEffect(() => {
        let newRawTotal = 0;
        let newTaxesTotal = 0;
        let newTotalPreTax = 0;

        document?.forEach((product) => {
            if (!product._id) return;
            let priceNoTaxes = Math.round((parseInt(product.quantity ? product.quantity : 1) * parseFloat(product.price)) * 100) / 100;
            let taxed = Math.round((priceNoTaxes * (product.taxes / 100)));
            newRawTotal += priceNoTaxes;
            newTaxesTotal += taxed;
            newTotalPreTax += priceNoTaxes;
        });

        // Remove the reductions from the newTotalPreTax
        if (reduction.currency === "%") {
            newTotalPreTax -= newTotalPreTax * (reduction.amount / 100)
            newTaxesTotal -= newTaxesTotal * (reduction.amount / 100);
        } else if (reduction.currency === "€") {
            // Calculate the percentage of diffrence between newTotalPreTax and reduction.amount to remove from taxes
            newTaxesTotal = (newTotalPreTax - reduction.amount) * (newTaxesTotal / newTotalPreTax)
            newTotalPreTax -= reduction.amount;
        }
        setRawTotal(newRawTotal);
        setTaxesTotal(newTaxesTotal);
        setTotalPreTax(newTotalPreTax);
        setTotal(newTotalPreTax + newTaxesTotal);
    }, [document, reduction]);

    const moveItem = (index, direction) => {
        let updatedDocument = [...document];
        if (direction === 'up') {
            if (index > 0) {
                let temp = updatedDocument[index];
                updatedDocument[index] = updatedDocument[index - 1];
                updatedDocument[index - 1] = temp;
            }
        } else if (direction === 'down') {
            if (index < updatedDocument.length - 1) {
                let temp = updatedDocument[index];
                updatedDocument[index] = updatedDocument[index + 1];
                updatedDocument[index + 1] = temp;
            }
        }
        setDocument(updatedDocument);
    }

    return (
        <div className={'billing-popup' + (showPopUp ? " open" : "")} onClick={(e) => {
            if (e.target.classList.contains('billing-popup')) {
                e.target.classList.remove('open');
                setShowPopUp(false);
                setDocument([]);
            }
        }}>
            <div className="container popup">
                <h3>{what ? what?.title : "Modifier un contact :"}</h3>
                <h4>{thing ? thing?.name : "Nom du contact"}</h4>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="documentState" className="form-label">État :</label>
                                <select disabled className="form-select" id="documentState" value={documentState} onChange={(e) => setDocumentState(e.target.value)}>
                                    <option value="Brouillon">Brouillon</option>
                                    <option value="Accepté">Accepté</option>
                                    <option value="Annulé">Annulé</option>
                                    <option value="Facturé">Facturé</option>
                                    <option value="Refusé">Refusé</option>
                                </select>
                            </div>
                            <div className="me-3">
                                <label htmlFor="code" className="form-label required">Code :</label>
                                <input disabled={documentState === "Facturé" || documentState === "Validé"} required type="text" className="form-control" id="code" value={code} onChange={(e) => setCode(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="date" className="form-label required">Date :</label>
                                <input disabled={documentState === "Facturé" || documentState === "Validé"} required type="date" className="form-control" id="date" value={formatDate(date, 2)} onChange={(e) => setDate(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="validity" className="form-label required">{"Date  " + (type === "invoice" ? "de validité du devis :" : "d'échéance de la facture :")}</label>
                                <input disabled={documentState === "Facturé" || documentState === "Validé"} required type="date" className="form-control" id="validity" value={formatDate(validity, 2)} onChange={(e) => setValidity(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">

                            <div className="me-3 dropdown">
                                <label htmlFor="clientId" className="form-label required">Code client :</label>
                                <div className="input-group-append">
                                    <input disabled={documentState === "Facturé" || documentState === "Validé"} required type="text" className="form-control" id="clientId" value={clientId} onChange={(e) => setClientId(e.target.value)} />
                                    <button disabled={documentState === "Facturé" || documentState === "Validé"} className="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" onClick={() => setShowClientPopUp(true)}><BsPersonFill /></button>
                                </div>
                            </div>
                            <div className="me-3">
                                <label htmlFor="fullName" className="form-label">Nom complet :</label>
                                <input disabled={documentState === "Facturé" || documentState === "Validé"} type="text" className="form-control" id="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="clientMail" className="form-label">Email :</label>
                                <input disabled={documentState === "Facturé" || documentState === "Validé"} type="text" className="form-control" id="clientMail" value={clientMail} onChange={(e) => setClientMail(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="siret" className="form-label">N° Siret :</label>
                                <input disabled={documentState === "Facturé" || documentState === "Validé"} type="text" className="form-control" id="siret" value={siret} onChange={(e) => setSiret(e.target.value.replace(/\D/g, '').slice(0, 14))} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="payingMethod" className="form-label required">Mode de paiement :</label>
                                <select disabled={documentState === "Facturé" || documentState === "Validé"} required className="form-select" id="payingMethod" value={payingMethod} onChange={(e) => setPayingMethod(e.target.value)}>
                                    <option value="">Selectionner un mode de paiement</option>
                                    <option value="PRE">PRE - Prélèvement</option>
                                    <option value="VIR">VIR - Virement</option>
                                    <option value="CB">CB - Carte bancaire</option>
                                    <option value="CHQ">CHQ - Chèque</option>
                                    <option value="ESP">ESP - Espèces</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 single-line">
                        <label htmlFor="reference" className="form-label">
                            Référence :
                        </label>
                        <input
                            disabled={documentState === "Facturé" || documentState === "Validé"}
                            type="text"
                            className="form-control"
                            id="reference"
                            value={reference}
                            onChange={(e) => setReference(e.target.value)}
                        />
                    </div>
                    <div className="mb-3 single-line">
                        <label htmlFor="description" className="form-label">
                            Description :
                        </label>
                        <textarea
                            disabled={documentState === "Facturé" || documentState === "Validé"}
                            className="form-control"
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                    <hr />
                    <div className="mb-3 single-line">
                        <div className="mb-3 single-line">
                            <h5>Adresse principale :</h5>
                            <div className="d-flex line-flex">
                                <div className="me-3">
                                    <label htmlFor="roadAndNumberPrincipal" className="form-label required">
                                        Numéro et Rue :
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        className="form-control"
                                        id="roadAndNumberPrincipal"
                                        value={fullAddress.roadAndNumber}
                                        disabled={documentState === "Facturé" || documentState === "Validé"}
                                        onChange={(e) => setFullAddress({ ...fullAddress, roadAndNumber: e.target.value })}
                                    />
                                </div>
                                <div className="me-3">
                                    <label htmlFor="complementPrincipal" className="form-label">
                                        Complément d'adresse :
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="complementPrincipal"
                                        value={fullAddress.complement}
                                        disabled={documentState === "Facturé" || documentState === "Validé"}
                                        onChange={(e) => setFullAddress({ ...fullAddress, complement: e.target.value })}
                                    />
                                </div>
                                <div className="me-3">
                                    <label htmlFor="zipCodePrincipal" className="form-label required">
                                        Code postal :
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        className="form-control"
                                        id="zipCodePrincipal"
                                        value={fullAddress.zipCode}
                                        disabled={documentState === "Facturé" || documentState === "Validé"}
                                        onChange={(e) => setFullAddress({ ...fullAddress, zipCode: e.target.value })}
                                    />
                                </div>
                                <div className="me-3">
                                    <label htmlFor="cityPrincipal" className="form-label required">
                                        Ville :
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        className="form-control"
                                        id="cityPrincipal"
                                        value={fullAddress.city}
                                        disabled={documentState === "Facturé" || documentState === "Validé"}
                                        onChange={(e) => setFullAddress({ ...fullAddress, city: e.target.value })}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="countryPrincipal" className="form-label required">
                                        Pays :
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        className="form-control"
                                        id="countryPrincipal"
                                        value={fullAddress.country}
                                        disabled={documentState === "Facturé" || documentState === "Validé"}
                                        onChange={(e) => setFullAddress({ ...fullAddress, country: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5>Adresse secondaire / Lieu d'intervention :</h5>{" "}
                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="roadAndNumberSecond" className="form-label required">
                                    Numéro et Rue :
                                </label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    id="roadAndNumberSecond"
                                    value={fullSecondAddress.roadAndNumber}
                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                    onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, roadAndNumber: e.target.value })}
                                />
                            </div>
                            <div className="me-3">
                                <label htmlFor="complementSecond" className="form-label">
                                    Complément d'adresse :
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="complementSecond"
                                    value={fullSecondAddress.complement}
                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                    onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, complement: e.target.value })}
                                />
                            </div>
                            <div className="me-3">
                                <label htmlFor="zipCodeSecond" className="form-label required">
                                    Code postal :
                                </label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    id="zipCodeSecond"
                                    value={fullSecondAddress.zipCode}
                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                    onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, zipCode: e.target.value })}
                                />
                            </div>
                            <div className="me-3">
                                <label htmlFor="citySecond" className="form-label required">
                                    Ville :
                                </label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    id="citySecond"
                                    value={fullSecondAddress.city}
                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                    onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, city: e.target.value })}
                                />
                            </div>
                            <div>
                                <label htmlFor="countrySecond" className="form-label required">
                                    Pays :
                                </label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    id="countrySecond"
                                    value={fullSecondAddress.country}
                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                    onChange={(e) => setFullSecondAddress({ ...fullSecondAddress, country: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                    <h5>Adresse de facturation :</h5>
                    <input
                        type="checkbox"
                        id="sameAddress"
                        checked={isTheSameAsFullAddress}
                        disabled={documentState === "Facturé" || documentState === "Validé"}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            setIsTheSameAsFullAddress(checked);
                            setBillingAddress((prev) => ({
                                ...prev,
                                isTheSameAsFullAddress: checked,
                            }));
                        }}
                    />
                    <label htmlFor="sameAddress" className="form-label">
                        -Même adresse que l'adresse principale
                    </label>{" "}
                    <div className="mb-4 single-line">

                        <div className="d-flex line-flex">

                            <div className="me-3">

                                <label htmlFor="roadAndNumber" className="form-label required">
                                    Numéro et Rue :
                                </label>
                                <input
                                    disabled={isTheSameAsFullAddress || documentState === "Facturé" || documentState === "Validé"}
                                    type="text"
                                    required
                                    className="form-control"
                                    id="roadAndNumber"
                                    value={
                                        isTheSameAsFullAddress
                                            ? fullAddress.roadAndNumber
                                            : billingAddress.roadAndNumber
                                    }
                                    onChange={(e) =>
                                        setBillingAddress({
                                            ...billingAddress,
                                            roadAndNumber: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="me-3">

                                <label htmlFor="complement" className="form-label">
                                    Complément d'adresse :
                                </label>{" "}
                                <input
                                    disabled={isTheSameAsFullAddress || documentState === "Facturé" || documentState === "Validé"}
                                    type="text"
                                    className="form-control"
                                    id="complement"
                                    value={
                                        isTheSameAsFullAddress
                                            ? (fullAddress.complement)
                                            : billingAddress.complement
                                    }
                                    onChange={(e) =>
                                        setBillingAddress({ ...billingAddress, complement: e.target.value })
                                    }
                                />{" "}
                            </div>{" "}
                            <div className="me-3">
                                {" "}
                                <label htmlFor="zipCode" className="form-label required">
                                    Code postal :
                                </label>{" "}
                                <input
                                    disabled={isTheSameAsFullAddress || documentState === "Facturé" || documentState === "Validé"}
                                    type="text"
                                    required
                                    className="form-control"
                                    id="zipCode"
                                    value={
                                        isTheSameAsFullAddress ? fullAddress.zipCode : billingAddress.zipCode
                                    }
                                    onChange={(e) =>
                                        setBillingAddress({ ...billingAddress, zipCode: e.target.value })
                                    }
                                />
                            </div>
                            <div className="me-3">
                                <label htmlFor="city" className="form-label required">
                                    Ville :
                                </label>
                                <input
                                    disabled={isTheSameAsFullAddress || documentState === "Facturé" || documentState === "Validé"}
                                    type="text"
                                    required
                                    className="form-control"
                                    id="city"
                                    value={isTheSameAsFullAddress ? fullAddress.city : billingAddress.city}
                                    onChange={(e) =>
                                        setBillingAddress({ ...billingAddress, city: e.target.value })
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="form-label required">
                                    Pays :
                                </label>
                                <input
                                    disabled={isTheSameAsFullAddress || documentState === "Facturé" || documentState === "Validé"}
                                    type="text"
                                    required
                                    className="form-control"
                                    id="country"
                                    value={
                                        isTheSameAsFullAddress ? fullAddress.country : billingAddress.country
                                    }
                                    onChange={(e) =>
                                        setBillingAddress({ ...billingAddress, country: e.target.value })
                                    }
                                />
                            </div>
                        </div>
                    </div>


                    <h2 className='single-line'>Construction du Document</h2>
                    <div className="document-creation">
                        <div className="buttons">
                            <button disabled={documentState === "Facturé" || documentState === "Validé"} type="button" className="btn btn-primary" onClick={(e) => {
                                e.preventDefault();
                                setShowProductPopUp(true);
                            }}><BsPlusCircle /> Article</button>
                            <button disabled={documentState === "Facturé" || documentState === "Validé"} type="button" className="btn btn-primary" onClick={(e) => {
                                e.preventDefault();
                                addSection({ text: "Nouvelle rubrique" });
                            }}><BsPlusCircle /> Rubrique</button>
                            <button disabled={documentState === "Facturé" || documentState === "Validé"} type="button" className="btn btn-primary" onClick={(e) => {
                                e.preventDefault();
                                addComment({ text: "Nouveau commentaire" });
                            }}><BsPlusCircle /> Commentaire</button>
                        </div>
                        <div className="document">
                            <table className='table-maxxing'>
                                <thead>
                                    <tr>
                                        <th className="code" scope="col"><span>Code</span></th>
                                        <th className="labl" scope="col"><span>Libellé</span></th>
                                        <th className="desc" scope="col"><span>Desc.</span></th>
                                        <th className="qty" scope="col"><span>Qté</span></th>
                                        <th className="unit" scope="col"><span>Unité</span></th>
                                        <th className="pric" scope="col"><span>PU HT</span></th>
                                        <th className="ttc" scope="col"><span>T. HT</span></th>
                                        <th className="tax" scope="col"><span>TVA</span></th>
                                        <th className="ttc" scope="col"><span>T. TTC</span></th>
                                        <th className='act' scope="col"><span>Actions</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {document?.map((item, index) => {
                                        if (item._id) {
                                            let priceNoTaxes = Math.round((parseInt(item.quantity ? item.quantity : 1) * parseFloat(item.price)) * 100) / 100;
                                            let taxed = Math.round((priceNoTaxes * (item.taxes / 100)))
                                            let priceFull = (priceNoTaxes + taxed).toFixed(2);


                                            return (
                                                <tr key={index}>
                                                    <td><input disabled={documentState === "Facturé" || documentState === "Validé"} className="product-input" type="text" value={item.code?.toString().padStart(8, '0')} onChange={(e) => handleInputChange(e, index, 'code')} /></td>
                                                    <td><input disabled={documentState === "Facturé" || documentState === "Validé"} className="product-input" type="text" value={item.label} onChange={(e) => handleInputChange(e, index, 'label')} /></td>
                                                    <td className="desc-btn">
                                                        <button disabled={documentState === "Facturé" || documentState === "Validé"} type="button" onClick={() => handleDescriptionClick(index)}>
                                                            <BsFileEarmarkFont />
                                                        </button>
                                                        {showDescriptionDropdown === index && (
                                                            <div className="description-dropdown">
                                                                <textarea disabled={documentState === "Facturé" || documentState === "Validé"}
                                                                    className="form-control"
                                                                    value={item.description}
                                                                    onChange={(e) => handleInputChange(e, index, 'description')}
                                                                ></textarea>
                                                            </div>
                                                        )}
                                                    </td>

                                                    <td><input disabled={documentState === "Facturé" || documentState === "Validé"} className="product-input" type="number" min={1} value={item.quantity ? item.quantity : 1} onChange={(e) => handleInputChange(e, index, 'quantity')} /></td>
                                                    <td><input disabled={documentState === "Facturé" || documentState === "Validé"} className="product-input" type="text" value={item.unit} onChange={(e) => handleInputChange(e, index, 'unit')} /></td>
                                                    <td><input disabled={documentState === "Facturé" || documentState === "Validé"} className="product-input" type="text" value={`${item.price}€`} onChange={(e) => handleInputChange(e, index, 'price')} /></td>
                                                    <td><input disabled={documentState === "Facturé" || documentState === "Validé"} className="product-input" type="text" value={`${priceNoTaxes}€`} onChange={(e) => handleInputChange(e, index, 'priceNoTaxes')} /></td>
                                                    <td><input disabled={documentState === "Facturé" || documentState === "Validé"} className="product-input" type="text" value={`${item.taxes}%`} onChange={(e) => handleInputChange(e, index, 'taxes')} /></td>
                                                    <td><input disabled={documentState === "Facturé" || documentState === "Validé"} className="product-input" type="text" value={`${priceFull}€`} onChange={(e) => handleInputChange(e, index, 'priceFull')} /></td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <button className='pretty-button' type='button' ><BsTrash onClick={() => handleDelete(index)} /></button>
                                                            <div className='d-flex flex-column'>
                                                                <button disabled={documentState === "Facturé" || documentState === "Validé"} className='pretty-button' type='button' onClick={() => {
                                                                    moveItem(index, 'up');
                                                                }
                                                                }><BsArrowUpSquare /></button>
                                                                <button disabled={documentState === "Facturé" || documentState === "Validé"} className='pretty-button' type='button' onClick={() => {
                                                                    moveItem(index, 'down');
                                                                }
                                                                }><BsArrowDownSquare /></button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        } else if (item.text) {
                                            return (
                                                <tr key={index}>
                                                    <td>ㅤ</td>
                                                    <td colSpan={6} className="section product-input"><input disabled={documentState === "Facturé" || documentState === "Validé"} className='product-input' value={item.text} onChange={(e) => handleInputChange(e, index, 'text')}></input></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <button disabled={documentState === "Facturé" || documentState === "Validé"} className='pretty-button' type='button' ><BsTrash onClick={() => handleDelete(index)} /></button>
                                                            <div className='d-flex flex-column'>
                                                                <button disabled={documentState === "Facturé" || documentState === "Validé"} className='pretty-button' type='button' onClick={() => {
                                                                    moveItem(index, 'up');
                                                                }
                                                                }><BsArrowUpSquare /></button>
                                                                <button disabled={documentState === "Facturé" || documentState === "Validé"} className='pretty-button' type='button' onClick={() => {
                                                                    moveItem(index, 'down');
                                                                }
                                                                }><BsArrowDownSquare /></button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        } else if (item.comment) {
                                            return (
                                                <tr key={index}>
                                                    <td>ㅤ</td>
                                                    <td colSpan={6} className="comment"><input disabled={documentState === "Facturé" || documentState === "Validé"} className='product-input' value={item.comment} onChange={(e) => handleInputChange(e, index, 'comment')}></input></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <button disabled={documentState === "Facturé" || documentState === "Validé"} className='pretty-button' type='button' ><BsTrash onClick={() => handleDelete(index)} /></button>
                                                            <div className='d-flex flex-column'>
                                                                <button disabled={documentState === "Facturé" || documentState === "Validé"} className='pretty-button' type='button' onClick={() => {
                                                                    moveItem(index, 'up');
                                                                }
                                                                }><BsArrowUpSquare /></button>
                                                                <button disabled={documentState === "Facturé" || documentState === "Validé"} className='pretty-button' type='button' onClick={() => {
                                                                    moveItem(index, 'down');
                                                                }
                                                                }><BsArrowDownSquare /></button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null

                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='d-flex line-flex gap-3 single-line'>
                        <h2>Réductions ?</h2>
                        <input disabled={documentState === "Facturé" || documentState === "Validé"} type="checkbox" id="reduction" name="reduction" value="reduction" onChange={(e) => {
                            if (e.target.checked || reduction.currency || reduction.amount) {
                                setShowReduction(true);
                            } else {
                                setShowReduction(false);
                            }
                        }} />
                    </div>
                    <div hidden={!showReduction} className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="reductionCurrency" className="form-label">Devise :</label>
                                <select disabled={documentState === "Facturé" || documentState === "Validé"} className="form-select" id="reductionCurrency" value={reduction.currency} onChange={(e) => {
                                    setReduction({ ...reduction, currency: e.target.value });
                                }}>
                                    <option value="">Selectionner une devise</option>
                                    <option value="%">%</option>
                                    <option value="€">€</option>
                                </select>
                            </div>
                            <div className="me-3">
                                <label htmlFor="reductionAmount" className="form-label">Montant sur le total HT:</label>
                                <input disabled={documentState === "Facturé" || documentState === "Validé"} type="text" className="form-control" id="reductionAmount" value={reduction.amount} onChange={(e) => {
                                    setReduction({ ...reduction, amount: e.target.value });
                                }} />
                            </div>
                        </div>
                    </div>

                    <h2>Totaux</h2>
                    <div className="mb-3 single-line ">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="rawTotal" className="form-label">Total HT avant réductions :</label>
                                <input disabled type="text" className="form-control" id="rawTotal" value={rawTotal.toFixed(2)} onChange={(e) => setRawTotal(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="totalPreTax" className="form-label">Total Réductions :</label>
                                <input disabled type="text" className="form-control" id="totalPreTax" value={
                                    reduction.currency === "%" ? (rawTotal * (reduction.amount / 100)).toFixed(2) : reduction.currency === "€" ? (reduction.amount) : 0
                                } onChange={(e) => setTotalPreTax(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="totalPreTax" className="form-label">Total HT :</label>
                                <input disabled type="text" className="form-control" id="totalPreTax" value={totalPreTax.toFixed(2)} onChange={(e) => setTotalPreTax(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="taxesTotal" className="form-label">Total TVA :</label>
                                <input disabled type="text" className="form-control" id="taxesTotal" value={taxesTotal.toFixed(2)} onChange={(e) => setTaxesTotal(e.target.value)} />
                            </div>
                            <div className="me-3">
                                <label htmlFor="total" className="form-label">Total TTC :</label>
                                <input disabled type="text" className="form-control" id="total" value={total.toFixed(2)} onChange={(e) => setTotal(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <h2>Acomptes</h2>
                    <div className="mb-3 single-line">
                        <div className="d-flex line-flex">
                            <div className="me-3">
                                <label htmlFor="currency" className="form-label required">Devise :</label>
                                <select disabled={documentState === "Facturé" || documentState === "Validé"} required className="form-select" id="currency" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                                    <option value="">Selectionner une devise</option>
                                    <option value="%">%</option>
                                    <option value="€">€</option>
                                </select>
                            </div>
                            <div className="me-3">
                                <label htmlFor="amount" className="form-label required">Montant sur TTC :</label>
                                <input disabled={documentState === "Facturé" || documentState === "Validé"} type="text" className="form-control" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 single-line gap-3 d-flex down-buttons">
                        <button disabled={documentState === "Facturé" || documentState === "Validé"} type="submit" className="btn btn-primary">{isSubmitting ? <Spinner strokeWidth={8} width={40} color={"#faba79"} /> : "Enregistrer"}</button>
                        {_id !== "" && documentState === "Brouillon" ? (
                            type === "bill" ? (
                                <>
                                    <button
                                        disabled={documentState === "Facturé" || documentState === "Validé"}
                                        type="button"
                                        className={`btn btn-primary status ${documentState === "Brouillon" ? "gray" : "green"}`}
                                        onClick={
                                            () => {
                                                handleButtonClick()
                                            }
                                        } >
                                        {isSubmitting ? <Spinner strokeWidth={8} width={40} color={"#faba79"} /> : "Valider ?"} </button>
                                </>
                            ) : (
                                <button
                                    disabled={documentState === "Facturé" || documentState === "Validé"}
                                    type="button"
                                    className={`btn btn-primary status ${documentState === "Brouillon" ? "gray" : "green"}`}
                                    onClick={() => {
                                        setBillLoading(true);
                                        userContext.apiReducer("invoiceTransition", JSON.stringify({ _id, documentState }))
                                            .then((response) => {
                                                handleModif(response, action);
                                                setShowPopUp(false);
                                                setBillLoading(false);
                                            })
                                            .catch((err) => {
                                                console.log(err, "error");
                                            });
                                    }}
                                >
                                    {billLoading ? <Spinner strokeWidth={8} width={40} color={"#faba79"} /> : "Facturer ?"}
                                </button>
                            )
                        ) : null}
                    </div>

                </form>
            </div>
        </div>
    );
}