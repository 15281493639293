import React from "react";
import HomeViewCard from "../../../../../Cards/HomeViewCard/HomeViewCard";

// context
import UserContext from '../../../../../../dataContext'

export default function HomeView(props) {
    const context = React.useContext(UserContext)
    const burl = context.getBurl()

    return (
        <>
            {
                props.data?.map(data => {
                    const images = data.images.map((image) => {
                        return burl + "/uploads/" + image
                    })
                    const formattedText = data.text.split('\n').map((line, index) => <p key={index}>{line}</p>);
                    return (
                        <HomeViewCard title={data.title} images={images} text={formattedText} />
                    )
                })
            }
        </>
    )
}