// dernieres reservations
import React from "react";
import Modal from "../../Modals/Modal";
import Overlay from "../../Subcomponents/Overlay/Overlay";

export default function ListItem(props) {
    const [max] = React.useState(props.max || 100)
        , [selected, setSelected] = React.useState()
        , [maxModal, setMaxModal] = React.useState(6)
        , [itemModification, setItemModification] = React.useState({})

    return (
        <div className={"row"}>
            <div>
                <ul className={"list-group"}>
                    {props.data
                        .sort(props.sortFn)
                        .map((item, index) => {
                            if (index < max) {
                                return (
                                    <li className="list-group-item list-item" key={item.title} onClick={() => {
                                        setItemModification(item.data)
                                    }}>
                                        <h5>{item.title}</h5>
                                        <p>{item.description}</p>
                                    </li>
                                )
                            } else {
                                return null
                            }
                        })
                    }
                </ul>
                {moreIcon(props.data, props.title, max, setSelected)}
            </div>
            {ModalSelection(selected, setSelected, maxModal, setMaxModal, setItemModification)}
            <Overlay item={itemModification}
                     close={() => {
                         setItemModification(false)
                     }}
                     edit={(value) => {
                         setItemModification(value)
                     }}/>
        </div>
    )
}

function moreIcon(list, title, max, setSel) {
    const len = list.length - max
    if (list.length === 0) {
        return (
            <li className="list-group-item more-icon">
                <div className={"row"}>
                    Pas de nouvelles réservatons
                </div>
            </li>
        )

    } else if (len <= 0) {
        return (
            <li className="list-group-item more-icon" onClick={() => {
                setSel({title: title, items: list})
            }}>
                <div className={"row"}>
                    . . .
                </div>
            </li>
        )

    } else {
        return (
            <li className="list-group-item more-icon" onClick={() => {
                setSel({title: title, items: list})
            }}>
                <div className={"row"}>
                    + {len}
                </div>
            </li>
        )
    }
}

function ModalSelection(list, setList, max, setMax, setItemModification) {
    if (!list) return null
    return (
        <Modal show={list} close={() => setList()}
               classes={{
                   title: "light",
                   header: "warning-bg"
               }} title={list.title}>
            <ul className={"list-group"}>
                {list ?
                    list.items.map((item, index) => {
                        if (index < max) {
                            return (
                                <div>
                                    <li className="list-group-item">
                                        <div className={"row"} onClick={() => {
                                            setItemModification(item.data)
                                        }}>
                                            <div className={"col-10"}>
                                                <h5>{item.title}</h5>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    </li>
                                    {index + 1 === list.items.length ?
                                        <li className="list-group-item more-icon">
                                            <div className={"row"}>
                                                <p>...</p>
                                            </div>
                                        </li>
                                        :
                                        null
                                    }
                                </div>
                            )
                        }
                        else if (index === max) {
                            return (
                                <li className="list-group-item more-icon" onClick={() => {
                                    setMax(max + 2)
                                }}>
                                    <div className={"row"}>
                                        <button type="button" className="btn btn-warning">
                                            En voir plus
                                        </button>
                                    </div>
                                </li>
                            )
                        }
                        else {
                            return null
                        }
                    })
                    :
                    null
                }
            </ul>
        </Modal>
    )
}