import { useEffect, useState } from "react";
import Spinner from "../../../Components/Spinner";

export default function ProductsPopUp({ showProductPopUp, setShowProductPopUp, addProduct, userContext }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        userContext.apiReducer("getProducts").then(productsList => {
            setLoading(false)
            productsList = sortProducts(productsList, "abc", "code")
            setProducts(productsList)
        })
    }, [userContext])

    useEffect(() => {
    }, [showProductPopUp])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showProductPopUp && !event.target.closest('.billing-popup')) {
                setShowProductPopUp(false);
            }
        };

        window.document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showProductPopUp, setShowProductPopUp]);


    function sortProducts(list, method, key) {
        switch (method) {
            case "abc":
                if (key) {
                    list.sort((a, b) => {
                        if (a[key] < b[key]) return -1;
                        if (a[key] > b[key]) return 1;
                        return 0;
                    });
                }
                break;

            default:
                break;
        }
        return list
    }

    function capitalizeText(text) {
        return text.toLowerCase().replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }

    // const filteredProducts = products.filter(contact =>
    //     contact?.clientId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     contact?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     contact?.email?.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    if (loading) return (
        <div className={'billing-popup' + (showProductPopUp ? " open" : "")} onClick={(e) => { if (e.target.classList.contains('billing-popup')) { e.target.classList.remove('open'); setShowProductPopUp(false); } }}>
            <div className="container">
                <h3>Articles</h3>
                <h2>Chargement des produits...</h2>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
            </div>
        </div>
    )
    return (
        <div className={'billing-popup' + (showProductPopUp ? " open" : "")} onClick={(e) => { if (e.target.classList.contains('billing-popup')) { e.target.classList.remove('open'); setShowProductPopUp(false); } }}>
            <div className="container">
                <h3>Articles</h3>

                <div className={"col-12"}>
                    <div className="search">
                        <input type="text" id="formControlLg" className="form-control form-control-lg" placeholder='Rechercher un produit...' onInput={(e) => setSearchTerm(e.target.value)} />
                    </div>

                    <table className='table-maxxing'>
                        <thead>
                            <tr>
                                <th scope='col'>
                                    <span>

                                    </span>
                                </th>
                                <th scope='col'>
                                    <span>
                                        Code
                                    </span>
                                </th>
                                <th scope='col'>
                                    <span>
                                        Libellé
                                    </span>
                                </th>
                                <th scope='col'>
                                    <span>
                                        Type
                                    </span>
                                </th>
                                <th scope='col'>
                                    <span>
                                        Famille
                                    </span>
                                </th>
                                <th scope='col'>
                                    <span>
                                        Prix HT (€)
                                    </span>
                                </th>
                                <th scope='col'>
                                    <span>
                                        Prix TTC (€)
                                    </span>
                                </th>
                                <th scope='col'>
                                    <span>
                                        Unité
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {products?.map((product, index) => {
                                // console.log(product, "product")
                                if (!product.code) return null
                                else if (searchTerm && !product.label.toLowerCase().includes(searchTerm.toLowerCase())) return null
                                return (
                                    <tr data-num-ligne="0" className="" key={index} onClick={(e) => {
                                        addProduct(product)
                                        setShowProductPopUp(false);
                                    }} >
                                        <td>
                                            <div className="display-flex flex-align-items-center">
                                                {/* <input  type="checkbox" name={"j-select-changement-statut-" + index} className="rca-legacy j-select-changement-statut" data-id="15612346" /> */}
                                            </div>
                                        </td>
                                        <td data-label="Code" className="lesser " title="Afficher l'aperçu de l'article">
                                            <span className="recherche-selected  j-dont-close-sliding-panel"></span>{product.code?.toString().padStart(8, '0')}
                                        </td>
                                        <td data-label="Libellé" className="full" title="Afficher l'aperçu de l'article">
                                            <span className="recherche-selected "></span>{product.label}
                                        </td>
                                        <td data-label="Type" className="lesser capitalize-text" title="Afficher l'aperçu de l'article">
                                            <span className="recherche-selected  j-dont-close-sliding-panel"></span>{capitalizeText(product.type)}
                                        </td>
                                        <td data-label="Famille" className="lesser" title="Afficher l'aperçu de l'article">
                                            <span className="recherche-selected  j-dont-close-sliding-panel"></span>{product.family}</td>
                                        <td data-label="Prix HT (€)" className="lesser text-right" title="Afficher l'aperçu de l'article">
                                            {product.price}
                                        </td>
                                        <td data-label="Prix TTC (€)" className="lesser text-right" title="Afficher l'aperçu de l'article">
                                            {product.priceFull}
                                        </td>
                                        <td data-label="Unité" className="lesser" title="Afficher l'aperçu de l'article">
                                            <span className="recherche-selected  j-dont-close-sliding-panel"></span>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}