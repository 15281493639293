import React from 'react'

// ICONS
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { slugify } from '../../../Utils/functions'

export default function Suggestion(props) {
    const ref = React.useRef()

    const [list, setList] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)
        if (!props.data) return null
        let data = props.data

        if (props.search && props.type !== "products") {
            data = data.filter((obj) =>
                JSON.stringify(obj).toLowerCase().includes(props.search.toLowerCase())
            )
        } else if (props.type === "products") {
            data = data.filter((obj) => obj['Code'] && obj["Libellé"])
            data = data.filter((obj) => slugify(obj['Code']).includes(props.search) || slugify(obj["Libellé"]).includes(props.search))
        }
        if (props.type !== "" && props.type !== "products") {
            data = data.filter((obj) => obj.type === props.type
            )
        }
        setList(data)
        setLoading(false)

        window.addEventListener('mousedown', (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                props.onBlur()
            }
        })
    }, [setList, props], [setLoading, props])


    if (!props.click || !props.data) {
        console.error('props.click or props.data missing')
        return null
    }

    if (!props.active) return null
    if (list.length === 0) {
        return (
            <ul className="suggestions" ref={ref}>
                <li key={'nothing'}>
                    <h6>Aucun résultat</h6>
                </li>
            </ul>
        )
    }
    return (
        <ul className="suggestions" ref={ref}>
            {loading ?
                <li key={"loading"}>
                    <h6><AiOutlineLoading3Quarters className={"icons"} /> Chargement</h6>
                </li>
                :
                list.map((item) => {
                    if (item.cancelled || item.archive) return null
                    if (item.type === 'Client' && item.properties.length === 0) {
                        return (
                            <li key={item.id} id={item.name}>
                                <h6>{item.type} - {item.lastName} {item.name} - PAS DE PROPRIÉTÉS</h6>
                                <p>{item.phone}</p>
                                <p>{item.email}</p>
                            </li>
                        )
                    } else if (props.type === "products") {
                        return (
                            <li className="row" key={item.Code} id={item.name} onClick={() => props.click(item)}>
                                <h6 className="col">{item.Code} - {item.Libellé}</h6>
                                <p className="col">{item["Prix de vente HT"]}</p>
                            </li>
                        )
                    }
                    return (
                        <li className="row" key={item.id} id={item.name} onClick={() => props.click(item)}>
                            <h6 className="col">  {item.type} - {item.lastName} {item.name}</h6>
                            <p className="col">{item.phone}</p>
                            <p className="col">{item.email}</p>
                        </li>
                    )
                })}

        </ul>
    )
}
