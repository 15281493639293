import { InputField, TextAreaField, CheckboxField, DropdownField } from './FieldComponents';

export const Field = ({ field }) => {
    switch (field.type) {
        case 'input':
            return <InputField field={field} />;
        case 'textarea':
            return <TextAreaField field={field} />;
        case 'check':
            return <CheckboxField field={field} />;
        case 'dropdown':
            return <DropdownField field={field} />;
        default:
            return null;
    }
};
