//External components
import React from "react";
import {Redirect, Route} from "react-router-dom";

// CONTEXT
import UserContext from '../dataContext'

export default function PublicRoutes({component: Component, ...rest}) {
    const userContext = React.useContext(UserContext)
    if (userContext.isLogged()) {
        return (
            <Redirect to={'/'}/>
        )
    }

    return (
        <Route {...rest} render={() => {
            return (
                <Component/>
            )
        }}/>
    )

}