import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const blue = "#003952"
// const gold = "#FABA79"

const styles = StyleSheet.create({
    tableRow: {
        flexDirection: "row",
        marginVertical: 2,
    },
    tableColumn: {
        borderStyle: "solid",
        // borderRightWidth: 1,
        borderColor: '#000000',
        padding: 5,
        // borderBottomWidth: 1,
    },
    header: {
        backgroundColor: blue,
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'left',
        fontSize: 11,
        paddingTop: 5,
        paddingBottom: 5,
        borderStyle: "solid",
        borderColor: blue,
        borderRightWidth: 1,

    },
    cell: {
        textAlign: 'left',
        fontSize: 9,
        fontWeight: '600',
        fontFamily: 'arial'
    },
    subheader: {
        fontWeight: '600',
        marginTop: 0,
        backgroundColor: blue,
        color: 'white',
    },
    comment: {
        fontSize: 8,
        textAlign: 'left',
        padding: 5,
    }

});

const TableRow = ({ data, isHeader = false, widths = [], tableStyle }) => (
    <View style={styles.tableRow}>
        {data.map((item, index) => (
            <View key={index} style={[styles.tableColumn, isHeader ? styles.header : styles.cell, { width: widths[index] || 'auto' }, tableStyle === "subheader" ? styles.subheader : styles.comment]}>
                <Text>{item}</Text>
            </View>
        ))}
    </View>
);

export default TableRow;
