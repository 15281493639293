import { useState } from "react"
import { BsCheckLg, BsFillPersonLinesFill, BsFillXCircleFill, BsPencilSquare, BsTrashFill } from "react-icons/bs"
import "../../../assets/scss/secteurs.scss";
import Spinner from "../../Spinner";

export default function Secteur({ index, originalSector, userContext, deleteSector, areLinksBeingEdited, setAreLinksBeingEdited, selectedOwners, setSelectedSector, hidden, setSectors }) {
    const [isEditing, setIsEditing] = useState(originalSector.newSector || false)
    const [isEditingLinks, setIsEditingLinks] = useState(false)
    const [sector, setSector] = useState(originalSector)
    const [file, setFile] = useState(originalSector?.file || "");
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isValidated, setIsValidated] = useState(false)
    const [isValidatedLinks, setIsValidatedLinks] = useState(false)
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleDelete = () => {
        if (window.confirm('Êtes vous sûr de vouloir supprimer ce secteur ?')) {
            userContext.apiReducer("deleteSector", { id: sector.id })
                .then(() => {
                    deleteSector(index)
                })
        }
    }
    const handleEdit = () => {
        setIsEditing(true)
    }
    const handleCancel = () => {
        setIsEditing(false)
        setSector(originalSector)
        if (originalSector.newSector) {
            deleteSector(index)
        }
    }
    const handleSave = (e, sector) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', sector.name);
        formData.append('description', sector.description);
        formData.append('id', sector.id);

        const action = originalSector.newSector ? "addSector" : "modifySector";

        userContext.apiReducer(action, formData)
            .then((response) => {
                // console.log(response, "response")
                setSector(response);
                setIsEditing(false);
                setIsSubmitting(false);
                originalSector.newSector = false;
                // Set isValidated as true for a few seconds, to show a green tick to the user to confirm that the data has been saved
                setIsValidated(true);
                setSectors((prevState) => {
                    const newSectors = [...prevState];
                    newSectors[index] = response;
                    return newSectors;
                })
                setTimeout(() => {
                    setIsValidated(false);
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
                setIsSubmitting(false);
            });
    };

    const handleEditLinks = () => {
        setIsEditingLinks(true)
        setAreLinksBeingEdited(true)
        setSelectedSector(sector)
    }
    const handleCancelLinks = () => {
        setIsEditingLinks(false)
        setAreLinksBeingEdited(false)
    }
    const handleSaveLinks = (e) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('id', sector.id);
        formData.append('owners', JSON.stringify(selectedOwners));

        userContext.apiReducer("modifySector", formData)
            .then((response) => {
                // console.log(response, "response")
                setSector(response);
                setSectors((prevState) => {
                    const newSectors = [...prevState];
                    newSectors[index] = response;
                    return newSectors;
                })
                setIsEditingLinks(false);
                setIsSubmitting(false);
                setAreLinksBeingEdited(false);
                // Set isValidated as true for a few seconds, to show a green tick to the user to confirm that the data has been saved
                setIsValidatedLinks(true);
                setTimeout(() => {
                    setIsValidatedLinks(false);
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
                setIsSubmitting(false);
            });
    };

    return (
        <div hidden={hidden} key={index} className={"cardParent" + (areLinksBeingEdited && !isEditingLinks ? " unfocused" : "")}>
            <div className="selection">
                {isEditing ? (
                    <>
                        <button className='btn btn-primary mx-2 btn-cancel btn-accordion' onClick={handleCancel}><BsFillXCircleFill /></button>
                        <button className='btn btn-primary mx-2 btn-save btn-accordion' onClick={(e) => handleSave(e, sector)}>{isSubmitting ? <Spinner strokeWidth={4} width={20} color={"#ffffff"} /> : <BsCheckLg />}</button>
                    </>
                ) : (
                    <>
                        <button className='btn btn-primary mx-2 btn-delete btn-accordion' onClick={handleDelete}><BsTrashFill /></button>
                        <button className='btn btn-primary mx-2 btn-edit btn-accordion' onClick={handleEdit}>{isValidated ? (<div style={{ color: "#66ff00" }}><BsCheckLg /></div>) : <BsPencilSquare />}</button>
                    </>
                )}
            </div>
            <div className={"cards" + (isEditingLinks ? " selected" : "")}>
                <div className="sector">
                    <h4><span><input value={sector?.name} onChange={(value) => setSector((prevState) => ({ ...prevState, name: value.target.value }))} className={"sectorInput" + (isEditing ? " bordered" : "")} type="text" placeholder="Nom du secteur" disabled={!isEditing}></input></span></h4>
                    <textarea value={sector?.description} onChange={(value) => setSector((prevState) => ({ ...prevState, description: value.target.value }))} className={"sectorInput" + (isEditing ? " bordered" : "")} placeholder="Description du secteur" disabled={!isEditing}></textarea>
                </div>
                <div className="file">
                    {
                        isEditing ? (
                            <input required accept="application/pdf" type="file" id="file" name="file" onChange={handleFileChange} />
                        ) : (
                            sector?.file?.length > 0 ? (
                                <>
                                    <p className="noFile">"{sector.file}"</p>
                                    <a href={userContext.getBurl() + "/Static/sectors/" + sector.file} target="_blank" rel="noreferrer" className="noFile">Voir le fichier</a>
                                </>

                            ) : (<p className="noFile">Pas de document associé pour ce secteur</p>)
                        )
                    }
                </div>
            </div>
            <div className="selection two">
                {isEditingLinks ? (
                    <>
                        <button className='btn btn-primary mx-2 btn-cancel btn-accordion' onClick={handleCancelLinks}><BsFillXCircleFill /></button>
                        <button className='btn btn-primary mx-2 btn-save btn-accordion' onClick={(e) => handleSaveLinks(e)}>{isSubmitting ? <Spinner strokeWidth={4} width={20} color={"#ffffff"} /> : <BsCheckLg />}</button>
                    </>
                ) : (
                    <>
                        <button className='btn btn-primary mx-2 btn-edit btn-accordion' onClick={handleEditLinks}>{isValidatedLinks ? (<div style={{ color: "#66ff00" }}><BsCheckLg /></div>) : <BsFillPersonLinesFill />}</button>
                    </>
                )}
            </div>
        </div>
    )
}