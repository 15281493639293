//External components
import React from "react";
import Image from 'react-image-webp';
import {useHistory, useParams} from "react-router-dom";

//internal Components
import LoginBox from "../Components/LoginBox";

//Context
import DataContext from "../dataContext";

//Images
import logoWEBP from '../Images/Logo/logo-white.webp'
import logoPNG from '../Images/Logo/logo-white.png'

export default function ResetPassword() {
    const context = React.useContext(DataContext)
        , history = useHistory()
        , params = useParams()

    const [formData, setFormData] = React.useState({
            password: '',
            confirmation: ''
        }),
        [message, setMessage] = React.useState({value: "", error: false})

    const handleChange = (e) => {
        if (e.target.name === "remember") {
            setFormData({
                ...formData,
                [e.target.name]: e.target.checked
            })
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value.trim()
            })
        }
    }

    return (
        <div>
            <div className={'background'}/>
            <LoginBox>
                <Image webp={logoWEBP} src={logoPNG}
                       className={'logoLogin'}
                       alt={'background'} title={"MyPacome"}/>
                <h1 className={"logo-text"}>MYPacôme</h1>
                <h3 className={"logo-text"}>Renseignez votre nouveau mot de passe</h3>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    if (formData.password !== formData.confirmation) {
                        setMessage({value: 'Les deux mots de passes doivent être identique.', error: true})
                        return null
                    }
                    context.apiReducer('resetPassword', {password: formData.password}, params)
                        .then(() => {
                            setMessage({
                                value: 'Votre mot de passe à bien été mis à jour. Cliquez sur se connecter afin de vous connecter sur la plateforme.',
                                error: false
                            })
                        })
                        .catch((err) => {
                            console.log(err)
                            setMessage({value: 'Une erreur s\'est produite', error: true})
                        })
                }}>
                    <div className={"form-group"}>
                        <input type={"password"} className={"form-control mt-lg-2"}
                               id={"password"} name={"password"} onChange={handleChange}
                               defaultValue={''}
                               aria-describedby={"passwordHelp"} placeholder={"Mot de passe"}/>
                        <input type={"password"} className={"form-control mt-lg-2"}
                               id={"confirmation"} name={"confirmation"} onChange={handleChange}
                               defaultValue={''}
                               aria-describedby={"passwordHelp"} placeholder={"Confirmation du mot de passe"}/>
                    </div>

                    <div className={"m-2"}>
                        <p className={message.error ? "warning" : "success"}>{message.value}</p>
                    </div>

                    <div className={"form-group mt-lg-4 d-flex justify-content-between"}>
                        <button type={'cancel'} className={"btn btn-outline-light"} onClick={() => {
                            history.push('/login')
                        }}> Se connecter
                        </button>
                        <button type={'submit'} className={"btn btn-warning"}> Réinitialiser</button>
                    </div>

                </form>
            </LoginBox>
        </div>
    );
}