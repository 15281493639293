import { useEffect, useState } from 'react'
import { Field } from './Field'
import Accordion from '../../../Cards/Accordion'

export default function HomeView({ homeView, burl, homeViewVersion }) {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenSection, setIsOpenSection] = useState(false)

    useEffect(() => {
    }, [homeViewVersion, homeView])

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    }

    const toggleAccordionSection = () => {
        setIsOpenSection(!isOpenSection);
    }

    return (
        <div className='mt-4 mb-5'>
            <h2 className='blue fw-bold'>Prévisualiser les changements sur les Fiches Home</h2>
            <form className={"home-view-add-container"}>
                <div className={"home-view-header"}></div>
                <Accordion title="Afficher la Fiche Home test" onClickFunc={toggleAccordion} key={homeViewVersion}>
                    {Array.isArray(homeView) ? homeView?.map((item, indexCategory) => {
                        const images = item?.images?.map((image) => {
                            return burl + "/uploads/" + image
                        })
                        return (
                            <Accordion title={item.title} key={`category-${indexCategory}-${homeViewVersion}`} onClickFunc={toggleAccordionSection} >
                                <div className={"section"}>

                                    <div className="input-title category">
                                        <Field field={{ title: "Titre", value: item.title, type: "input" }} />
                                    </div>
                                    {item.content.map((item, indexSection) => {
                                        return (
                                            <div key={`section-${indexSection}`} className="category">
                                                <hr></hr>
                                                <p className="name text-center fw-bold">{item.title}</p>
                                                {item.type === "text" ? (
                                                    item.fields.map((field, indexSingle) => (
                                                        <Field key={`field-${indexSingle}`} field={field} />
                                                    ))
                                                ) : (
                                                    <div className="section-check">
                                                        <input
                                                            type='checkbox'
                                                            name='check'
                                                            defaultValue={true} // checked by default to be able to see it on the preview
                                                            readOnly disabled
                                                            defaultChecked={true} // checked by default to be able to see it on the preview
                                                            className="form-check-input"
                                                        />

                                                        {
                                                            item.fields.map((field, indexSingle) => {
                                                                let cssClass
                                                                item.defaultValue = true // checked by default to be able to see it on the preview
                                                                item.defaultValue ? cssClass = "" : cssClass = " d-none"
                                                                return (

                                                                    <div key={`field-${indexSingle}`} className={"input type-" + field.type + cssClass}>
                                                                        <div className="icon">{field.title}</div>
                                                                        <div className="area" style={{ width: "auto" }}>
                                                                            {field.type === "input" ? (
                                                                                <input
                                                                                    name={"text"}

                                                                                    defaultValue={field.defaultValue}
                                                                                />
                                                                            ) : field.type === "textarea" ? (
                                                                                <textarea
                                                                                    name={"textarea"}

                                                                                    defaultValue={field.defaultValue}
                                                                                    style={{ resize: "none" }}
                                                                                />
                                                                            ) : field.type === "check" ? (
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name={"check"}

                                                                                    defaultValue={field.defaultValue}
                                                                                    className="form-check-input"
                                                                                />
                                                                            ) : (
                                                                                <select
                                                                                    className="form-select"
                                                                                    name={"dropdown"}

                                                                                    defaultValue={field.defaultValue}
                                                                                >
                                                                                    {field.options.map((option, index) => (
                                                                                        <option defaultValue={option} key={index}>{option}</option>
                                                                                    ))}
                                                                                </select>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                )

                                                            })
                                                        }

                                                    </div>

                                                )}
                                            </div>
                                        );
                                    })}
                                    <div className="form-group multi-preview">
                                        <div className="row text-center text-lg-start">
                                            {images?.map((url, index) => (
                                                <div className="col-6" key={index} >
                                                    <div className="image-container">
                                                        <img src={url} alt="..." className="img-fluid img-thumbnail" />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </Accordion>
                        )
                    }) : null}
                </Accordion>
            </form>
        </div>
    )
}

// import { useEffect, useState } from 'react';
// import { Field } from './Field';
// import Accordion from '../../../Cards/Accordion';

// export default function HomeView({ homeView, burl }) {
//     const [isOpen, setIsOpen] = useState(false);

//     useEffect(() => { }, [homeView]);

//     const toggleAccordion = () => {
//         setIsOpen(!isOpen);
//     };

//     return (
//         <>
//             <form className={"home-view-add-container"}>
//                 <div className={"home-view-header"}></div>
//                 <Accordion title="Preview" onClickFunc={toggleAccordion}>
//                     {isOpen && homeView.map((item, indexCategory) => {
//                         // ... rest of your code
//                     })}
//                 </Accordion>
//             </form>
//         </>
//     );
// }
