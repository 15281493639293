import React, { useEffect, useRef, useState } from 'react';
import { BsTrashFill, BsPencilSquare, BsCheckLg, BsFillXCircleFill } from "react-icons/bs";
import Accordion from '../../../Cards/Accordion';
import Option from './Option';

export default function Field({ field, onFieldChange, onFieldDelete, fieldIndex, categoryIndex, sectionIndex, listOfModifications, setListOfModifications }) {
    const [newOption, setNewOption] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState(field.title);
    const [showOptions, setShowOptions] = useState(false);
    const [, setEditedOption] = useState("");
    const [deletedOptions, setDeletedOptions] = useState([]);
    const [editedOptions, setEditedOptions] = useState([])
    const [addedOptions, setAddedOptions] = useState([])
    const [editedType, setEditedType] = useState(field.type);
    const [originalOptions, setOriginalOptions] = useState(field.options)
    const originalTitle = useRef(field.title);
    const originalType = useRef(field.type);

    useEffect(() => {
    }, [field.options])

    const handleOptionSave = (option, oldOption) => {
        const updatedOptions = field.options.map(opt => opt === oldOption ? option : opt)
        onFieldChange({ ...field, options: updatedOptions }, fieldIndex)
        setEditedOptions([...editedOptions, option])
        setEditedOption(null)
    }

    const handleOptionAdd = () => {
        if (newOption !== '') {
            onFieldChange({ ...field, options: [...field.options, newOption] }, fieldIndex)
            setNewOption('');
            setAddedOptions([...addedOptions, newOption])
        }
    }

    const handleOptionDelete = (optionToDelete) => {
        if (isEditing) {
            onFieldChange({ ...field, options: field.options.filter(option => option !== optionToDelete) }, fieldIndex)
            setDeletedOptions([...deletedOptions, optionToDelete])
        }
    }

    const handleDelete = () => {
        if (window.confirm('Êtes vous sûr de vouloir supprimer ce champ ?')) {
            onFieldDelete(fieldIndex, listOfModifications)
        }
    }

    const handleEdit = () => {
        setIsEditing(true)
        originalTitle.current = editedTitle
        originalType.current = editedType
        setOriginalOptions(field.options);
    }


    const handleCancel = () => {
        setIsEditing(false)
        setEditedTitle(originalTitle.current)
        setEditedType(originalType.current)
        // restore original options
        onFieldChange({ ...field, options: originalOptions }, fieldIndex)
        setEditedOption(null)
    }

    const handleSave = () => {
        // if title and type are both modified
        if (editedTitle !== originalTitle.current && editedType !== originalType.current) {
            onFieldChange({ ...field, title: editedTitle, type: editedType }, fieldIndex)
            setListOfModifications(prevState => [...prevState, { action: "modifyFieldTitle", fieldIndex: fieldIndex, categoryIndex: categoryIndex, sectionIndex: sectionIndex, value: editedTitle }, { action: "modifyFieldType", fieldIndex: fieldIndex, categoryIndex: categoryIndex, sectionIndex: sectionIndex, value: editedType }]);
            originalTitle.current = editedTitle
            originalType.current = editedType

        }
        else if (editedTitle !== originalTitle.current) {
            onFieldChange({ ...field, title: editedTitle }, fieldIndex)
            setListOfModifications(prevState => [...prevState, { action: "modifyFieldTitle", fieldIndex: fieldIndex, categoryIndex: categoryIndex, sectionIndex: sectionIndex, value: editedTitle }]);
            originalTitle.current = editedTitle
        }
         else if (editedType !== originalType.current) {
            handleTypeChange({ target: { value: editedType } })
            setListOfModifications(prevState => [...prevState, { action: "modifyFieldType", fieldIndex: fieldIndex, categoryIndex: categoryIndex, sectionIndex: sectionIndex, value: editedType }]);
            originalType.current = editedType
        }
    

        if (deletedOptions.length > 0) {
            deletedOptions.forEach((option, index) => {
                setListOfModifications(prevState => [...prevState, { action: "deleteFieldOption", fieldIndex: fieldIndex, categoryIndex: categoryIndex, sectionIndex: sectionIndex, optionIndex: index, value: option}])
            })
            setDeletedOptions([])
        }

        if (addedOptions.length > 0) {
            addedOptions.forEach((option, index) => {
                setListOfModifications(prevState => [...prevState, { action: "addFieldOption", fieldIndex: fieldIndex, categoryIndex: categoryIndex, sectionIndex: sectionIndex, value: option }])
            })
            setAddedOptions([])
        }

        if (editedOptions.length > 0) {
            editedOptions.forEach((option, index) => {
                setListOfModifications(prevState => [...prevState, { action: "modifyFieldOption", fieldIndex: fieldIndex, categoryIndex: categoryIndex, sectionIndex: sectionIndex, optionIndex: index, value: option }])
            })
            setEditedOptions([])
        }
        
        setIsEditing(false)
    }
            
    const handleTypeChange = (e) => {
        let newType = e.target.value;
        let newField = { ...field, type: newType };

        // Initialize options if the type is dropdown
        if (newType === 'dropdown' && !newField.options) {
            newField.options = [];
        }

        setEditedType(e.target.value)
        onFieldChange(newField, fieldIndex)
    }

    return (
        <div className="row mt-3">
            <div className="col form-group">
                {isEditing ? (
                    <input
                        className='form-control'
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                    />
                ) : (
                    <input
                        className='form-control'
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        disabled
                    />
                )}
            </div>
            <div className="col">
                {isEditing ? (
                    <select
                        className='form-control'
                        name={"type"}
                        value={editedType}
                        onChange={handleTypeChange}
                    >
                        <option value="input">Input</option>
                        <option value="textarea">Textarea</option>
                        <option value="check">Check</option>
                        <option value="dropdown">Dropdown</option>
                    </select>
                ) : (
                    <select
                        className='form-control'
                        name={"type"}
                        value={editedType}
                        onChange={handleTypeChange}
                        disabled
                    >
                        <option value="input">Input</option>
                        <option value="textarea">Textarea</option>
                        <option value="check">Check</option>
                        <option value="dropdown">Dropdown</option>
                    </select>
                )}

            </div>
            <div className={`col ${showOptions ? "open" : ""}`}>
                {editedType === "dropdown" && field.options && (
                    <>

                        <Accordion title={"Options du dropdown"} titleSize={"6"} onClickFunc={() => setShowOptions(!showOptions)}>
                            {(
                                <div className='row dropdown-elements'>
                                    {field.options.map((option, index) => (
                                        <Option isEditingField={isEditing} key={index} option={option} handleOptionDelete={handleOptionDelete} handleOptionSave={handleOptionSave} />
                                    ))}
                                    <div className="row">
                                        <input disabled={!isEditing} className='form-control col' name={"newOption"} value={newOption} onChange={(e) => setNewOption(e.target.value)} />
                                        <button disabled={!isEditing} className='col-2 btn btn-primary btn-save btn-accordion' onClick={handleOptionAdd}>+</button>
                                    </div>
                                </div>
                            )}
                        </Accordion>
                    </>
                )}
            </div>
            <div className="col">
                {isEditing ? (
                    <>
                        <button className='btn btn-primary mx-2 btn-cancel btn-accordion' onClick={handleCancel}><BsFillXCircleFill /></button>
                        <button className='btn btn-primary mx-2 btn-save btn-accordion' onClick={handleSave}><BsCheckLg /></button>
                    </>
                ) : (
                    <>
                        <button className='btn btn-primary mx-2 btn-delete btn-accordion' onClick={handleDelete}><BsTrashFill /></button>
                        <button className='btn btn-primary mx-2 btn-edit btn-accordion' onClick={handleEdit}><BsPencilSquare /></button>
                    </>
                )}
            </div>
        </div>
    );
}
