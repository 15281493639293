// EXTERNAL MODULES
import React from 'react'
import moment from 'moment';
import localization from 'moment/locale/fr';

// INTERNAL COMPONENTS
import DropDownButton from "../Components/Subcomponents/DropdownButton"
import TimelineCustom from "../Components/Plannings/TimelineCustom"
import IndividualCustom from "../Components/Plannings/IndividualCustom"
import Overlay from "../Components/Subcomponents/Overlay/Overlay";
import { displayPopover } from "../Components/Subcomponents/Popover/PopoverPlanning";

// ICONS

// CONTEXT
import UserContext from '../dataContext'
import PlanningKPI from '../Components/PlanningKPI';
import Spinner from '../Components/Spinner';

// SCSS
import "../assets/scss/planning.scss"

// LOCALES
moment.updateLocale('fr', localization);

export default function Planning(props) {
    const userContext = React.useContext(UserContext)
        , urlSearchParams = new URLSearchParams(window.location.search)
        , params = Object.fromEntries(urlSearchParams.entries())

    const [loading, setLoading] = React.useState(true)
        , [h, setH] = React.useState(window.innerWidth < 950 ? '81vh' : '84vh')
        , [calendars, setCalendars] = React.useState(params.calendars ? params.calendars : "agency")
        , [list, setList] = React.useState([])
        , [active, setActive] = React.useState(false)
        // , [itemSelected] = React.useState({})
        , [itemModification, setItemModification] = React.useState({})
        , [sI, setSI] = React.useState({
            item: {},
            type: "",
            position: {
                x: 0,
                y: 0
            }
        })
        , [filters, setFilters] = React.useState({
            date: params.date ? moment.unix(params.date) : moment().startOf('month'),
            search: params.search ? params.search : "",
            selected: params.selected ? params.selected : "",
            view: params.view ? params.view : window.innerWidth < 950 ? 'day' : 'month',
        })

    // RELOAD
    const [, r] = React.useState({})

    const forceUpdate = React.useCallback(() => {
        setLoading(true)
        r({})
        setLoading(false)
    }, [r]);

    // FUNCTIONS

    const changeParams = React.useCallback(() => {
        let query = ("calendars=" + calendars)
        Object.keys(filters).forEach((key) => {
            if (filters[key]) {
                if (filters[key]._isAMomentObject) {
                    query += "&" + key + "=" + filters[key].unix()
                } else {
                    query += "&" + key + "=" + filters[key]
                }
            }
        }, [])
        const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + query;
        if (window.location.href !== newUrl) window.history.pushState({ path: newUrl }, '', newUrl);
    }, [calendars, filters])

    const handleObject = React.useCallback((name, value) => {
        let item = filters
        item[name] = value
        setFilters(item)
    }, [filters, setFilters])

    const previous = () => {
        let start = moment(filters.date)
        start.subtract(1, filters.view).startOf('day')
        handleObject('date', start)
        forceUpdate()
    }

    const next = () => {
        let start = moment(filters.date)
        start.add(1, filters.view).startOf('day')
        handleObject('date', start)
        forceUpdate()
    }

    React.useEffect(() => {
        changeParams()
        if (!params.view) {
            if (window.innerWidth < 950) {
                handleObject('view', 'day')
            } else {
                handleObject('view', 'month')
            }
        }
        if (window.innerWidth < 950) {
            setH('81vh')
        } else {
            setH('84vh')
        }
        if (!userContext.loading && userContext.data.people &&
            loading) {
            setList(userContext.data.people.Client)
            setLoading(false)
        }
    }, [params, setH, r, calendars, forceUpdate, userContext, active, setActive, setList, loading, setLoading, changeParams, handleObject])
    return (
        <div className='planning-parent'>
            <div className={"menu-planning p-2 flex-md-row flex-column-reverse row justify-content-around"}>
                {/* <div className={"row"}> */}
                <DropDownButton setValue={(value) => {
                    handleObject('view', value)
                    forceUpdate()
                }} options={[
                    { name: "Journée", value: "day" },
                    { name: "Semaine", value: "week" },
                    { name: "Mois", value: "month" },
                ]} selected={filters.view} cssClass={"drop-month"} />
                {/* </div> */}
                {/* <div className={"row"}>
                        <div className={"btn-group"} role={"group"} aria-label={"Basic example"}>
                            <button type={"button"} className={"btn btn-tertiary"} onClick={previous}>Précédent
                            </button>
                            <button type={"button"} className={"btn btn-tertiary"} onClick={() => {
                                let date = moment().startOf('day')
                                handleObject('date', date)
                                forceUpdate()
                            }}>Aujourd'hui
                            </button>
                            <button type={"button"} className={"btn btn-tertiary"} onClick={next}>Suivant
                            </button>
                        </div>
                    </div> */}
                <div className={"col d-none d-md-block kpi"}>
                    {/* <h3 className={"text-center dark text-capitalize"}>{filters.date.format('MMMM YYYY')}</h3> */}
                    <PlanningKPI date={filters.date} userContext={userContext} />
                </div>

                <DropDownButton setValue={(value) => {
                    setCalendars(value)
                }} options={[
                    { name: "Agence", value: "agency" },
                    { name: "Individuel", value: "individual" },
                    { name: "Mon planning", value: "personal", disable: true },
                ]} selected={calendars} cssClass={"drop-type"} />

            </div>
            <h3 className={"text-center text-capitalize text-month date"}>{filters.date.format('MMMM YYYY')}</h3>
            <Overlay item={itemModification}
                close={() => {
                    setItemModification(false)
                    forceUpdate()
                }}
                edit={(value) => {
                    setItemModification(value)
                    forceUpdate()
                }} />
            {
                loading ?
                    (<>
                        <div className={"d-flex flex-column justify-content-center align-items-center loading-planning"}>
                            <Spinner strokeWidth={9} width={40} color={"#003952"} />
                            {/* <h3 className={"text-center dark text-capitalize"}>Chargement...</h3> */}
                        </div>
                    </>)
                    :
                    displayCalendars(
                        calendars,
                        { height: h, filters },

                        (value) => {
                            setSI(value)
                        },
                        list,
                        (value) => {
                            setItemModification(value)
                        }, previous, next, filters.date
                    )
            }
            {
                displayPopover(
                    sI,
                    () => {
                        setSI({})
                    }, (value) => {
                        setItemModification(value)
                    },
                    userContext
                )
            }
        </div >
    )
}

function displayCalendars(value, options, select, data, add, previous, next, filters) {
    switch (value) {
        case "individual":
            return (
                <IndividualCustom previous={previous} next={next} data={data} options={options} select={(value) => select(value)} add={(value) => {
                    add(value)
                }} date={filters} />
            )
        case "agency":
            return (
                <TimelineCustom data={data} options={options}
                    select={(value) => select(value)}
                    add={(value) => {
                        add(value)
                    }}
                    previous={previous}
                    next={next}
                />
            )
        case "personal":
            return (
                <IndividualCustom previous={previous} next={next}
                    data={data} options={options} select={(value) => select(value)} add={(value) => {
                        add(value)
                    }} />
            )
        default:
            break
    }
}

