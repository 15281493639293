import "../assets/scss/spinner.scss";

export default function Spinner({ strokeWidth, width, color }) {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <svg className="spinner" style={{ width, height: width, padding: strokeWidth / 2, color }}>
                <circle
                    fill="none"
                    stroke="currentColor"
                    cx="50%"
                    cy="50%"
                    r="50%"
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    transformorigin="center"
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        values="-90;810"
                        keyTimes="0;1"
                        dur="2s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="stroke-dashoffset"
                        values="0%;0%;-157.080%"
                        calcMode="spline"
                        keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0"
                        keyTimes="0;0.5;1"
                        dur="2s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="stroke-dasharray"
                        values="0% 314.159%;157.080% 157.080%;0% 314.159%"
                        calcMode="spline"
                        keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0"
                        keyTimes="0;0.5;1"
                        dur="2s"
                        repeatCount="indefinite"
                    />
                </circle>
            </svg>
        </div>
    );
}
