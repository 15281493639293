import React from 'react';
import {BsThreeDotsVertical} from "react-icons/bs"

export default function Popover(props) {

    const position = React.useCallback((x, y) => {
        const obj = {}
        let offsetX = 276
        let offsetY = 260

        if (props.select) {
            if (props.select.type === "booking" && ["airbnb", "homeaway", "dgf", "booking"].includes(props.select.platform)) {
                console.log(" b long")
                offsetY = 560
                offsetX = 550
            }
        }

        if (x + offsetX > document.body.offsetWidth) {
            obj.left = x - offsetX
        } else {
            obj.left = x
        }
        if (y + offsetY > (document.body.offsetHeight)) {
            obj.top = y - offsetY + window.scrollY
        } else {
            obj.top = y + window.scrollY
        }
        return obj
    }, [props])

    const ref = React.useRef(null)
        , [loading, setLoading] = React.useState(true)
        , [style, setStyle] = React.useState(position(props.select.position.x, props.select.position.y))

    React.useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            if (ref.current && !ref.current.contains(e.target) && !loading) {
                props.close()
            }
        })
        setStyle(position(props.select.position.x, props.select.position.y))
        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [props, position, loading, setLoading, setStyle])

    if (!props.title) return null

    return (
        <div ref={ref} className={'popover'} style={style}>
            <div className={"row m-0 justify-content-between align-items-center"}>
                <div className={"col-6"}>
                    <h5 className={"overflow-hidden m-0"}>{props.title}</h5>
                </div>
                <div className={"col-6 d-flex flex-row-reverse"}>
                    {!!props.modify ?
                        <button type="button" className="btn btn-light" onClick={() => {
                            props.modify()
                            props.close()
                        }}>
                            <BsThreeDotsVertical/>
                        </button>
                        :
                        null
                    }

                </div>
            </div>
            <hr/>
            {props.children}
        </div>
    )
}