//External components
import React from "react";
import {Redirect, Route} from "react-router-dom";

// CONTEXT
import UserContext from '../dataContext'

//Internal components
import Menu from "../Layout/MenuLayout";

export default function PrivateRoute({component: Component, ...rest}) {
    const userContext = React.useContext(UserContext)
    if (!userContext.isLogged()) {
        return (
            <Redirect to={'/login'}/>
        )
    }
    return (
        <Route {...rest} render={() => {
            return (
                <Menu>
                    <Component/>
                </Menu>
            )
        }}/>
    )

}