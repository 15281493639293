import React from "react";
import Gallery from "../../Gallery/Gallery";


export default function HomeViewCard(props) {
    return (
        <div className={"home-view d-flex flex-wrap align-items-center"}>
            <h2 hidden={!props.title}>{props.title}</h2>
            <div className={"home-view-container1 row"}>
                <div hidden={!props.images || props.images?.length === 0}  className={"home-view-image-container col"} >
                    <Gallery images={props.images} />
                </div>
                <p hidden={!props.text} className={"flex-grow-1 col"}>{props.text}</p>
            </div>
            <hr/>
        </div>

    )
}



