import React, { useState } from 'react';
import '../../assets/scss/accordion.scss';

const Accordion = ({ title, children, titleSize, onClickFunc }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onClickBehavior = () => {
        if (onClickFunc) onClickFunc();
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion">
            <div className={`accordion-header ${isOpen ? 'open' : ''}`} onClick={() => onClickBehavior()}>
                <h2 className={"fs-" + (titleSize ? titleSize : "4")}>{title}</h2>
                <span>{isOpen ? '-' : '+'}</span>
            </div>
            <div className={`accordion-body ${isOpen ? 'open' : ''}`}>
                {children}
            </div>
        </div>
    );
};

export default Accordion;