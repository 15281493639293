import React, { useState } from "react";

// Icons
import { BsTrashFill, BsCheck } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import SendButton from "../../../../Button/SendButton";
import { defaultListNew } from "./defaultListNew"

let defaultListNewPrayge = [...defaultListNew]


export default function AddHomeView(props) {
    const listTemp = defaultListNewPrayge
    const [list, setList] = React.useState(listTemp)
    const [loading, setLoading] = React.useState(false)
    const [, r] = React.useState({})

    const reload = function () {
        r({})
    }

    const handleResize = React.useCallback((e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }, [])

    const deleteSection = function (index) {
        const listTemp = list
        listTemp.splice(index, 1)
        setList(listTemp)
        reload()
    }

    const changeValue = function (e, indexCategory, indexSection, indexSingle) {
        const listTemp = list
        let input
        if (typeof indexSection === "object") {
            console.log(indexSection)
        }
        if (indexSection === "image") {
            console.log("imagee")
        }
        if (typeof indexSingle === "undefined") {
            input = listTemp[indexCategory].content[indexSection]
        }
        else {
            input = listTemp[indexCategory].content[indexSection].fields[indexSingle]
        }
        const value = e.target.value

        e.target.name === "check" ? (input.value ? input.value = false : input.value = true) : input.value = value

        setList(listTemp)
        reload()
    }

    const changeImages = function (indexCategory, images) {
        const listTemp = list
        listTemp[indexCategory].images = images

        setList(listTemp)
        reload()
    }

    React.useEffect(() => {
        const tas = document.getElementsByTagName("textarea")
        for (let i = 0; i < tas.length; i++) {
            tas[i].style.height = `${tas[i].scrollHeight}px`
        }
    }, [handleResize])


    return (
        <>
            <form className={"home-view-add-container"} onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (loading) return null
                setLoading(true)
                try {
                    props.send(list)
                    setLoading(false)
                    reload()
                } catch (err) {
                    setLoading(false)
                }
            }}>
                <div className={"home-view-header"}>
                    <h3>Publier la fiche home </h3>
                    <SendButton loading={loading} type={"submit"}
                        className={"btn"}><BsCheck /></SendButton>
                </div>
                {list.map((item, indexCategory) => {
                    return (
                        <div className={"section"} key={indexCategory}>

                            <div className="input-title">
                                <div className="area">
                                    <input name={"title"} type={"text"} disabled
                                        onChange={(e) => {
                                            changeValue(e, indexCategory)
                                        }} defaultValue={item.title} />
                                </div>
                                <button className={"btn-delete"} hidden={!item.deletable} onClick={() => {
                                    deleteSection(indexCategory)
                                }}><BsTrashFill />
                                </button>
                            </div>
                            {item.content.map((item, indexSection) => {
                                return (
                                    <div className="category">
                                        <hr></hr>
                                        <p className="name text-center fw-bold">{item.title}</p>
                                        {item.type === "text" ? (
                                            item.fields.map((field, indexSingle) => {
                                                // if (field.dependsOn !== "" && typeof field.dependsOn !== 'undefined') {
                                                //     console.log(field.dependsOn, "dependsOn")
                                                //     console.log(field, "field")
                                                // }
                                                return (
                                                    <div className={"input type-" + field.type}>
                                                        <div className="icon">{field.title}</div>
                                                        <div className="area" style={{ width: "auto" }}>
                                                            {field.type === "input" ? (
                                                                <input
                                                                    name={"text"}
                                                                    onChange={(e) => {
                                                                        handleResize(e);
                                                                        changeValue(e, indexCategory, indexSection, indexSingle);
                                                                    }}
                                                                />
                                                            ) : field.type === "textarea" ? (
                                                                <textarea
                                                                    name={"textarea"}
                                                                    onChange={(e) => {
                                                                        handleResize(e);
                                                                        changeValue(e, indexCategory, indexSection, indexSingle);
                                                                    }}
                                                                />
                                                            ) : field.type === "check" ? (
                                                                <input
                                                                    type="checkbox"
                                                                    name={"check"}
                                                                    onChange={(e) => {
                                                                        handleResize(e);
                                                                        changeValue(e, indexCategory, indexSection, indexSingle);
                                                                    }}
                                                                />
                                                            ) : (
                                                                <select
                                                                    className="form-select"
                                                                    name={"dropdown"}
                                                                    onChange={(e) => {
                                                                        handleResize(e);
                                                                        changeValue(e, indexCategory, indexSection, indexSingle);
                                                                    }}
                                                                >
                                                                    {field.options.map((option) => (
                                                                        <option value={option}>{option}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </div>
                                                    </div>
                                                )


                                            })
                                        ) : (
                                            <div className="section-check">
                                                <input
                                                    type="checkbox"
                                                    name={"check"}
                                                    onChange={(e) => {
                                                        handleResize(e);
                                                        changeValue(e, indexCategory, indexSection);
                                                    }}
                                                    checked={item.value ? true : false}
                                                />

                                                {
                                                    item.fields.map((field, indexSingle) => {
                                                        let cssClass
                                                        item.value ? cssClass = "" : cssClass = " d-none"
                                                        return (

                                                            <div className={"input type-" + field.type + cssClass}>
                                                                <div className="icon">{field.title}</div>
                                                                <div className="area" style={{ width: "auto" }}>
                                                                    {field.type === "input" ? (
                                                                        <input
                                                                            name={"text"}
                                                                            onChange={(e) => {
                                                                                handleResize(e);
                                                                                changeValue(e, indexCategory, indexSection, indexSingle);
                                                                            }}
                                                                        />
                                                                    ) : field.type === "textarea" ? (
                                                                        <textarea
                                                                            name={"textarea"}
                                                                            onChange={(e) => {
                                                                                handleResize(e);
                                                                                changeValue(e, indexCategory, indexSection, indexSingle);
                                                                            }}
                                                                        />
                                                                    ) : field.type === "check" ? (
                                                                        <input
                                                                            type="checkbox"
                                                                            name={"check"}
                                                                            onChange={(e) => {
                                                                                handleResize(e);
                                                                                changeValue(e, indexCategory, indexSection, indexSingle);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <select
                                                                            className="form-select"
                                                                            name={"dropdown"}
                                                                            onChange={(e) => {
                                                                                handleResize(e);
                                                                                changeValue(e, indexCategory, indexSection, indexSingle);
                                                                            }}
                                                                        >
                                                                            {field.options.map((option) => (
                                                                                <option value={option}>{option}</option>
                                                                            ))}
                                                                        </select>
                                                                    )}
                                                                </div>
                                                            </div>

                                                        )

                                                    })
                                                }

                                            </div>

                                        )}
                                    </div>
                                );
                            })}

                            <div className="input">
                                <div className="icon">Photos</div>
                                <div className="area">
                                    <ImageUpload indexCategory={indexCategory} changeImages={changeImages} />
                                    {/* <input name={"images"} onChange={(e) => {
                                        changeValue(e, indexCategory, e.target.files)
                                    }} type={"file"}
                                        accept="image/png, image/jpeg" multiple /> */}
                                </div>
                            </div>

                        </div>
                    )
                })}
                {/* <div className={"section"}>
                    <button className={"btn-add"} type={"button"} onClick={() => {
                        addSection()
                    }}>Ajouter une nouvelle section
                    </button>
                </div> */}
            </form>
        </>
    )
}
function ImageUpload({ changeImages, indexCategory }) {
    let [imageURLs, setImageURLs] = useState([]);
    let [imageFiles, setImageFiles] = useState([]); // store the file data
    let handleFileChange = (event) => {
        let selectedFiles = Array.from(event.target.files),
            selectedURLs = selectedFiles.map((file) => URL.createObjectURL(file));
        setImageURLs((prevURLs) => [...prevURLs, ...selectedURLs]);
        setImageFiles((prevFiles) => [...prevFiles, ...selectedFiles]); // update the file data
        changeImages(indexCategory, imageFiles);
    };
    let removeImage = (index) => {
        let updatedURLs = [...imageURLs];
        let updatedFiles = [...imageFiles]; // copy the file data
        updatedURLs.splice(index, 1);
        updatedFiles.splice(index, 1); // remove the file data
        setImageURLs(updatedURLs);
        setImageFiles(updatedFiles); // set the new file data
        changeImages(indexCategory, updatedFiles); // use the file data instead of URLs
    };
    return (
        <div>
            <div className="form-group multi-preview">
                <div className="row text-center text-lg-start">
                    {imageURLs.map((url, index) => (
                        <div className="col-lg-3 col-md-4 col-6">
                            <div className="image-container">
                                <img src={url} alt="..." className="img-fluid img-thumbnail" />
                                <FaTimes className="close-icon" onClick={() => removeImage(index)} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="form-group">
                <input
                    type="file"
                    name={"images"}
                    className="form-control"
                    onChange={handleFileChange}
                    accept="image/png, image/jpeg"
                    multiple
                />
            </div>
        </div>
    );
}