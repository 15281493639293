import React from 'react';

export default function DropDownButton(props) {
    const ref = React.useRef()

    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false)
            }
        });
    }, [])

    return (
        <div className={"dropdown " + props.cssClass ? props.cssClass : ""}>
            <button className={open ? "btn btn-light dropdown-toggle show w-100" : "btn btn-light dropdown-toggle w-100"}
                id={"dropdownMenuLink"} data-bs-toggle={"dropdown"}
                aria-expanded={open ? "true" : "false"} onClick={() => {
                    setOpen(!open)
                }}>
                {props.name ? props.name : props.options.filter(i => i.value === props.selected)[0].name}
            </button>

            <ul className={open ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuLink" ref={ref}>
                {props.options.map((option, index) => {
                    if (option.disable) {
                        return (
                            <li key={option.value + index}>
                                <button className={"dropdown-item disabled"}>
                                    {option.name}
                                </button>
                            </li>
                        )
                    }
                    return (
                        <li key={option.value + index}>
                            <button className={option.value === props.selected ? "dropdown-item active" : "dropdown-item"} onClick={() => {
                                props.setValue(option.value)
                                setOpen(false)
                            }}>
                                {option.name}
                            </button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}