import React from 'react';
import { useHistory } from "react-router-dom";

export default function Error404(props) {
    const history = useHistory()

    return (
        <div className={'row align-items-center img404'}>
            <div className={"col-sm-12 col-lg-4 text-center"}>
                <h1>Nous ne trouvons pas la ressource recherchée</h1>
                <button className={'btn btn-outline-warning'} onClick={() => {
                    history.push('/')
                }}>Revenir à l'accueil</button>
            </div>
        </div>
    )
}