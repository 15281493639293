// External components
import React from "react";
import * as Icons from 'react-icons/bs'
import "../../../assets/scss/sidebar.scss"

export default function MenuListLink(props) {
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        if (props.links.some(e => e.reference === window.location.pathname)) setOpen(true)
    }, [setOpen, props.links])

    const Icon = Icons[props.icon];

    return (
        <li className="pageSubmenuParent" key={props.name}>

            <button
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle sidebar-dropdown w-100"
                onClick={() => {
                    setOpen(!open)
                }}
            >
                {/* <i className="fas fa-copy"/> */}
                <Icon />
                <span>{props.name}</span>
            </button>

            <ul className={open ? "list-unstyled" : "collapse list-unstyled"} id="pageSubmenu">
                {
                    props.links.map((link) => {
                        return (
                            <li className={window.location.pathname === props.reference ? "active" : ""} key={link.name}>
                                <a href={link.reference} className='header-link'>{link.name}</a>
                            </li>
                        )
                    })
                }
            </ul>
        </li>
    )
}