export default function LoginBox(props) {
    return (
        <div className="container full-height align-items-center">
            <div className={'row full-height align-items-center justify-content-md-center'}>
                <div className={'col col-lg-5 col-sm-12 align-items-center align-content-lg-center loginBox'}>
                    <div className={'content'}>
                       {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}