import { keyPerformanceIndicators } from "../Utils/functions";
import Accordion from "./Cards/Accordion";

export default function PlanningKPI({ date, userContext }) {
    if (typeof userContext === 'undefined')
        return <h4 className="text-center black">Chargement des données...</h4>;
    else {
        const filteredArray = userContext.data.people?.Client?.filter(
            function (obj) {
                return obj.archive !== true && obj.email !== 'informatique@mypacome.fr';
            }
        );
        filteredArray?.forEach(client => {
            client.eventsList = []

            client.properties.forEach(property => {

                // List all bookings
                property.bookings.forEach(booking => {
                    if (!booking.cancelled) {
                        if (!booking.cancelled) {
                            const start = new Date(booking.dates.start)
                            const end = new Date(booking.dates.end)
                            client.eventsList.push({
                                id: booking.id,
                                calendarId: '0',
                                category: 'time',
                                dueDateClass: '',
                                title: booking.platform + " " + property.name,
                                raw: {
                                    item: booking,
                                    type: "booking"
                                },
                                start: start.toISOString(),
                                end: end.toISOString()
                            })
                        }
                    }
                });

                // List all interventions
                property.interventions.forEach(intervention => {
                    const start = new Date(intervention.date.dateStart)
                    const end = new Date(intervention.date.dateStart)
                    end.setHours(end.getHours() + parseInt(intervention.date.duration.split(":")[0]))
                    end.setMinutes(end.getMinutes() + parseInt(intervention.date.duration.split(":")[1]))
                    const isCancelledTitle = intervention.cancelled ? "[🚨 Annulé 🚨] " : ""

                    client.eventsList.push({
                        id: intervention.id,
                        calendarId: '1',
                        title: isCancelledTitle + intervention.type + ' ' + property.name,
                        category: 'time',
                        dueDateClass: '',
                        raw: {
                            item: intervention,
                            type: "intervention"
                        },
                        start: start.toISOString(),
                        end: end.toISOString()
                    })
                })

            });

        });

        let globalValidateSchedules = 0,
            globalPlannedSchedules = 0,
            globalTotalSchedules = 0,
            globalRegularSchedules = 0,
            globalCancelledSchedules = 0;

        filteredArray?.forEach(client => {
            let [
                validateSchedules,
                plannedSchedules,
                totalSchedules,
                regularSchedules,
                cancelledSchedules,
            ] = keyPerformanceIndicators(client, date, true);
            globalValidateSchedules += validateSchedules;
            globalPlannedSchedules += plannedSchedules;
            globalTotalSchedules += totalSchedules;
            globalRegularSchedules += regularSchedules;
            globalCancelledSchedules += cancelledSchedules;
        });

        globalValidateSchedules = Math.floor(globalValidateSchedules / 60) + "h" + globalValidateSchedules % 60
        globalPlannedSchedules = Math.floor(globalPlannedSchedules / 60) + "h" + globalPlannedSchedules % 60
        globalTotalSchedules = Math.floor(globalTotalSchedules / 60) + "h" + globalTotalSchedules % 60
        globalRegularSchedules = Math.floor(globalRegularSchedules / 60) + "h" + globalRegularSchedules % 60
        globalCancelledSchedules = Math.floor(globalCancelledSchedules / 60) + "h" + globalCancelledSchedules % 60
        // return (
        //     <div className="col-6 py-6 mx-auto">
        //         <div className="accordion accordion-flush" id="accordionExample">
        //             <div className="accordion-item">
        //                 <h2 className="accordion-header" id="headingOne">
        //                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
        //                         KPI Globaux du mois
        //                     </button>
        //                 </h2>
        //                 <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        //                     <div className="accordion-body">
        //                         <ul className="list-group row flex-row">
        //                             <li className="list-group-item col-6">Heures validées : <strong>{globalValidateSchedules}</strong></li>
        //                             <li className="list-group-item col-6">Heures plannifiées: <strong>{globalPlannedSchedules}</strong> </li>
        //                             <li className="list-group-item col-6" title="attention, pour le moment fluctue car on enlève des heures aux contrats parfois pour les mettre en 'P' faute de pouvoir créer une inter dans l'abonnement sans récurrence ">Heures régulières : <strong>{globalRegularSchedules}</strong></li>
        //                             <li className="list-group-item col-6">Heures annulées: <strong>{globalCancelledSchedules}</strong></li>
        //                             <li className="list-group-item col-6 flex-fill">Heures totales: <strong>{globalTotalSchedules}</strong></li>
        //                         </ul>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // );
        return (
            <div className="mx-5 px-5">
                <Accordion title="KPI Globaux du mois" titleSize="5">
                    <ul className="list-group row flex-row">
                        <li className="list-group-item col-6">Heures validées : <strong>{globalValidateSchedules}</strong></li>
                        <li className="list-group-item col-6">Heures plannifiées: <strong>{globalPlannedSchedules}</strong> </li>
                        <li className="list-group-item col-6" title="attention, pour le moment fluctue car on enlève des heures aux contrats parfois pour les mettre en 'P' faute de pouvoir créer une inter dans l'abonnement sans récurrence ">Heures régulières : <strong>{globalRegularSchedules}</strong></li>
                        <li className="list-group-item col-6">Heures annulées: <strong>{globalCancelledSchedules}</strong></li>
                        <li className="list-group-item col-6 flex-fill">Heures totales: <strong>{globalTotalSchedules}</strong></li>
                    </ul>
                </Accordion>

            </div>
        )
    }
}
