import React from 'react'

//Context
import UserContext from "../dataContext";

// Internal components
import Overlay from "../Components/Subcomponents/Overlay/Overlay";

import "../assets/scss/providers.scss";

// Icons
import { BsPlus } from "react-icons/bs";
import Spinner from '../Components/Spinner';

export default function Providers(props) {
    const userContext = React.useContext(UserContext)

    const [item, setItem] = React.useState({})

    if (userContext.loading) {
        return (
            <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div className={"col-auto\n"}>
                        <h1>Prestataires</h1>
                    </div>
                    <div className={"col-auto\n"}>
                        <button type={"button"} className={'btn btn-outline-dark '} onClick={() => {
                            let object = {}
                            object.choices = "Providers-new"
                            setItem(object)
                        }}>
                            <h3><BsPlus /></h3>
                        </button>
                    </div>
                </div>
                <div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Téléphone</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    <Spinner strokeWidth={9} width={40} color={"#003952"} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    return (
        <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
            <div className={"row justify-content-between"}>
                <div className={"col-auto\n"}>
                    <h1>Prestataires</h1>
                </div>
                <div className={"col-auto\n"}>
                    <button type={"button"} className={'btn btn-outline-dark'} onClick={() => {
                        let object = {}
                        object.choices = "Providers-new"
                        setItem(object)
                    }}>
                        <h3><BsPlus /></h3>
                    </button>
                </div>
            </div>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>Nom société</th>
                        <th>Téléphone</th>
                        <th>Spécialité</th>
                    </tr>
                </thead>
                <tbody>
                    {userContext.data.people.Prestataires
                        .sort((a, b) => a.company.localeCompare(b.company))
                        .map((presta) => {
                            if (presta.archive) return null
                            const specialities = presta.activity
                            return (
                                <tr key={presta.id} onClick={() => {
                                    let object = presta
                                    object.choices = "ProvidersModify"
                                    setItem(object)
                                }}>
                                    <td>{presta.company}</td>
                                    <td>{presta.phone}</td>
                                    <td>{specialities.join(', ')}</td>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
            <Overlay item={item}
                close={() => {
                    setItem(false)
                }}
                edit={(value) => {
                    setItem(value)
                }} />
        </div>
    )
}