import React from 'react'
import UserContext from "../dataContext";

// Internal components
import Overlay from "../Components/Subcomponents/Overlay/Overlay";


// Icons
import { BsPlus } from "react-icons/bs";
import Spinner from '../Components/Spinner';

export default function Clients(props) {
    const userContext = React.useContext(UserContext)

    const [item, setItem] = React.useState({})
        , [, r] = React.useState({})

    const forceUpdate = React.useCallback(() => {
        r({})
    }, [r]);

    if (userContext.loading) {
        return (
            <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div className={"col-auto\n"}>
                        <h1>Clients</h1>
                    </div>
                    <div className={"col-auto\n"}>
                        <button type={"button"} className={'btn btn-outline-dark '} onClick={() => {
                            setItem({ choices: "Clients" })
                        }}>
                            <h3><BsPlus /></h3>
                        </button>
                    </div>
                </div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Téléphone</th>
                            <th>Email</th>
                            <th>Professionel</th>
                            <th>Client abonné</th>
                        </tr>
                    </thead>
                    <tbody className='my-5'>
                        <tr>
                            <td colSpan={6}>
                                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
            <div className={"row justify-content-between"}>
                <div className={"col-auto\n"}>
                    <h1>Clients</h1>
                </div>
                <div className={"col-auto\n"}>
                    <button type={"button"} className={'btn btn-outline-dark'} onClick={() => {
                        let object = {}
                        object.choices = "Client"
                        setItem(object)
                    }}>
                        <h3><BsPlus /></h3>
                    </button>
                </div>
            </div>

            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Téléphone</th>
                        <th>Email</th>
                        <th>Professionel</th>
                        <th>Client abonné</th>
                    </tr>
                </thead>
                <tbody>
                    {userContext.data.people.Client.sort((a, b) => a.lastName > b.lastName ? 1 : -1).map((client) => {
                        if (client.archive) return null
                        return (
                            <tr onClick={() => {
                                const object = client
                                object.choices = "ClientChoices"
                                setItem(object)
                            }}>
                                <td>{client.lastName}</td>
                                <td>{client.name}</td>
                                <td>{client.phone}</td>
                                <td>{client.email}</td>
                                <td>{client.professional ? "Oui " + client.companyID : "Non"}</td>
                                <td>{client.subscription ? (client.subscription.aborted.isAborted ? "Contrat terminé" : "Oui ") : "Non"}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Overlay item={item}
                close={() => {
                    setItem(false)
                }}
                edit={(value) => {
                    setItem(value)
                    forceUpdate()
                }} />
        </div>
    )
}