import { useEffect, useState } from 'react'
import '../../../assets/scss/modifyHomeView.scss'
import HomeView from './Display/HomeView'
import ModifyHomeView from "./Modify/ModifyHomeView"
import Spinner from '../../Spinner'


export default function GestionFichesHome({ context }) {
    const testUser = context.data.people.Client.find(({ lastName }) => lastName === "Test")
    const testUserHome = JSON.parse(testUser.properties[0].homeViewNew)
    const [testUserHomeView, setTestUserHomeView] = useState(JSON.parse(testUserHome))
    const [submitLoading, setSubmitLoading] = useState(false)
    const [listOfModifications, setListOfModifications] = useState([])
    const burl = context.getBurl()
    const [homeViewVersion, setHomeViewVersion] = useState(0);

    const [isOpen, setIsOpen] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)

    const handleSaveChanges = () => {
        setIsOpen(!isOpen);
    };

    function handleConfirmChanges() {
        setSubmitLoading(true)
        // console.log(listOfModifications, 'listOfModifications')
        context.apiReducer('modifyHomeViewStructure', { changes: listOfModifications }, {})
            .then((response) => {
                // console.log(response, 'response')
                setSubmitLoading(false)
                setSubmitSuccess(true)
            })
            .catch((err) => {
                console.log(err)
                setSubmitLoading(false)
                setIsOpen(false)
            })
    }

    useEffect(() => {
    }, [listOfModifications])

    return (
        <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
            <div className={"row justify-content-between"}>
                <div className={""}>
                    <p><b>La Fiche Home du compte test est utilisée pour visualiser les modifications, en dessous de la section de modification ci-dessous.</b></p>
                    <p><i><b>Dû à une erreur j'ai dû copier les informations de la propriété de Mme. GERMOND pour la Fiche Home du client test, c'est donc normal si les informations proviennent de cette Fiche Home</b></i></p>
                    <p><i>Également, toutes les catégories nécéssitant d'être cochées pour être visibles sont cochées par défaut sur la preview afin de voir les champs s'y trouvant</i></p>
                    <ModifyHomeView testUserHomeView={testUserHomeView} setTestUserHomeView={setTestUserHomeView} setListOfModifications={setListOfModifications} listOfModifications={listOfModifications} setHomeViewVersion={setHomeViewVersion} />
                    <HomeView homeView={testUserHomeView} burl={burl} homeViewVersion={homeViewVersion} />
                    <button type="button" className="btn btn-primary" onClick={handleSaveChanges} hidden={isOpen}>
                        Confirmer les modifications ?
                    </button>
                    {isOpen && (
                        <div className="accordion my-4">
                            <div style={{ pointerEvents: 'none' }} className={`accordion-header ${isOpen ? 'open' : ''}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Confirmer les modifications :</h5>
                                <span>{isOpen ? '-' : '+'}</span>
                            </div>
                            <div className={`accordion-body ${isOpen ? 'open' : ''}`}>
                                <ShowActions listOfModifications={listOfModifications} testUserHomeView={testUserHomeView} testUserHome={testUserHome} />
                            </div>
                            <p className='d-flex mx-3 px-1'><i>Attention, cette opération est lourde et peut prendre du temps, essayez de rassembler le plus de modifications dans une seule requête</i></p>
                            <div className="accordion-buttons">
                                <button type="button" className="btn btn-secondary mx-3" onClick={() => setIsOpen(false)}>Annuler</button>
                                <button type="button" className="btn btn-primary" onClick={handleConfirmChanges}>Sauvegarder les modifications</button>
                            </div>
                            {submitLoading && (
                                <div>
                                    <Spinner strokeWidth={9} width={40} color={"#003952"} />
                                    <h2 className="text-center">Changement des Fiches Home...</h2>
                                    <h4 className="text-center"><i>Cette opération peut prendre du temps, ne fermez pas cette page pour être notifié quand le changement est terminé.</i></h4>
                                </div>
                            )}
                            {submitSuccess && (
                                <div>
                                    <h2 className="text-center">Changements enregistrés !</h2>
                                    {/* Reset and refresh the page when closing: */}
                                    <button type="button" className="btn btn-primary" onClick={() => { setSubmitSuccess(false); setIsOpen(false); window.location.reload(); }}>Fermer et recharger la page</button>
                                </div>
                            )}
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}

function ShowActions({ listOfModifications, testUserHomeView, testUserHome }) {
    const modificationsBySection = new Map();
    listOfModifications.forEach(modification => {
        let sectionModifications = modificationsBySection.get(modification.sectionIndex);
        if (!sectionModifications) {
            sectionModifications = { categories: new Map(), modifications: [] };
            modificationsBySection.set(modification.sectionIndex, sectionModifications);
        }

        if (modification.categoryIndex !== undefined) {
            let categoryModifications = sectionModifications.categories.get(modification.categoryIndex);
            if (!categoryModifications) {
                categoryModifications = { fields: new Map(), modifications: [] };
                sectionModifications.categories.set(modification.categoryIndex, categoryModifications);
            }

            if (modification.fieldIndex !== undefined) {
                let fieldModifications = categoryModifications.fields.get(modification.fieldIndex);
                if (!fieldModifications) {
                    fieldModifications = [];
                    categoryModifications.fields.set(modification.fieldIndex, fieldModifications);
                }

                fieldModifications.push(modification);
            } else {
                categoryModifications.modifications.push(modification);
            }
        } else {
            sectionModifications.modifications.push(modification);
        }
    });

    return (
        <ul>
            {Array.from(modificationsBySection.entries()).map(([sectionIndex, sectionModifications]) => {
                return (
                    <li key={sectionIndex}>
                        Dans la section "{testUserHomeView[sectionIndex]?.title}":
                        <ul className='border-left m-3'>
                            {sectionModifications.modifications.map((modification, index) => (
                                <li key={index}>{translateAction(modification, testUserHome)}</li>
                            ))}
                            {Array.from(sectionModifications.categories.entries()).map(([categoryIndex, categoryModifications]) => (
                                <li key={categoryIndex}>
                                    Dans la catégorie "{testUserHomeView[sectionIndex]?.content[categoryIndex]?.title}":
                                    <ul className='border-left m-3'>
                                        {categoryModifications.modifications.map((modification, index) => (
                                            <li key={index}>{translateAction(modification, testUserHome)}</li>
                                        ))}
                                        {Array.from(categoryModifications.fields.entries()).map(([fieldIndex, fieldModifications]) => (
                                            <li key={fieldIndex}>
                                                Dans le champ "{testUserHomeView[sectionIndex]?.content[categoryIndex]?.fields[fieldIndex]?.title}":
                                                <ul className='border-left m-3'>
                                                    {fieldModifications.map((modification, index) => (
                                                        <li key={index}>{translateAction(modification, testUserHome)}</li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </li>
                );
            })}
        </ul>
    );
}


function translateAction(modification, testUserHome) {
    switch (modification.action) {
        case 'addSection':
            return `Ajout d'une section avec le titre "${modification.value.title}"`;
        case 'modifySectionTitle':
            return `Modification du titre de la section ${modification.sectionIndex + 1} en "${modification.value}"`;
        case 'addCategory':
            return `Ajout d'une catégorie à la section ${modification.sectionIndex + 1} avec le titre "${modification.value.title}"`;
        case 'modifyCategoryTitle':
            return `Modification du titre de la catégorie ${modification.categoryIndex + 1} de la section ${modification.sectionIndex + 1} en "${modification.value}"`;
        case 'modifyCategoryType':
            return `Modification du type de la catégorie ${modification.categoryIndex + 1} de la section ${modification.sectionIndex + 1} en "${modification.value}"`;
        case 'addField':
            return `Ajout d'un champ à la catégorie ${modification.categoryIndex + 1} de la section ${modification.sectionIndex + 1} avec le titre "${modification.value.title}"`;
        case 'modifyFieldTitle':
            return `Modification du titre du champ ${modification.fieldIndex + 1} de la catégorie ${modification.categoryIndex + 1} de la section ${modification.sectionIndex + 1} en "${modification.value}"`;
        case 'modifyFieldType':
            return `Modification du type du champ ${modification.fieldIndex + 1} de la catégorie ${modification.categoryIndex + 1} de la section ${modification.sectionIndex + 1} en "${modification.value}"`;
        case 'addFieldOption':
            return `Ajout de l'option "${modification.value}" au champ ${modification.fieldIndex + 1} de la catégorie ${modification.categoryIndex + 1} de la section ${modification.sectionIndex + 1}`;
        case 'modifyFieldOption':
            return `Modification de l'option "${modification.value}" du champ ${modification.fieldIndex + 1} de la catégorie ${modification.categoryIndex + 1} de la section ${modification.sectionIndex + 1}`;
        case 'deleteFieldOption':
            return `Suppression de l'option "${modification}" du champ ${modification.fieldIndex + 1} de la catégorie ${modification.categoryIndex + 1} de la section ${modification.sectionIndex + 1}`;
        case 'deleteSection':
            return `Suppression de la section "${testUserHome[modification?.sectionIndex]?.title}"`;
        case 'deleteCategory':
            return `Suppression de la catégorie ${modification.categoryIndex + 1} de la section ${modification.sectionIndex + 1}`;
        case 'deleteField':
            return `Suppression du champ ${modification.fieldIndex + 1} de la catégorie ${modification.categoryIndex + 1} de la section ${modification.sectionIndex + 1}`;
        default:
            return '';
    }
}