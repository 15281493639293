// {
//     title: "",                           <- Titre de la catégorie ("Accès")
//     images: [],                          <- Images éventuelles liées à la catégorie
//     content: [                           
//         {
//             title: "",                   <- Titre ("Fermeture portail", "Clés", "Alarme")
//             type: "",                    <- Type ("input" = texte, "check" = checkmark)
//             fields: [
//                 {
//                     title: "",           <- Titre du champ ("Fermeture portail" -> "Non fermé" / "Cadenas")
//                     type: "",            <- Type du champ ("input" = input simple et rapide, "textarea" = long input (notes, commentaires), "check" pour une checkbox)
//                     value: "",           <- Valeur du champ, remplir si une valeur de base, laisser vide dans l'autre cas
//                 },
//             ],
//         }
//     ],
//     deletable: false,
// }
//
// [
// {
//     title: "",
//     images: [],
//     content: [
//         {
//             title: "",
//             type: "",
//             fields: [
//                 {
//                     title: "",
//                     type: "",
//                     value: "",
//                 },
//             ],
//         }
//     ],
//     deletable: false,
// },
// ]
//
// 
// "type":
// text, check pour les individuels ("Clés", "Alarme")
// input, textarea, check
//
// Donner le "title" de l'item pour lequel il dépend

export let defaultListNew = [
    {
        title: "Accès",
        images: [],
        content: [
            {
                title: "Infos supplémentaires sur la maison",
                type: "text",
                fields: [
                    {
                        title: "Propriétaire",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Adresse",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Point GPS",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Remarques",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Fermeture portail",
                type: "text",
                fields: [
                    {
                        title: "Non fermé",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Bip",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Cadenas",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Code",
                        type: "input",
                        value: "",
                    }
                ],
            },
            {
                title: "Clés",
                type: "text",
                fields: [
                    {
                        title: "Nombre de clés",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10
                        ],
                        value: "",
                    },
                    {
                        title: "Informations",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Alarme",
                type: "check",
                value: false,
                fields: [
                    {
                        title: "Code",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Explications alarme",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Boîtes à clés",
                type: "check",
                value: false,
                fields: [
                    {
                        title: "Code",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Place de parking",
                type: "check",
                value: false,
                fields: [
                    {
                        title: "Nombre de places",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10
                        ],
                        value: "",
                    },
                ],
            },
            {
                title: "WIFI",
                type: "check",
                value: false,
                fields: [
                    {
                        title: "Code WIFI",
                        type: "input",
                        value: "",
                    },
                ],
            },
            {
                title: "TV",
                type: "check",
                value: false,
                fields: [
                    {
                        title: "Informations",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Nombre télécommandes",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10
                        ],
                        value: "",
                    },
                ],
            },
            {
                title: "Remarques",
                type: "text",
                fields: [
                    {
                        title: "Informations",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
        ],
        deletable: false,
    },
    {
        title: "A l'intérieur du bien",
        images: [],
        content: [
            {
                title: "Capacité domicile",
                type: "text",
                fields: [
                    {
                        title: "Nombre de personnes max",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15,
                            16,
                            17,
                            18,
                            19,
                            20,
                            21,
                            22,
                            23,
                            24,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30
                        ],
                        value: "",
                    },
                    {
                        title: "Nombre de couchages",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15,
                            16,
                            17,
                            18,
                            19,
                            20,
                            21,
                            22,
                            23,
                            24,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30
                        ],
                        value: "",
                    },
                    {
                        title: "Nombre de lits",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15,
                            16,
                            17,
                            18,
                            19,
                            20,
                            21,
                            22,
                            23,
                            24,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30
                        ],
                        value: "",
                    },
                    {
                        title: "Nombre de lits bébé",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15,
                            16,
                            17,
                            18,
                            19,
                            20,
                            21,
                            22,
                            23,
                            24,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30
                        ],
                        value: "",
                    },
                    {
                        title: "Nombre de salle de bains",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15,
                            16,
                            17,
                            18,
                            19,
                            20,
                            21,
                            22,
                            23,
                            24,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30
                        ],
                        value: "",
                    },
                    {
                        title: "Baignoires",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15,
                            16,
                            17,
                            18,
                            19,
                            20,
                            21,
                            22,
                            23,
                            24,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30
                        ],
                        value: "",
                    },
                    {
                        title: "Douches",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15,
                            16,
                            17,
                            18,
                            19,
                            20,
                            21,
                            22,
                            23,
                            24,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30
                        ],
                        value: "",
                    },
                ],
            },
            {
                title: "Chambres",
                type: "text",
                fields: [
                    {
                        title: "Nombre de chambres",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8
                        ],
                        value: "",
                    },
                    {
                        title: "Chambre 1",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Chambre 2",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Chambre 3",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Chambre 4",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Chambre 5",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Chambre 6",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Chambre 7",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Chambre 8",
                        type: "input",
                        value: "",
                    },
                ],
            },
            {
                title: "Type de chauffage",
                type: "text",
                fields: [
                    {
                        title: "Électrique",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Poêle",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Pompe à chaleur",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Informations supplémentaires",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Traitement des eaux",
                type: "text",
                fields: [
                    {
                        title: "Fosses toutes eaux",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Fosse septique",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Tout à l'égout",
                        type: "check",
                        value: false,
                    },
                ],
            },
            {
                title: "Cheminée",
                type: "text",
                fields: [
                    {
                        title: "Stockage bois",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "A disposition",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Limité",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Interdit",
                        type: "check",
                        value: false,
                    },
                ],
            },
            {
                title: "Informations supplémentaires",
                type: "text",
                fields: [
                    {
                        title: "Compteur électrique",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Ballon d'eau chaude",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Exctincteur",
                        type: "input",
                        value: "",
                    },
                ],
            },
            {
                title: "Ordures",
                type: "text",
                fields: [
                    {
                        title: "Stockage ordures propriétaire",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Jour de ramassage ordures",
                        type: "dropdown",
                        options: [
                            "Lundi",
                            "Mardi",
                            "Mercredi",
                            "Jeudi",
                            "Vendredi",
                            "Samedi",
                            "Dimanche",
                        ],
                        value: "",
                    },
                ],
            },
            {
                title: "Linge",
                type: "text",
                fields: [
                    {
                        title: "Notes",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Produit entretien",
                type: "text",
                fields: [
                    {
                        title: "Emplacement",
                        type: "input",
                        value: "",
                    },
                ],
            },
            {
                title: "Remarques supplémentaires",
                type: "text",
                fields: [
                    {
                        title: "Notes",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
        ],
        deletable: false,
    },
    {
        title: "A l'extérieur du bien",
        images: [],
        content: [
            {
                title: "Jardin",
                type: "text",
                fields: [
                    {
                        title: "Coordonnées",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Fréquence de passage",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Robot",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Code",
                        type: "input",
                        value: "",
                    },
                ],
            },
            {
                title: "Piscine",
                type: "text",
                fields: [
                    {
                        title: "Coordonnées",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Fréquence de passage",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Dimension",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Température",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Serviettes piscine",
                        type: "dropdown",
                        options: [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15,
                            16,
                            17,
                            18,
                            19,
                            20,
                            21,
                            22,
                            23,
                            24,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30
                        ],
                        value: "",
                    },
                    {
                        title: "Ouverture / fertmeture",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Informations supplémentaires",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Autres artisans",
                type: "text",
                fields: [
                    {
                        title: "Electricien",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Chauffagiste",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Plombier",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Autres",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Équipements extérieurs",
                type: "text",
                fields: [
                    {
                        title: "Trampoline",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Transat",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Balançoire",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Table jardin",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Toboggan",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Chaises jardin",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Panier de basket",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Hamac",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Parasol",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Spa",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Table de Ping Pong",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Barbecue",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Plancha",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Brasero",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Bain Nordique",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Informations supplémentaires",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Remarques supplémentaires",
                type: "text",
                fields: [
                    {
                        title: "Notes",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
        ],
        deletable: false,
    },
    {
        title: "Prestations",
        images: [],
        content: [
            {
                title: "Passage hebdomadaire",
                type: "text",
                fields: [
                    {
                        title: "Nombre d'heures / mois",
                        type: "input",
                        value: "",
                    },
                ],
            },
            {
                title: "A vérifier à chaque passage",
                type: "text",
                fields: [
                    {
                        title: "Fuite",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Volet",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Ampoules",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Compteur",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Bâche",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Courrier",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Piles",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Chauffage",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Animaux",
                        type: "check",
                        value: false,
                    },
                ],
            },
            {
                title: "Gestion locative",
                type: "text",
                fields: [
                    {
                        title: "Ménage",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Nombre d'heures",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Check in / Check out",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Nombre d'heures",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Gestion du linge",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Nombre d'heures",
                        type: "input",
                        value: "",
                    },
                ],
            },
            {
                title: "A vérifier",
                type: "text",
                fields: [
                    {
                        title: "Stock accueil",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Papier toilette",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Sac poubelle",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Café / Thé",
                        type: "check",
                        value: false,
                    },
                ],
            },
            {
                title: "Remarques supplémentaires",
                type: "text",
                fields: [
                    {
                        title: "Remarque",
                        type: "textarea",
                        value: "",
                    },
                    {
                        title: "A ne pas faire",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Informations vacanciers",
                type: "text",
                fields: [
                    {
                        title: "Check in",
                        type: "textarea",
                        value: "",
                    },
                    {
                        title: "Check out",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Kit à mettre en place",
                type: "text",
                fields: [
                    {
                        title: "Kit cuisine",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Kit hygiène",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Kit produits locaux",
                        type: "check",
                        value: false,
                    },
                ],
            },
            {
                title: "Nettoyage spécifique",
                type: "text",
                fields: [
                    {
                        title: "Barbecue",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Transat",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Robot jardin",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Table de jardin",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Terrasse",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Jacuzzi",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Grille pain",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Vitres",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Informations supplémentaires",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
        ],
        deletable: false,
    },
    {
        title: "Matériels à disposition",
        images: [],
        content: [
            {
                title: "Cuisine",
                type: "text",
                fields: [
                    {
                        title: "Cafetière",
                        type: "input",
                        value: "",
                    },
                    {
                        title: "Bouilloire",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Micro-onde",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Lave-vaisselle",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Plaque de cuisson gaz",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Plaque de cuisson électrique",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Four",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Robot cuiseur",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Robot patissier",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Poêle",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Grille pain",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Mixeur",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Équipement cuisine enfants",
                        type: 'check',
                        value: '',
                    },
                    {
                        title: "Informations supplémentaires",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Entretien",
                type: "text",
                fields: [
                    {
                        title: "Fer à repasser",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Machine à laver",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Table à repasser",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Sèche-linge",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Aspirateur",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Serpillère",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Tancarville",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Balai à brosse",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Informations supplémentaires",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Procédures particulières",
                type: "text",
                fields: [
                    {
                        title: "Notes",
                        type: "textarea",
                        value: "",
                    },
                ],
            },
            {
                title: "Bébé",
                type: "text",
                fields: [
                    {
                        title: "Bavoir",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Vaisselle enfants",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Lit parapluie",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Berceau",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Chaise haute",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Table à langer",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Baignoire enfants",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Couverture enfants",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Barrière sécurité escalier",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Pot",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Parc",
                        type: "check",
                        value: false,
                    },
                    {
                        title: "Divers jeux",
                        type: "check",
                        value: false,
                    },
                ],
            },
        ],
        deletable: false,
    },
]