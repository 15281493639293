import { slugify } from "../../../../../Utils/functions"
import InventoryFormInput from "./BookingInventoryForm/InventoryFormInput"
import InventoryFormRow from "./BookingInventoryForm/InventoryFormRow"
import InventoryFormSection from "./BookingInventoryForm/InventoryFormSection"
import InventorySignature from "./BookingInventoryForm/InventorySignature"
import userContext from "../../../../../dataContext"
import { useContext } from "react"
import { BsArrowLeftCircle, BsLayoutSidebarReverse, BsLayoutSplit, BsMailbox } from "react-icons/bs"
import { useState } from "react"
import "../../../../../assets/scss/Overlay/BookingInventory.scss"

export default function BookingInventory({ inventory, close, overlayProps }) {
    const inv = JSON.parse(inventory)
    const invIn = inv.entree || ""
    const invOut = inv.sortie || ""
    const normalRooms = ["Entrée", "Salon", "Salle à manger", "Cuisine / Cellier", "Chambre(s)", "Salle(s) de bain", "WC"]
    const Context = useContext(userContext)
    const startDate = new Date(overlayProps.item.dates.start)
    const endDate = new Date(overlayProps.item.dates.end)

    const [gridView, setGridView] = useState(false)
    const [editing,] = useState(false)

    const defaultClass = "col-6 col-12-md bg-light border-2 h-100 black overflow-scroll"
    const gridClass = "col-9 col-12-md bg-light border-2 h-100 black overflow-scroll"

    return (
        <div className={"overlay-modal"}>
            <div onClick={close} className={"row m-0 w-100 h-100 d-flex flex-row-reverse"}>
                <div className={gridView ? gridClass : defaultClass}>
                    <div className={"row sticky-top border-bottom bg-" + (overlayProps.item.platform ? overlayProps.item.platform : "light")}>
                        <div className={"col-4"}>
                            <h2 className={""}>{overlayProps.item.platform}</h2>
                            <h5 className={""}>État des lieux de la réservation du {`${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()} au ${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`}</h5>
                            <p className={"form-text"}>{overlayProps.item.id}</p>
                        </div>
                        <div className={"col-8 d-flex justify-content-end align-items-center"}>
                            {/* {editing ? (
                                <button style={{ border: "none" }} className={"btn btn-light me-3"} onClick={() => {
                                    setEditing(false)
                                }}>
                                    <BsPencil /> Sauvegarder
                                </button>
                            ) : (
                                <button style={{ border: "none" }} className={"btn btn-light me-3"} onClick={() => {
                                    setEditing(true)
                                }}>
                                    <BsPencil /> Éditer
                                </button>
                            )} */}
                            {gridView ? (
                                <button style={{ border: "none" }} className={"btn btn-light me-3"} onClick={() => {
                                    setGridView(false)
                                }}>
                                    <BsLayoutSidebarReverse /> Vue normale
                                </button>
                            ) : (
                                <button style={{ border: "none" }} className={"btn btn-light me-3"} onClick={() => {
                                    setGridView(true)
                                }}>
                                    <BsLayoutSplit /> Vue double
                                </button>
                            )}

                            <button style={{ border: "none" }} className={"btn btn-light me-3"} onClick={() => {
                                const object = overlayProps.item
                                object.choices = ""
                                overlayProps.edit(object)
                            }}>
                                <BsArrowLeftCircle /> Retour au booking
                            </button>
                        </div>
                    </div>
                    <div className={"booking-inventory" + (gridView ? " booking-inventory-grid" : "")}>
                        <div className="booking-inventory-download">
                            <h4>Renvoyer l'état des lieux</h4>
                            <hr />
                            <form className="row mx-2" onSubmit={(e) => {
                                e.preventDefault()
                                const email = e.target.email.value
                                Context.apiReducer("sendInventory", inv, email)
                            }}>
                                <input required type="text" name="email" placeholder="Adresse mail" className="form-control col" />
                                <button className={"btn btn-light col-5 mx-2"} type="submit">
                                    <BsMailbox /> Envoyer l'état des lieux
                                </button>
                            </form>

                        </div>


                        {
                            invIn ? <BookingEntree editing={editing} invIn={invIn} normalRooms={normalRooms} Context={Context} /> : <div className="in"><h2>Pas d'entrée dans cet état des lieux</h2></div>
                        }
                        {
                            invOut ? <BookingSortie editing={editing} invOut={invOut} normalRooms={normalRooms} Context={Context} /> : <div className="out"><h2>Pas de sortie dans cet état des lieux</h2></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function BookingEntree({ invIn, normalRooms, Context, editing }) {
    return (
        <div className="in">
            <h2>Entrée</h2>
            <InventoryFormSection title={"Mandataire"}>
                <InventoryFormRow>
                    <InventoryFormInput readOnly={editing} name={"in-agency"} label={"Agence"} defaultValue={invIn['in-agency']} />
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"in-agencyAdress"} label={"Adresse de l'agence"} defaultValue={invIn['in-agencyAdress']} />
                </InventoryFormRow>
                <InventoryFormRow>
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"in-represented"} label={"Représenté par"} defaultValue={invIn['in-represented']} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Dates clés"}>
                <InventoryFormRow>
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"in-start"} label={"Date d'entrée"} type={'date'} date={invIn['in-start']} />
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"in-end"} label={"Date de sortie"} type={'date'} date={invIn['in-end']} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Locataire"}>
                <InventoryFormRow>
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"in-locataire"} label={"Nom du locataire"} defaultValue={invIn['in-locataire']} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Lieu loué"}>
                <InventoryFormRow>
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"in-property-address"} label={"Adresse du bien"} defaultValue={invIn['in-property-address']} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Clé / Bips"}>
                <p>Veuillez renseigner pour chaque champ le nombre correspondant.</p>
                <InventoryFormRow>
                    <InventoryFormInput defaultValue={invIn['in-key-entree']} disabled={!editing} readOnly={editing} name={"in-key-entree"} size={2} type={"dropdownNumber"} label={"Porte d'entrée"} />
                    <InventoryFormInput defaultValue={invIn['in-key-portail']} disabled={!editing} readOnly={editing} name={"in-key-portail"} size={2} type={"dropdownNumber"} label={"Portail"} />
                    <InventoryFormInput defaultValue={invIn['in-key-bip']} disabled={!editing} readOnly={editing} name={"in-key-bip"} size={2} type={"dropdownNumber"} label={"Bip"} />
                    <InventoryFormInput defaultValue={invIn['in-key-garage']} disabled={!editing} readOnly={editing} name={"in-key-garage"} size={2} type={"dropdownNumber"} label={"Garage"} />
                    <InventoryFormInput defaultValue={invIn['in-key-autres']} disabled={!editing} readOnly={editing} name={"in-key-autres"} size={2} type={"dropdownNumber"} label={"Autre(s)"} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Pièces de la maison"}>
                {
                    // These rooms are all normal so just loop on them for an easier time
                    normalRooms.map((roomName, index) => {
                        return (
                            <InventoryFormRow key={index}>
                                <InventoryFormInput defaultValue={invIn["in-grade-" + slugify(roomName)]} disabled={!editing} readOnly={editing} name={"in-grade-" + slugify(roomName)} title={roomName} label={"État général"} type={"dropdownColour"} />
                                <InventoryFormInput defaultValue={invIn["in-comments-" + slugify(roomName)]} disabled={!editing} readOnly={editing} name={"in-comments-" + slugify(roomName)} size={10} label={"Commentaires"} type={"textarea"} />
                            </InventoryFormRow>
                        )
                    })
                }
                <InventoryFormRow>
                    <InventoryFormInput defaultValue={invIn["in-grade-privatised"]} disabled={!editing} readOnly={editing} name={"in-grade-privatised"} note={"(Balcon, terrasse, parking, garage, jardin)"} title={"Parties privatisées"} label={"État général"} type={"dropdownColour"} />
                    <InventoryFormInput defaultValue={invIn["in-comments-privatised"]} disabled={!editing} readOnly={editing} name={"in-comments-privatised"} size={10} label={"Commentaires"} type={"textarea"} />
                </InventoryFormRow>

                <InventoryFormRow>
                    <InventoryFormInput defaultValue={invIn["in-grade-equipments"]} disabled={!editing} readOnly={editing} name={"in-grade-equipments"} note={"(Sonnette, interphone, boîte aux lettres, portail, cheminée, poêle….)"} title={"Autres équipements"} label={"État général"} type={"dropdownColour"} />
                    <InventoryFormInput defaultValue={invIn["in-comments-equipments"]} disabled={!editing} readOnly={editing} name={"in-comments-equipments"} size={10} label={"Commentaires"} type={"textarea"} />
                </InventoryFormRow>

                <InventoryFormRow>
                    <InventoryFormInput defaultValue={invIn["in-grade-general"]} disabled={!editing} readOnly={editing} name={"in-grade-general"} note={"Avis général sur la propriété et le check-in."} title={"Observations générales et suggestions"} label={"État général"} type={"dropdownColour"} />
                    <InventoryFormInput defaultValue={invIn["in-comments-general"]} disabled={!editing} readOnly={editing} name={"in-comments-general"} size={10} label={"Commentaires"} type={"textarea"} />
                </InventoryFormRow>

            </InventoryFormSection>

            <InventoryFormSection title={"Compteurs"}>
                <InventoryFormRow>
                    <InventoryFormInput defaultValue={invIn["in-count-water"]} disabled={!editing} readOnly={editing} name={"in-count-water"} label={"Eau"} />
                    <InventoryFormInput defaultValue={invIn["in-counter-gas"]} disabled={!editing} readOnly={editing} name={"in-counter-gas"} label={"Gaz"} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Signature"}>
                <p><i>En signant ce document, vous reconnaissez avoir lu et approuvé toutes les informations contenues dans cet état des lieux. <br />
                    Toutes règles non respectées donne l'autorisation au propriétaire de prélever une somme sur la caution.</i></p>

                <h5>Signature du concierge : {invIn["in-represented"]}</h5>
                <InventorySignature signatureDefault={invIn["entree-sign-concierge"]} inOrOut={"entree"} who={"concierge"} signaturer={invIn["in-represented"]} />

                <h5>Signature du locataire : {invIn["in-locataire"]}</h5>
                <InventorySignature signatureConsent={invIn["in-mail-consent"]} signatureDefault={invIn["entree-sign-locataire"]} inOrOut={"entree"} who={"locataire"} signaturer={invIn["in-locataire"]} />

            </InventoryFormSection>

            <InventoryFormSection title={"Images"}>
                <div>
                    <p>Images envoyées : {invIn?.images?.length}</p>
                </div>
                <div className={"imagesList d-inline-flex"}>
                    {invIn?.images?.map((url, index) => <img key={index} src={Context.getBurl() + "/" + url} className={"ml-1"} alt={index} />)}
                </div>
            </InventoryFormSection>
        </div>
    )
}

function BookingSortie({ invOut, normalRooms, Context, editing }) {
    return (
        <div className="out">
            <h2>Sortie</h2>
            <InventoryFormSection title={"Mandataire"}>
                <InventoryFormRow>
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"out-agency"} label={"Agence"} defaultValue={invOut['out-agency']} />
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"out-agencyAdress"} label={"Adresse de l'agence"} defaultValue={invOut['out-agencyAdress']} />
                </InventoryFormRow>
                <InventoryFormRow>
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"out-represented"} label={"Représenté par"} defaultValue={invOut['out-represented']} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Dates clés"}>
                <InventoryFormRow>
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"out-start"} label={"Date d'entrée"} type={'date'} date={invOut['out-start']} />
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"out-end"} label={"Date de sortie"} type={'date'} date={invOut['out-end']} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Locataire"}>
                <InventoryFormRow>
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"out-locataire"} label={"Nom du locataire"} defaultValue={invOut['out-locataire']} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Lieu loué"}>
                <InventoryFormRow>
                    <InventoryFormInput disabled={!editing} readOnly={editing} name={"out-property-address"} label={"Adresse du bien"} defaultValue={invOut['out-property-address']} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Clé / Bips"}>
                <p>Veuillez renseigner pour chaque champ le nombre correspondant.</p>
                <InventoryFormRow>
                    <InventoryFormInput defaultValue={invOut['out-key-entree']} disabled={!editing} readOnly={editing} name={"out-key-entree"} size={2} type={"dropdownNumber"} label={"Porte d'entrée"} />
                    <InventoryFormInput defaultValue={invOut['out-key-portail']} disabled={!editing} readOnly={editing} name={"out-key-portail"} size={2} type={"dropdownNumber"} label={"Portail"} />
                    <InventoryFormInput defaultValue={invOut['out-key-bip']} disabled={!editing} readOnly={editing} name={"out-key-bip"} size={2} type={"dropdownNumber"} label={"Bip"} />
                    <InventoryFormInput defaultValue={invOut['out-key-garage']} disabled={!editing} readOnly={editing} name={"out-key-garage"} size={2} type={"dropdownNumber"} label={"Garage"} />
                    <InventoryFormInput defaultValue={invOut['out-key-autres']} disabled={!editing} readOnly={editing} name={"out-key-autres"} size={2} type={"dropdownNumber"} label={"Autre(s)"} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Pièces de la maison"}>
                {
                    // These rooms are all normal so just loop on them for an easier time
                    normalRooms.map((roomName, index) => {
                        return (
                            <InventoryFormRow key={index}>
                                <InventoryFormInput defaultValue={invOut["out-grade-" + slugify(roomName)]} disabled={!editing} readOnly={editing} name={"out-grade-" + slugify(roomName)} title={roomName} label={"État général"} type={"dropdownColour"} />
                                <InventoryFormInput defaultValue={invOut["out-comments-" + slugify(roomName)]} disabled={!editing} readOnly={editing} name={"out-comments-" + slugify(roomName)} size={10} label={"Commentaires"} type={"textarea"} />
                            </InventoryFormRow>
                        )
                    })
                }
                <InventoryFormRow>
                    <InventoryFormInput defaultValue={invOut["out-grade-privatised"]} disabled={!editing} readOnly={editing} name={"out-grade-privatised"} note={"(Balcon, terrasse, parking, garage, jardin)"} title={"Parties privatisées"} label={"État général"} type={"dropdownColour"} />
                    <InventoryFormInput defaultValue={invOut["out-comments-privatised"]} disabled={!editing} readOnly={editing} name={"out-comments-privatised"} size={10} label={"Commentaires"} type={"textarea"} />
                </InventoryFormRow>

                <InventoryFormRow>
                    <InventoryFormInput defaultValue={invOut["out-grade-equipments"]} disabled={!editing} readOnly={editing} name={"out-grade-equipments"} note={"(Sonnette, interphone, boîte aux lettres, portail, cheminée, poêle….)"} title={"Autres équipements"} label={"État général"} type={"dropdownColour"} />
                    <InventoryFormInput defaultValue={invOut["out-comments-equipments"]} disabled={!editing} readOnly={editing} name={"out-comments-equipments"} size={10} label={"Commentaires"} type={"textarea"} />
                </InventoryFormRow>

                <InventoryFormRow>
                    <InventoryFormInput defaultValue={invOut["out-grade-general"]} disabled={!editing} readOnly={editing} name={"out-grade-general"} note={"Avis général sur la propriété et le check-in."} title={"Observations générales et suggestions"} label={"État général"} type={"dropdownColour"} />
                    <InventoryFormInput defaultValue={invOut["out-comments-general"]} disabled={!editing} readOnly={editing} name={"out-comments-general"} size={10} label={"Commentaires"} type={"textarea"} />
                </InventoryFormRow>

            </InventoryFormSection>

            <InventoryFormSection title={"Compteurs"}>
                <InventoryFormRow>
                    <InventoryFormInput defaultValue={invOut["out-count-water"]} disabled={!editing} readOnly={editing} name={"out-count-water"} label={"Eau"} />
                    <InventoryFormInput defaultValue={invOut["out-counter-gas"]} readOnly={editing} name={"out-counter-gas"} label={"Gaz"} />
                </InventoryFormRow>
            </InventoryFormSection>

            <InventoryFormSection title={"Signature"}>
                <p><i>En signant ce document, vous reconnaissez avoir lu et approuvé toutes les informations contenues dans cet état des lieux. <br />
                    Toutes règles non respectées donne l'autorisation au propriétaire de prélever une somme sur la caution.</i></p>

                <h5>Signature du concierge : {invOut["out-represented"]}</h5>
                <InventorySignature signatureDefault={invOut["sortie-sign-concierge"]} inOrOut={"sortie"} who={"concierge"} signaturer={invOut["out-represented"]} />

                <h5>Signature du locataire : {invOut["out-locataire"]}</h5>
                <InventorySignature signatureConsent={invOut["out-mail-consent"]} signatureDefault={invOut["sortie-sign-locataire"]} inOrOut={"sortie"} who={"locataire"} signaturer={invOut["out-locataire"]} />

            </InventoryFormSection>

            <InventoryFormSection title={"Images"}>
                <div>
                    <p>Images envoyées : {invOut?.images?.length}</p>
                </div>
                <div className={"imagesList d-inline-flex"}>
                    {invOut?.images?.map((url, index) => <img key={index} src={Context.getBurl() + "/" + url} className={"ml-1"} alt={index} />)}
                </div>
            </InventoryFormSection>
        </div>
    )
}