import React from 'react';


export default function MultiplePossibilityText (props) {
    let className = ""

    if (!props.possibility || !props.value) return null

    if (props.isOnError !== undefined && props.isOnError) className = "danger"

    for (const item of props.possibility) {
        if (item.value === props.value) {
            return <span className={className}><small>{item.text}</small></span>
        }
    }
    return null
}