import Popover from "./Popover";
import BooleanTextError from "../BooleanTextError";
import MultiplePossibilityText from "../MultiplePossibilityText";


export function displayPopover(select, close, modify, context) {
    if (!select || !select.type) return null

    if (select.type === 'booking') {
        if (['airbnb', 'booking', 'homeaway', 'gdf', 'rent'].includes(select.item.platform.trim().toLowerCase())) {
            return (
                <Popover title={select.item.platform} select={select}
                    close={() => close()}
                    modify={() => modify(select.item)}>
                    <div className={"row m-0"}>
                        <div className={"col-6"}>
                            <p>Début : <small>{new Date(select.item.dates.start).toLocaleDateString()}</small></p>
                            <p>Fin : <small>{new Date(select.item.dates.end).toLocaleDateString()}</small></p>
                            <BooleanTextError title={"Prix nuitée"}
                                value={select.item.prices.night === undefined ? "- €" : `${select.item.prices.night} €`}
                                error={select.item.prices.night === undefined}
                                tooltips={"Prix nuitée non renseignée ou non valide"} />
                            <h6>Linge</h6>
                            <div className={"mx-1"}>
                                <BooleanTextError title={"Confirmation"}
                                    value={<MultiplePossibilityText value={select.item.laundry.took}
                                        possibility={[
                                            { value: "Y", text: " Oui" },
                                            { value: "W", text: " ?" },
                                            { value: "R", text: " Non" },
                                            { value: "C", text: " En attente" },
                                        ]} />}
                                    error={["W", "C"].includes(select.item.laundry.took)}
                                    tooltips={"Confirmation non renseignée ou en attente"} />
                                <BooleanTextError title={"Paiement du linge"}
                                    value={<MultiplePossibilityText value={select.item.laundry.payed}
                                        possibility={[
                                            { value: "Y", text: " Payé par le voyageur" },
                                            { value: "W", text: " En attente de payement" },
                                            { value: "P", text: " Payé par le propriétaire" },
                                            { value: "N", text: " ?" },
                                            { value: "R", text: " Non applicable" }
                                        ]} />}
                                    error={["W", "N"].includes(select.item.laundry.payed)}
                                    tooltips={"Paiement du linge non renseignée ou en attente"} />
                                <BooleanTextError title={"Prix du linge HT"}
                                    value={(select.item.laundry.price === undefined ? "-" : select.item.laundry.price) + " €"}
                                    tooltips={"Prix de ligne non renseignée ou non valide "} />
                                <BooleanTextError title={"Numéro de commande"} value={select.item.laundry.commandNumber}
                                    tooltips={"Numéro de commande non renseignée ou non valide"} />
                                <BooleanTextError title={"Date de réception"}
                                    value={select.item.laundry?.dates?.start ? new Date(select.item.laundry?.dates?.start).toLocaleDateString() : null}
                                    tooltips={"Date de réception non renseignée ou non valide"} />
                                <BooleanTextError title={"Date de retour"}
                                    value={select.item.laundry?.dates?.end ? new Date(select.item.laundry?.dates?.end).toLocaleDateString() : null}
                                    tooltips={"Date de retour non renseignée ou non valide"} />
                            </div>
                        </div>
                        <div className={"col-6"}>
                            <h6>Locataire :</h6>
                            <div className={"mx-1"}>
                                <BooleanTextError title={"Nom"}
                                    value={(select.item.people.traveler.name ? select.item.people.traveler.name : "")}
                                    error={!select.item.people.traveler?.name}
                                    tooltips={"Nom non renseignée ou non valide"} />
                                <BooleanTextError title={"Email"} value={select.item.people.traveler.email}
                                    tooltips={"Email non renseignée ou non valide"} />
                                <BooleanTextError title={"Téléphone"} value={select.item.people.traveler.phone}
                                    error={!select.item.people.traveler.phone}
                                    tooltips={"Téléphone non renseignée ou non valide"} />
                                <BooleanTextError title={"Résponsabilité civile"}
                                    value={<MultiplePossibilityText value={select.item.civicResponsibility}
                                        possibility={[
                                            { value: "D", text: " ?" },
                                            { value: "V", text: " Réceptionnée" },
                                            { value: "W", text: " En attente" },
                                            { value: "N", text: " Non applicable" },
                                        ]} />}
                                    error={["W", "D"].includes(select.item.civicResponsibility)}
                                    tooltips={"Résponsabilité civile non renseignée ou en attente"} />
                                <BooleanTextError title={"Nombre d'adultes"}
                                    value={select.item.people.adults}
                                    error={!select.item.people.adults}
                                    tooltips={"Nombre d'adultes non renseignée ou non valide"} />
                                <BooleanTextError title={"Nombre d'enfants"}
                                    value={select.item.people.kids?.toString()}
                                    error={select.item.people.kids === undefined}
                                    tooltips={"Nombre d'enfants non renseignée ou non valide"} />
                                <p>Type de caution:
                                    <MultiplePossibilityText value={select.item.bail.type}
                                        possibility={[
                                            {
                                                value: "S",
                                                text: "Swickly"
                                            },
                                            {
                                                value: "C",
                                                text: "Chèque"
                                            }
                                        ]} />
                                </p>
                                <BooleanTextError title={"Caution réglée"}
                                    value={<MultiplePossibilityText value={select.item.bail.paid}
                                        possibility={[
                                            { value: false, text: " Non" },
                                            { value: "R", text: " Oui, réceptionnée" },
                                            { value: "W", text: " En attente" },
                                            { value: "N", text: " ?" },
                                        ]} />}
                                    error={["N", "W"].includes(select.item.bail.paid)}
                                    tooltips={"Caution réglée non renseignée ou non valide"} />
                            </div>
                        </div>
                    </div>
                </Popover>
            )
        }
        if (['unavailable', 'back', "Rent-Client"].includes(select.item.platform)) {
            return (
                <Popover title={select.item.platform} select={select} close={() => close()} modify={() => {
                    modify(select.item)
                }}>
                    <div className={"row m-0"}>
                        <div className={"col-6"}>
                            <p>Début : <small>{new Date(select.item.dates.start).toLocaleDateString()}</small></p>
                            <p>Fin : <small>{new Date(select.item.dates.end).toLocaleDateString()}</small></p>
                            <p>Description : {select.item.description} </p>
                        </div>
                    </div>
                </Popover>
            )
        }
    }

    if (select.type === "intervention") {
        return (
            <Popover title={select.item.type + (select.item.cancelled ? " [annulée] " : " ") + new Date(select.item.date.dateStart).toLocaleDateString()}
                select={select}
                close={() => close()}
                modify={() => {
                    const object = select.item
                    object.choices = "InterventionBrief"
                    //  object.choices = select.item.group ? "InterventionMultiple" : "InterventionBrief"
                    modify(object)
                }}>
                <p>
                    Début : {
                        new Date(select?.item?.date?.dateStart).toISOString().substr(11, 5)
                    }
                </p>
                <p>
                    Durée : {select.item.date.duration}
                </p>
                <p>Description : {select.item.description.description}</p>
                <p>Personnes affiliées :</p>
                {
                    select.item.people.length === 0 ?
                        <p className={"danger"}>AUCUN PERSONNE AFFILIÉ</p>
                        :
                        select.item.people.map((people) => {
                            let className = "badge bg-booking rounded-pill"

                            let identified = context.data.people.Concierge.find(el => el.id === people.people)
                            let valid = people.report && people.finishDate && people.photo.length > 0 ? "success-bg" : "warning-bg"

                            if (!identified) identified = context.data.people.Equipe.find(el => el.id === people.people)

                            if (!identified) identified = context.data.people.Prestataires.find(el => el.id === people.people)

                            if (!identified) return (<span
                                className={[className, "danger", valid].join(' ')}>ERR {people.people}</span>)
                            return (<span
                                className={[className, "light", valid].join(' ')}>{identified.lastName} {identified.name}</span>)
                        })
                }
            </Popover>
        )
    }

    // default
    return (
        <Popover title={"erreur"}
            select={select}
            close={() => close()}>
            <p>Erreur</p>
        </Popover>
    )
}