export const InputField = ({ field }) => (
    <div className={"input type-" + field.type}>

        <div className="icon">{field.title}</div>
        <div className="area" style={{ width: "auto" }}>
            <input name={"text"} defaultValue={field.value} />
        </div>
    </div>
);

export const TextAreaField = ({ field }) => (
    <div className={"input type-" + field.type}>
        <div className="icon">{field.title}</div>
        <div className="area" style={{ width: "auto" }}>
            <textarea name={"textarea"} defaultValue={field.value} style={{ resize: "none" }} />
        </div>
    </div>
);

export const CheckboxField = ({ field }) => (
    <div className={"input type-" + field.type}>
        <div className="icon">{field.title}</div>
        <div className="area" style={{ width: "auto" }}>
            <input type='checkbox' name='check' defaultValue={field.value} defaultChecked={field.value ? true : false} className="form-check-input" />
        </div>
    </div>
);

export const DropdownField = ({ field }) => (
    <div className={"input type-" + field.type}>
        <div className="icon">{field.title}</div>
        <div className="area" style={{ width: "auto" }}>
            <select className="form-control h-100" name={"dropdown"} defaultValue={field.value} >
                {field.options?.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
        </div>
    </div>
);
