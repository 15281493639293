//External components
import React from "react";
import Image from 'react-image-webp';

//internal Components
import LoginBox from "../Components/LoginBox";

//Context
import DataContext from "../dataContext";

import "../assets/scss/login.scss"

export default function Login() {
    const context = React.useContext(DataContext)

    const [formData, setFormData] = React.useState({
        email: '',
        password: '',
        remember: false
    })
        , [message] = React.useState({ value: "", error: false })

    const handleChange = (e) => {
        if (e.target.name === "remember") {
            setFormData({
                ...formData,
                [e.target.name]: e.target.checked
            })
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value.trim()
            })
        }
    }

    return (
        <div className="parent">
            <div className={'background'} />
            <LoginBox>
                <Image webp={"https://i.ibb.co/3CgD7G4/new-Colors-Logo-2.webp"} src={"https://i.ibb.co/3CgD7G4/new-Colors-Logo-2.webp"} alt={"logo"} className={"col-6"} />
                <div className={"col-12"}>
                    <h3>MYPacôme</h3>
                    <h4>Planning Manager</h4>
                </div>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    context.apiReducer('login', {
                        email: formData.email,
                        password: formData.password
                    }, { remember: formData.remember })
                        .catch((err) => {
                            let item = formData
                            item.password = ''
                            setFormData(item)

                        })
                }}>
                    <div className={"form-group"}>
                        <input type={"email"} className={"form-control mt-lg-2"}
                            id={"email"} name={"email"} onChange={handleChange}
                            defaultValue={formData.email}
                            aria-describedby={"emailHelp"} placeholder={"Email"} />

                        <input type={"password"} className={"form-control mt-lg-2"}
                            id={"password"} name={"password"} onChange={handleChange}
                            defaultValue={formData.password} value={formData.password}
                            aria-describedby={"passwordHelp"} placeholder={"Mot de passe"} />
                    </div>

                    <div className={"form-check form-check-inline mt-lg-2"}>
                        <input type={"checkbox"} id={"remember"}
                            defaultChecked={formData.remember}
                            name={"remember"} onChange={handleChange} />
                        <label htmlFor={"remember"} className={"ms-1"}> Se souvenir de moi</label>
                    </div>

                    <div className={"form-group mt-lg-4"}>
                        <p className={message.error ? "warning" : "success"}>{message.value}</p>
                    </div>

                    <div className={"form-group mt-lg-4"}>
                        <button type={'submit'} className={"btn btn-secondary fancy-button"}>Connexion</button>
                    </div>

                    <div className={"form-group mt-lg-2"}>
                        <a className={"forgot"} href={"/reset"}>Mot de passe oublié ?</a>
                    </div>

                </form>
            </LoginBox>
        </div>
    );
}