import React from 'react'
import UserContext from "../dataContext";

// INTERNAL COMPONENTS
import Overlay from "../Components/Subcomponents/Overlay/Overlay";

// ICONS
import { BsPlus, BsDownload } from "react-icons/bs";

// FUNCTIONS
import { billing, nameOfMonth, round, toMonthYear, toTTC, csvMultipleClients } from "../Utils/functions";
import { CSVLink } from "react-csv";
import Spinner from '../Components/Spinner';

export default function Billing(props) {
    const userContext = React.useContext(UserContext)

    const [item, setItem] = React.useState({})
        , [loading, setLoading] = React.useState(true)
        , [, r] = React.useState({})
        , [list, setList] = React.useState([])
        , [monthList, setMonthList] = React.useState([])
        , [currentObj, setCurrentObj] = React.useState({ list: [], csv: [] })
        , [total, setTotal] = React.useState(0)

    const forceUpdate = React.useCallback(() => {
        r({})
    }, [r]);

    const selectFromDate = function (date, listTemp) {
        const currentListTemp = []
        let totalTemp = 0

        for (const client of listTemp) {
            const index = client.list.findIndex((el) => new Date(el.start).getTime() === new Date(date).getTime())
            if (index !== -1) {
                totalTemp += client.list[index].prices.total
                currentListTemp.push({ client: client.client, billing: client.list[index] })
            }
        }

        setCurrentObj({ list: currentListTemp, csv: csvMultipleClients(currentListTemp) })
        setTotal(round(totalTemp))
    }

    if (loading
        && list.length === 0
        && userContext.loading
        && userContext.data?.people?.Client?.length > 0) {

        const listTemp = []
        const monthListTemp = []
        const dateMin = new Date(2020, 0, 1);



        for (const client of userContext.data?.people?.Client) {
            if (!client.archive && client.subscription && client.clientId !== "C-9999") {
                const obj = { client: client, ...billing(client) }
                listTemp.push(obj)
                for (const listItem of obj.list) {
                    if (!(monthListTemp.some((el) => el.value.getTime() === listItem.start.getTime())) && listItem.start.getTime() > dateMin) {
                        monthListTemp.push({ value: listItem.start, title: toMonthYear(listItem.start) })
                    }
                }
            }
        }
        monthListTemp.sort((a, b) => new Date(a.start) > new Date(b.start) ? -1 : 1)
        setMonthList(monthListTemp)
        setList(listTemp)

        selectFromDate(monthListTemp[0].value, listTemp)

        setLoading(false)
    }

    if (userContext.loading || loading) {
        return (
            <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div className={"col-auto\n"}>
                        <h1>Facturation client</h1>
                    </div>
                    <div className={"col-auto\n"}>
                        <select className="form-select form-select-lg" aria-label="select" disabled>
                            <option selected>MOIS - ANNÉE</option>
                        </select>
                    </div>
                </div>
                <div className={"row justify-content-between mt-3"}>
                    <div className={"col-auto\n"}>
                        <h3>Total du mois</h3>
                        <h4>XXX.XX € HT</h4>
                        <h4>XXX.XX € TTC</h4>
                    </div>
                </div>
                <div className={"mt-3"}>
                    <h3>Détail par client</h3>
                    <Spinner strokeWidth={9} width={40} color={"#003952"} />
                </div>
            </div>
        )
    }

    for (let row of currentObj.csv) {
        // Loop through each element
        for (let i = 0; i < row.length; i++) {
            // Check if the element is a string
            if (typeof row[i] === "string") {
                // Replace any comma with a dot using a regular expression
                row[i] = row[i].replace(/,/g, ".");
            }
        }
    }
    return (
        <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
            <div className={"row justify-content-between"}>
                <div className={"col-auto\n"}>
                    <h1>Facturation client</h1>
                </div>
                <div className={"col-2\n"}>
                    <select className="form-select form-select-lg" aria-label="select" onChange={(e) => {
                        selectFromDate(e.target.value, list)
                    }}>
                        {monthList.map((month) => {
                            return <option value={month.value}>{month.title}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className={"row justify-content-between mt-3"}>
                <div className={"col-auto\n"}>
                    <h3>Total du mois</h3>
                    <h4>{total} € HT</h4>
                    <h4>{toTTC(total)} € TTC</h4>
                </div>
                <div className={"col-2\n"}>
                    <CSVLink type={"button"} className={"btn btn-outline-tertiary form-select-lg w-100 h-"}
                        data={currentObj.csv} separator={","}
                        filename={"Total.csv"}>
                        <BsDownload className={"display-2"} />
                    </CSVLink>
                </div>
            </div>
            <div className={"mt-3"}>
                <h3>Détail par client</h3>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th className={"text-center"}>Montant facturé (€ HT)</th>
                            <th className={"text-end"}>action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentObj.list.sort((a, b) => a.client.lastName > b.client.lastName ? 1 : -1).map((client) => {
                            if (client.client.archive) return null
                            if (!client.client.subscription) return null
                            for (let row of client.billing.csv) {
                                // Loop through each element
                                for (let i = 0; i < row.length; i++) {
                                    // Check if the element is a string
                                    if (typeof row[i] === "string") {
                                        // Replace any comma with a dot using a regular expression
                                        row[i] = row[i].replace(/,/g, ".");
                                    }
                                }
                            }
                            return (
                                <tr key={client.client.lastName + "-" + client.client.name}>
                                    <td>{client.client.lastName}</td>
                                    <td>{client.client.name}</td>
                                    <td className={"text-center"}>{round(client.billing.prices.total)}</td>
                                    <td className={"text-end"}>
                                        <button type={"button"} className={"btn btn-light"} onClick={() => {
                                            const object = client.client
                                            object.choices = "ClientInvoices"
                                            setItem(object)
                                        }}>
                                            <BsPlus />
                                        </button>
                                        <CSVLink type={"button"} className={"btn btn-light mx-1"}
                                            data={client.billing.csv}
                                            filename={
                                                client.client.lastName
                                                + "-"
                                                + client.client.name
                                                + "_"
                                                + nameOfMonth(client.billing.start)
                                                + "-"
                                                + client.billing.start.getFullYear()
                                                + ".csv"
                                            }>
                                            <BsDownload />
                                        </CSVLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <Overlay item={item}
                close={() => {
                    setItem(false)
                }}
                edit={(value) => {
                    setItem(value)
                    forceUpdate()
                }} />
        </div>
    )
}