//EXTERNAL MODULES
import React from 'react'
import Timeline, {
    TimelineHeaders,
    SidebarHeader,
    CustomMarker,
    CustomHeader
} from 'react-calendar-timeline'
import * as moment from 'moment'
import 'moment/locale/fr';

// CSS
import 'react-calendar-timeline/lib/Timeline.css'

// ICONS
import { BsPlus, BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Loader from "../Subcomponents/loader/loader";

export default function TimelineCustom(props) {
    const endDate = function () {
        let end = moment(props.options.filters.date)
        end.add(1, props.options.filters.view).startOf('day')
        return end
    }
        , end = endDate()
        , defaultTimeRange = props.options.filters.date - end
        , [loading, setLoading] = React.useState(true)
        , [groups, setGroups] = React.useState([])
        , [items, setItems] = React.useState([])
        , [isVisible, setIsVisible] = React.useState(false)


    React.useEffect(() => {
        let groupsR = []
        const itemsR = []
        window.addEventListener("scroll", () => {
            let heightToHideFrom = 100;
            const winScroll = document.body.scrollTop ||
                document.documentElement.scrollTop;
            if (winScroll < heightToHideFrom) {
                isVisible &&      // to limit setting state only the first time
                    setIsVisible(true);
            } else {
                setIsVisible(true);
            }
        });

        for (let client of props.data) {
            for (let properties of client.properties) {
                if (!client.archive) {
                    groupsR.push({
                        id: client.email + properties.name,
                        name: client.lastName + " " + properties.name,
                        title: (
                            <div className={"clickable"} onClick={() => {
                                const object = client
                                object.choices = "ClientChoices"
                                props.add(object)
                            }}>
                                <p><span className='last-name'>{client.lastName}</span> {client.name[0]}. <br /> {properties.name}</p>
                            </div>
                        ),
                    })
                }
                for (const booking of properties.bookings) {
                    if (!booking.cancelled) {

                        const bookingStart = new Date(booking.dates.start)
                        bookingStart.setHours(bookingStart.getHours() + 12)
                        const bookingEnd = new Date(booking.dates.end)
                        bookingEnd.setHours(bookingEnd.getHours() + 12)

                        itemsR.push({
                            id: client.email + properties.name + properties.bookings.indexOf(booking),
                            group: client.email + properties.name,
                            title: booking.platform,
                            start_time: moment(bookingStart),
                            end_time: moment(bookingEnd),
                            itemProps: {
                                onMouseDown: (ev) => {
                                    props.select({
                                        item: booking,
                                        type: 'booking',
                                        position: {
                                            x: ev.clientX,
                                            y: ev.clientY
                                        }
                                    })
                                },
                                className: 'rtc-booking ' + booking.platform + (booking.errors.length > 0 ? " error" : " "),
                            }
                        })
                    }
                }
                for (const intervention of properties.interventions) {
                    const duration = intervention.date.duration.split(":")
                        , start = new Date(intervention.date.dateStart)
                        , end = new Date(intervention.date.dateStart)
                    end.setTime(end.getTime() + (duration[0] * 60 * 60 * 1000) + (duration[1] * 60 * 1000))
                    let validation = intervention.valid ? "success" : '';
                    const isCancelled = intervention.cancelled ? "cancelled-intervention" : "";
                    if (start < new Date()) validation = "error"
                    if (intervention.valid) {
                        validation = "success"
                    }
                    if (!isCancelled) itemsR.push({
                        id: client.email + properties.name + intervention.id,
                        group: client.email + properties.name,
                        title: intervention.type,
                        start_time: moment(start),
                        end_time: moment(end),
                        itemProps: {
                            onMouseDown: (ev) => {
                                props.select({})
                                props.select({
                                    item: intervention,
                                    type: 'intervention',
                                    position: {
                                        x: ev.clientX,
                                        y: ev.clientY
                                    }
                                })
                            },
                            className: 'rtc-intervention ' + intervention.type + " " + validation + " " + isCancelled,
                        }
                    })
                }
            }
        }
        groupsR = groupsR.sort(function (a, b) {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
        setGroups(groupsR)
        setItems(itemsR)
        setLoading(false)

    }, [props, setGroups, setItems, setLoading, isVisible, setIsVisible])

    if (loading) return <Loader />

    return (
        <Timeline
            groups={groups}
            items={items}
            canMove={false}
            canResize={false}
            canChangeGroup={false}
            lineHeight={50}
            itemHeightRatio={0.45}
            stackItems
            moveResizeValidator={() => {
            }}
            minZoom={defaultTimeRange}
            maxZoom={defaultTimeRange}
            onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
                updateScrollCanvas(moment(props.options.filters.date).valueOf(), moment(end).valueOf())
            }}
            defaultTimeStart={props.options.filters.date}
            defaultTimeEnd={end}
            sidebarWidth={170}
            visibleTimeStart={props.options.filters.date.valueOf()}
            visibleTimeEnd={end.valueOf()}>
            <TimelineHeaders>
                <SidebarHeader id={"SidebarHeader"}>
                    {({ getRootProps }) => {
                        return (
                            <>
                                <div {...getRootProps()} className={"d-flex justify-content-around "}>
                                    {
                                        <button className={"btn btn-outline-light w-100"} onClick={props.previous}>
                                            <h3><BsArrowLeftShort /></h3>
                                        </button>
                                    }
                                    <button className={"btn btn-outline-light w-100"} onClick={() => {
                                        props.add({ choices: "-" })
                                    }}>
                                        <h3><BsPlus /></h3>
                                    </button>
                                    {
                                        <button className={"btn btn-outline-light w-100"} onClick={props.next}>
                                            <h3><BsArrowRightShort /></h3>
                                        </button>
                                    }
                                </div>
                            </>
                        )
                    }}
                </SidebarHeader>
                {/* <DateHeader unit="primaryHeader" />
                <DateHeader /> */}
                <CustomHeader height={50} headerData={{ someData: 'data' }} unit="day">
                    {({
                        headerContext: { intervals },
                        getRootProps,
                        getIntervalProps,
                        showPeriod,
                        data,
                    }) => {
                        return (
                            <div {...getRootProps()}>
                                {intervals.map(interval => {
                                    const intervalStyle = {
                                        textAlign: 'center',
                                        lineHeight: '50px',
                                        borderLeft: '1px solid black',
                                        cursor: 'pointer',
                                        backgroundColor: 'white',
                                        color: 'black',
                                        textTransform: 'capitalize'
                                    }
                                    const intervalBase = interval.startTime.format('dd-D')
                                    const finalInterval = intervalBase.slice(0, 1) + intervalBase.slice(2)
                                    return (
                                        <div
                                            onClick={() => {
                                                showPeriod(interval.startTime, interval.endTime)
                                            }}
                                            {...getIntervalProps({
                                                interval,
                                                style: intervalStyle
                                            })}
                                        >
                                            <div className={(finalInterval.charAt(0) === "s" || finalInterval.charAt(0) === "d") ? "rct-day-0 sticky" : "sticky"}>
                                                {finalInterval}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }}
                </CustomHeader>
            </TimelineHeaders>
            <CustomMarker className={"todayMarker"} date={moment(new Date()).valueOf()} />
        </Timeline>
    )

}