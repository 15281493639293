//External components
import React from "react";
import { BsXCircle, BsChevronUp, BsChevronDown, BsChevronContract } from "react-icons/bs";

export default function ResourcesUpdate(props) {
    const [percentage] = React.useState(toPercent(props.count, props.toAdd))

    // React.useEffect(() => {
    //     if ((!props.name) || (!props.toAdd) || (!props.count) || (!props.icon)) {
    //         console.error('Component ResourcesUpdate - Missing one or more props : name, toAdd, count, icon')
    //     }
    // }, [props])

    return (
        <div className={"row justify-content-center align-items-center"}>
            <div className={"col-2 text-center align-items-center"}>
                {props.icon || <BsXCircle />}
            </div>
            <div className={"col"}>
                <div className={"row"}>
                    <h3 className={"col"}>{props.name}</h3>
                    <h3 className={"col text-end"}>{props.count}</h3>
                </div>

                <div
                    className={color(props.toAdd, "row justify-content-start success", "row justify-content-start warning", "row justify-content-start danger")}>
                    <h5 className={"col-6 text-start"}>{iconSwitch(props.toAdd)}{percentage}</h5>
                    <h5 className={"col-6 text-end"}>{props.toAdd}</h5>
                </div>
            </div>
        </div>
    )
}

function toPercent(sum, add) {
    let percentage = (add / sum * 100).toFixed(2)
    return percentage + "%"
}

function iconSwitch(value) {
    if (!value && typeof value !== 'number') return null
    if (value > 0) {
        return (
            <BsChevronUp />
        )
    }
    if (value === 0) {
        return (
            <BsChevronContract />
        )
    }
    if (value < 0) {
        return (
            <BsChevronDown />
        )
    }

}

function color(value, sup, equ, inf) {
    if (!value && typeof value !== 'number') return null
    if (value > 0) return sup
    if (value === 0) return equ
    if (value < 0) return inf
}