import React, { useEffect, useRef, useState } from 'react';
import { BsTrashFill, BsPencilSquare, BsCheckLg, BsFillXCircleFill } from "react-icons/bs";

export default function TitleField({ title, handleTitleAndTypeChange, onTitleDelete, titleIndex, listOfModifications, sectionOrCategory }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState(title);
    const [editedType, setEditedType] = useState("");
    const originalType = useRef("");

    useEffect(() => {
        if (sectionOrCategory.type) {
            setEditedType(sectionOrCategory.type)
            originalType.current = sectionOrCategory.type
        }
    }, [sectionOrCategory.type])
    const originalTitle = useRef(title);


    const handleSave = (listOfModifications, sectionOrCategory) => {
        if (sectionOrCategory.title !== editedTitle || (sectionOrCategory.type && sectionOrCategory.type !== editedType)) {
            handleTitleAndTypeChange(editedTitle, editedType, listOfModifications, sectionOrCategory)
        }
        setIsEditing(false);
    };

    const handleDelete = () => {
        onTitleDelete(titleIndex, listOfModifications);
    };

    const handleEdit = () => {
        setIsEditing(true);
        originalTitle.current = editedTitle;
        originalType.current = editedType;
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedTitle(originalTitle.current);
        setEditedType(originalType.current);
    };

    return (
        <div className="row my-4 py-2">
            <div className="col form-group">
                {isEditing ? (
                    <input className='form-control fw-bold black' value={editedTitle} onChange={(e) => setEditedTitle(e.target.value)} />
                ) : (
                    <input className='form-control fw-bold black' value={editedTitle} onChange={(e) => setEditedTitle(e.target.value)} disabled />
                )}
            </div>
            {sectionOrCategory.type ? (<div className="col">
                {isEditing ? (
                    <select
                        className='form-control'
                        name={"type"}
                        value={editedType}
                        onChange={(e) => setEditedType(e.target.value)}
                    >
                        <option value="text">Texte</option>
                        <option value="check">Check</option>
                    </select>
                ) : (
                    <select
                        className='form-control'
                        name={"type"}
                        value={editedType}
                        onChange={(e) => setEditedType(e.target.value)}
                        disabled
                    >
                        <option value="text">Texte</option>
                        <option value="check">Check</option>
                    </select>
                )}

            </div>) : ""}

            <div className="col">
                {isEditing ? (
                    <>
                        <button className='btn btn-primary mx-2 btn-cancel btn-accordion' onClick={handleCancel}><BsFillXCircleFill /></button>
                        <button className='btn btn-primary mx-2 btn-save btn-accordion' onClick={() => handleSave(listOfModifications, sectionOrCategory)}><BsCheckLg /></button>
                    </>
                ) : (
                    <>
                        <button className='btn btn-primary mx-2 btn-delete btn-accordion' onClick={handleDelete}><BsTrashFill /></button>
                        <button className='btn btn-primary mx-2 btn-edit btn-accordion' onClick={handleEdit}><BsPencilSquare /></button>
                    </>
                )}
            </div>

        </div>
    );
}
