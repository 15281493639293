import Section from './Section';
import Accordion from '../../../Cards/Accordion';

export default function ModifyHomeView({ testUserHomeView, setTestUserHomeView, listOfModifications, setListOfModifications, setHomeViewVersion }) {
    const handleSectionChange = (updatedSection, index) => {
        setTestUserHomeView(prevHomeView => {
            const updatedSections = prevHomeView.map((section, i) =>
                i === index ? updatedSection : section
            );
            return updatedSections;
        });
        setHomeViewVersion(prevVersion => prevVersion + 1);
    };


    const handleSectionDelete = (deletedIndex, listOfModifications) => {
        if (window.confirm('Êtes vous sûr de vouloir supprimer cette section ?')) {
            const updatedSections = testUserHomeView.filter((a, i) => i !== deletedIndex);
            setTestUserHomeView(testUserHomeView => updatedSections);
            setListOfModifications([...listOfModifications, { action: "deleteSection", sectionIndex: deletedIndex }])
        }
    };

    const handleSectionAdd = (listOfModifications) => {
        const newSection = { title: "Nouvelle section", content: [], images: [] };
        setTestUserHomeView(testUserHomeView => [...testUserHomeView, newSection]);
        setListOfModifications([...listOfModifications, { action: "addSection", value: newSection }])
    };

    return (
        <div className={"modify-home-sheet col mx-1 p-2 mt-3 mt-md-0"}>
            {Array.isArray(testUserHomeView) ? testUserHomeView?.map((section, index) => (
                <Accordion key={index} title={section.title}>
                    <Section
                        key={index}
                        sectionIndex={index}
                        section={section}
                        onSectionChange={handleSectionChange}
                        onSectionDelete={handleSectionDelete}
                        setListOfModifications={setListOfModifications}
                        listOfModifications={listOfModifications}
                    />
                </Accordion>
            )) : null}
            <button className='btn btn-primary mt-4 btn-accordion' onClick={() => handleSectionAdd(listOfModifications)}>Ajouter une section</button>
        </div>
    );
}