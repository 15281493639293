import React from "react";

// Icons
import { BsTrashFill } from "react-icons/bs";
// context
import UserContext from '../../../../../../dataContext'

export default function HomeView(props) {
    const context = React.useContext(UserContext)
    const burl = context.getBurl()
    let privateData
    try {
        privateData = JSON.parse(props.private)
    } catch (error) {
        privateData = null
    }
    try {
        const list = JSON.parse(props.data)
        return (
            <>
                <form className={"home-view-add-container"} >
                    <div className={"home-view-header"}>
                    </div>
                    {/* Not pretty but this is the private section to separate the data so it doesnt get sent to concierges  */}
                    <div className="section">
                        <div className="input-title">
                            <div className="area">
                                <input name={"title"} type={"text"}
                                    defaultValue={"Notes de l'équipe"} disabled />
                            </div>
                        </div>

                        <div className="category">
                            <hr></hr>
                            <p className="name text-center fw-bold">Notes de l'équipe</p>
                            <p className="name text-center "><i>Ces notes ne seront qu'uniquement visibles sur PM.</i></p>
                            <div className={"input type-textarea"}>
                                <div className="icon">Notes</div>
                                <div className="area" style={{ width: "auto" }}>
                                    <textarea
                                        name={"textarea"}
                                        value={privateData?.notes}
                                        disabled
                                        style={{ resize: "none" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {list ? list?.map((item, indexCategory) => {
                        // { console.log(item.images, "images")}
                        const images = item.images?.map((image) => {
                            return burl + "/uploads/" + image
                        })
                        return (
                            <div className={"section"} key={indexCategory}>

                                <div className="input-title">
                                    <div className="area">
                                        <input name={"title"} type={"text"}
                                            defaultValue={item.title} disabled />
                                    </div>
                                    <button className={"btn-delete"} hidden={!item.deletable} onClick={() => {
                                    }}><BsTrashFill />
                                    </button>
                                </div>
                                {item.content?.map((item, indexSection) => {

                                    return (
                                        <div className="category" key={indexSection}>
                                            <hr></hr>
                                            <p className="name text-center fw-bold">{item.title}</p>
                                            {item.type === "text" ? (
                                                item.fields?.map((field, indexSingle) => {
                                                    if (field.type === "check") {
                                                        field.value = field.value === "true"
                                                    }
                                                    return (
                                                        <div className={"input type-" + field.type}>
                                                            <div className="icon">{field.title}</div>
                                                            <div className="area" style={{ width: "auto" }}>
                                                                {field.type === "input" ? (
                                                                    <input
                                                                        name={"text"}
                                                                        value={field.value}
                                                                        disabled
                                                                    />
                                                                ) : field.type === "textarea" ? (
                                                                    <textarea
                                                                        name={"textarea"}
                                                                        value={field.value}
                                                                        disabled
                                                                        style={{ resize: "none" }}
                                                                    />

                                                                ) : field.type === "check" ? (
                                                                    <input
                                                                        type='checkbox'
                                                                        name='check'
                                                                        value={field.value}
                                                                        disabled
                                                                        checked={field.value ? true : false}
                                                                        className="form-check-input"
                                                                    />


                                                                ) : (
                                                                    <select
                                                                        className="form-control h-100"
                                                                        name={"dropdown"}
                                                                        value={field.value}
                                                                        disabled
                                                                    >
                                                                        {field.options?.map((option) => (
                                                                            <option value={option}>{option}</option>
                                                                        ))}
                                                                    </select>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )


                                                })
                                            ) : (
                                                <div className="section-check">
                                                    <input
                                                        type='checkbox'
                                                        name='check'
                                                        value={item.value}
                                                        disabled
                                                        checked={item.value ? true : false}
                                                        className="form-check-input"
                                                    />

                                                    {
                                                        item.fields?.map((field, indexSingle) => {
                                                            let cssClass
                                                            item.value ? cssClass = "" : cssClass = " d-none"
                                                            return (

                                                                <div className={"input type-" + field.type + cssClass} key={indexSingle}>
                                                                    <div className="icon">{field.title}</div>
                                                                    <div className="area" style={{ width: "auto" }}>
                                                                        {field.type === "input" ? (
                                                                            <input
                                                                                name={"text"}
                                                                                disabled
                                                                                value={field.value}
                                                                            />
                                                                        ) : field.type === "textarea" ? (
                                                                            <textarea
                                                                                name={"textarea"}
                                                                                disabled
                                                                                value={field.value}
                                                                                style={{ resize: "none" }}
                                                                            />
                                                                        ) : field.type === "check" ? (
                                                                            <input
                                                                                type="checkbox"
                                                                                name={"check"}
                                                                                disabled
                                                                                value={field.value}
                                                                                className="form-check-input"
                                                                            />
                                                                        ) : (
                                                                            <select
                                                                                className="form-select"
                                                                                name={"dropdown"}
                                                                                disabled
                                                                                value={field.value}
                                                                            >
                                                                                {field.options?.map((option) => (
                                                                                    <option value={option}>{option}</option>
                                                                                ))}
                                                                            </select>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                            )

                                                        })
                                                    }

                                                </div>

                                            )}
                                        </div>
                                    );
                                })}

                                {/* <div className="input">
                                    <div className="name text-center fw-bold">Photos</div>
                                    <div className="area">
                                        <div hidden={!images || images?.length === 0} className={"home-view-image-container col"} >
                                            <Gallery images={images} />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="form-group multi-preview">
                                    <div className="row text-center text-lg-start">
                                        {images?.map((url, index) => (
                                            <div className="col-6">
                                                <div className="image-container">
                                                    <img src={url} alt="..." className="img-fluid img-thumbnail" />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        )
                    }) : ""}
                </form>
            </>
        )
    } catch (error) {
        console.log(error)
        return <div>Erreur</div>
    }

}