import React, { useState } from "react";
import UserContext from '../dataContext'
import GestionFichesHome from "../Components/Gestion/Fiches Home/GestionFichesHome";
import '../assets/scss/gestion.scss'
import Spinner from '../Components/Spinner';
import * as XLSX from 'xlsx';

export default function Gestion() {
    const userContext = React.useContext(UserContext)
    const [, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    if (userContext.loading) {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Gestion</h1>
                    </div>
                </div>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                <h2 className="text-center">Chargement...</h2>
            </div>
        )
    } else if (!userContext.loading) {
        return (
            <>
                <div>
                    <div className={"row justify-content-center"}>
                        <div className="">
                            <h1 className="blue fw-bold text-center">Gestion</h1>
                            <div className={"w-48 cardsContainer cardShadow mx-auto p-2 mt-5"}>
                                <div className={"row justify-content-between gap-2"}>
                                    <div className="row flex-column align-items-center text-center">
                                        <h2 className="blue fw-bold">Facturation - produits supplémentaires</h2>
                                        <p className="black"><strong>Envoyez un fichier .csv de la liste des produits pour la facturation</strong></p>
                                        <form className="row flex-column mt-4 w-auto" onSubmit={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setIsLoading(true);
                                            const file = e.target.products.files[0];
                                            let reader = new FileReader();
                                            let fileExtension = file.name.split('.').pop().toLowerCase();
                                            reader.onload = function (event) {
                                                let fileContent = event.target.result;
                                                let csvString;

                                                if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                                                    // Handle XLS/XLSX file
                                                    let workbook = XLSX.read(fileContent, { type: 'binary' });
                                                    let firstSheetName = workbook.SheetNames[0];
                                                    let worksheet = XLSX.utils.sheet_to_csv(workbook.Sheets[firstSheetName]);
                                                    csvString = worksheet;
                                                } else if (file.type === 'text/csv') {
                                                    // Handle CSV file
                                                    csvString = fileContent;
                                                } else {
                                                    console.error('Unsupported file type');
                                                    setIsLoading(false);
                                                    return;
                                                }

                                                // Manually parse CSV string to JSON
                                                let lines = csvString.split('\n');
                                                let headers = lines[0].split(',');
                                                let parsedData = lines.slice(1).map(line => {
                                                    let values = line.split(',');
                                                    let obj = {};
                                                    headers.forEach((header, index) => {
                                                        obj[header.trim()] = values[index].trim();
                                                    });
                                                    return obj;
                                                });

                                                // Convert JSON object to string
                                                const csvJSONString = JSON.stringify(parsedData);
                                                const fd = new FormData();
                                                fd.append('products', csvJSONString);
                                                userContext.apiReducer('createProductsFromCSV', fd, {})
                                                    .then((response) => {
                                                        setIsLoading(false);
                                                    })
                                                    .catch((err) => {
                                                        console.log(err);
                                                        setIsLoading(false);
                                                    });
                                            };

                                            if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                                                reader.readAsArrayBuffer(file);
                                            } else if (file.type === 'text/csv') {
                                                reader.readAsText(file);
                                            } else {
                                                console.error('Unsupported file type');
                                                setIsLoading(false);
                                            }
                                        }}>
                                            <input type="file" accept=".csv, .xls" className="form-control-file" name="products" id="products" required onChange={(e) => {
                                                setFile(e.target.files)
                                            }} />
                                            <button type="submit" className="btn btn-primary mt-4">{isLoading ? <div className="spinner-border text-light" role="status"></div> : 'Envoyer'}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={"w-full cardsContainer cardShadow mx-1 p-2 mt-5"}>
                        <div className={"row justify-content-between gap-2"}>
                            <div className="row flex-column align-items-center text-center">
                                <h2 className="blue fw-bold">Modification de la stucture des fiches Home</h2>
                                <GestionFichesHome context={userContext} />
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }

    else { console.log("exeption") }
}


// Hi someone from the future where this breaks because the person uploading doesnt have commas as separators!
// You could add a dropdown where the person select their separator!
// Seems easy enough from here.... though i won't do it myself i have more stuff to do lol

// function csvJSONString(csv) {

//     let lines = csv.split("\n");

//     let result = [];

//     // NOTE: If your columns contain commas in their values, you'll need
//     // to deal with those before doing the next step
//     // (you might convert them to &&& or something, then covert them back later)
//     let headers = lines[0].split(",");

//     for (let i = 1; i < lines.length; i++) {

//         let obj = {};
//         let currentline = lines[i].split(",");

//         for (let j = 0; j < headers.length; j++) {
//             obj[headers[j]] = currentline[j];
//         }

//         result.push(obj);

//     }

//     return JSON.stringify(result); //JSON
// }