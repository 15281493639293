import { useState } from "react";
import Spinner from "../../../Components/Spinner";

export default function ClientPopUp({ showClientPopUp, setShowClientPopUp, contacts, setClientId, setSiret, setAddress,
    setPayingMethod, setFullAddress, setFullSecondAddress, setBillingAddress, setIsTheSameAsFullAddress, setFullName, setClientMail
}) {
    const [searchTerm, setSearchTerm] = useState("");

    const filteredContacts = contacts.filter(contact =>
        contact?.clientId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact?.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (contacts.length === 0) return (
        <div className={'billing-popup' + (showClientPopUp ? " open" : "")} onClick={(e) => { if (e.target.classList.contains('billing-popup')) { e.target.classList.remove('open'); setShowClientPopUp(false); } }}>
            <div className="container">
                <h3>Clients</h3>
                <h2>Chargement des clients...</h2>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
            </div>
        </div>
    )
    return (
        <div className={'billing-popup' + (showClientPopUp ? " open" : "")} onClick={(e) => { if (e.target.classList.contains('billing-popup')) { e.target.classList.remove('open'); setShowClientPopUp(false); } }}>
            <div className="container">
                <h3>Clients</h3>
                <input
                    type="text"
                    placeholder="Rechercher un client..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control mb-3"
                />
                <table className="contactTable table-maxxing">
                    <thead>
                        <tr>
                            <th>
                                <span>ID Client</span>
                            </th>
                            <th>
                                <span>Nom</span>
                            </th>
                            <th>
                                <span>Prénom</span>
                            </th>
                            <th>
                                <span>Email</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredContacts.map((contact, index) => (
                            <tr
                                key={index}
                                onClick={() => {
                                    // Set full address if available
                                    if (contact.fullAddress.roadAndNumber) {
                                        setFullAddress({
                                            roadAndNumber: contact.fullAddress.roadAndNumber,
                                            zipCode: contact.fullAddress.zipCode,
                                            city: contact.fullAddress.city,
                                            country: contact.fullAddress.country,
                                            complement: contact.fullAddress.complement,
                                        });
                                    }

                                    // Set full second address if available
                                    if (contact.fullSecondAddress.roadAndNumber) {
                                        setFullSecondAddress({
                                            roadAndNumber: contact.fullSecondAddress.roadAndNumber,
                                            zipCode: contact.fullSecondAddress.zipCode,
                                            city: contact.fullSecondAddress.city,
                                            country: contact.fullSecondAddress.country,
                                            complement: contact.fullSecondAddress.complement,
                                        });
                                    }

                                    // Set billing address if available
                                    if (contact.billingAddress.roadAndNumber) {
                                        setBillingAddress({
                                            roadAndNumber: contact.billingAddress.roadAndNumber,
                                            zipCode: contact.billingAddress.zipCode,
                                            city: contact.billingAddress.city,
                                            country: contact.billingAddress.country,
                                            complement: contact.billingAddress.complement,
                                            isTheSameAsFullAddress: contact.billingAddress.isTheSameAsFullAddress,
                                        });
                                    }

                                    setClientId(contact.clientId);
                                    setSiret(contact.siret);
                                    setPayingMethod(contact.paymentMethod);
                                    setIsTheSameAsFullAddress(
                                        contact.billingAddress.isTheSameAsFullAddress,
                                    );
                                    setFullName(
                                        contact.status === "Personne physique"
                                            ? `${contact.name} ${contact.lastName}`
                                            : contact.socialReason,
                                    );
                                    setClientMail(contact.email);
                                    setShowClientPopUp(false);
                                }}
                            >
                                <td>{contact.clientId}</td>
                                {contact.status === "Personne morale" ? (
                                    <>
                                        <td>{contact.socialReason}</td>
                                        <td>{contact.formeJuridique}</td>
                                    </>
                                ) : (
                                    <>
                                        <td>{contact.lastName}</td>
                                        <td>{contact.name}</td>
                                    </>
                                )}
                                <td>{contact.email}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>;
            </div>
        </div>
    );
}