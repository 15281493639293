import Field from "./Field";
import TitleField from "./TitleField";

export default function Category({ category, onCategoryChange, onCategoryDelete, categoryIndex, listOfModifications, setListOfModifications, sectionIndex }) {

    const handleFieldChange = (updatedField, index) => {
        const updatedFields = category.fields.map((field, i) =>
            i === index ? updatedField : field
        )
        onCategoryChange({ ...category, fields: updatedFields }, categoryIndex);
    };

    const handleFieldDelete = (deletedFieldIndex) => {
        const updatedFields = category.fields.filter((field, index) => index !== deletedFieldIndex);
        onCategoryChange({ ...category, fields: updatedFields }, categoryIndex);
        setListOfModifications(prevState => [...prevState, { action: "deleteField", fieldIndex: deletedFieldIndex, categoryIndex: categoryIndex, sectionIndex: sectionIndex }])
    };

    const handleFieldAdd = (listOfModifications) => {
        const newField = { title: "Nouveau champ", type: "input", value: "" };
        onCategoryChange({ ...category, fields: [...category.fields, newField] }, categoryIndex);
        setListOfModifications(prevState => [...prevState, { action: "addField", categoryIndex: categoryIndex, sectionIndex: sectionIndex, value: newField }])
    };

    const handleTitleAndTypeChange = (newTitle, newType, listOfModifications) => {
        onCategoryChange({ title: newTitle, type: newType }, categoryIndex)
        setListOfModifications(prevState => [...prevState, { action: "modifyCategoryTitle", categoryIndex: categoryIndex, sectionIndex: sectionIndex, value: newTitle }, { action: "modifyCategoryType", categoryIndex: categoryIndex, sectionIndex: sectionIndex, value: newType }])
    };
    
    const handleCategoryDelete = () => {
        if (window.confirm('Êtes vous sûr de vouloir supprimer cette catégorie ?')) {
            onCategoryDelete(categoryIndex, listOfModifications);
        }
    };    

    return (
        <div className="category">
            <hr></hr>
            <div className="row">
                <div className="col form-group">
                    <strong>Titre de la catégorie</strong><br />
                </div>
                <div className="col form-group">
                    <strong>Type de la catégorie</strong><br />
                </div>

                <div className="col">
                    <strong>Actions</strong><br />
                </div>
            </div>
            <TitleField
                type={"category"}
                title={category.title}
                handleTitleAndTypeChange={handleTitleAndTypeChange}
                titleIndex={categoryIndex}
                sectionOrCategory={category}
                listOfModifications={listOfModifications}
                onTitleDelete={handleCategoryDelete}
            />
            {/* <p className="name text-center fw-bold black">{category.title}</p> */}

            <div className="row">
                <div className="col form-group">
                    <strong>Titre du champ</strong><br />
                </div>
                <div className="col">
                    <strong>Type de champ</strong><br />
                </div>
                <div className="col">
                    <strong>Options du dropdown</strong><br />
                </div>
                <div className="col">
                    <strong>Actions</strong><br />
                </div>
            </div>

            {category.fields.map((field, index) => {
                return (<Field
                    key={index}
                    fieldIndex={index}
                    field={field}
                    onFieldChange={handleFieldChange}
                    onFieldDelete={handleFieldDelete}
                    setListOfModifications={setListOfModifications}
                    listOfModifications={listOfModifications}
                    categoryIndex={categoryIndex}
                    sectionIndex={sectionIndex}
                />)
            })}


            <button className='btn btn-primary mt-4 btn-accordion' onClick={() => handleFieldAdd(listOfModifications)}>Ajouter un champ</button>
        </div>
    );
}