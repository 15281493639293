import React from 'react';
import Category from './Category';
import TitleField from './TitleField';

export default function Section({ section, onSectionChange, onSectionDelete, sectionIndex, listOfModifications, setListOfModifications }) {

    const handleCategoryChange = (updatedCategory, categoryIndex) => {
        const updatedCategories = section.content.map((category, i) => i === categoryIndex ? Object.assign({}, category, updatedCategory) : category );   
        onSectionChange({ ...section, content: updatedCategories }, sectionIndex);
    };

    const handleCategoryDelete = (deletedCategoryIndex, listOfModifications) => {
        const updatedCategories = section.content.filter((category, index) => index !== deletedCategoryIndex);
        onSectionChange({ ...section, content: updatedCategories }, sectionIndex);
        setListOfModifications([...listOfModifications, { action: "deleteCategory", categoryIndex: deletedCategoryIndex, sectionIndex: sectionIndex }])
    };

    const handleCategoryAdd = (listOfModifications) => {
        const newCategory = { title: "Nouvelle catégorie", type: "text", fields: [] };
        onSectionChange({ ...section, content: [...section.content, newCategory] }, sectionIndex);
        setListOfModifications([...listOfModifications, { action: "addCategory", sectionIndex: sectionIndex, value: newCategory }])
    };

    const handleTitleAndTypeChange = (newTitle, newType, listOfModifications, section) => {
        onSectionChange({ ...section, title: newTitle }, sectionIndex);
        setListOfModifications([...listOfModifications, { action: "modifySectionTitle", sectionIndex: sectionIndex, value: newTitle }])
    };
        
    return (
        <div className="section">
            <div className="row">
                <div className="col form-group">
                    <strong>Titre de la section</strong><br />
                </div>
                <div className="col">
                    <strong>Actions</strong><br />
                </div>
            </div>
            <TitleField
                title={section.title}
                handleTitleAndTypeChange={handleTitleAndTypeChange}
                sectionOrCategory={section}
                onTitleDelete={onSectionDelete}
                titleIndex={sectionIndex}
                setListOfModifications={setListOfModifications}
                listOfModifications={listOfModifications}
            />
            {section.content.map((category, categoryIndex) => (
                <Category
                    key={categoryIndex}
                    category={category}
                    categoryIndex={categoryIndex}
                    onCategoryChange={handleCategoryChange}
                    onCategoryDelete={handleCategoryDelete}
                    setListOfModifications={setListOfModifications}
                    listOfModifications={listOfModifications}
                    sectionIndex={sectionIndex}
                />
            ))}
            <button className='btn btn-primary mt-4 btn-accordion' onClick={() => handleCategoryAdd(listOfModifications)}>Ajouter une catégorie</button>
        </div>
    );
}