
import React from "react";

// Icons
import { BsDownload } from "react-icons/bs";
import Gallery from "../../../../../Gallery/Gallery";
// context
import UserContext from '../../../../../../dataContext'

import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        padding: "10mm 0"
    },
    section: {
        width: '90%',
        margin: "10 auto",
    },
    sectionText: {
        margin: "0 auto",
        color: '#000',
        fontWeight: "black",
        fontSize: 25,
        padding: 10
    },
    inputTitle: {
        borderCollapse: 'collapse',
        width: '100%',
        flexDirection: 'row'
    },
    category: {
        flexDirection: 'row',
        gapColumn: '2rem',
        padding: '15 0 0',
        fontSize: '20',
        borderTop: "1px solid #363538",
        margin: "10 auto 0",
        width: "100%",
        textAlign: 'center'
    },
    imageContainer: {
        display: 'flex',
        flexDirection: "row",
        flexWrap: 'wrap',
        width: '100%',
        gap: "10"
    },
    imageDiv: {
        width: "250"
    },
    image: {
        width: "100%"
    },
    text: {
        fontSize: "14",
        lineHeight: "1.4",
        marginTop: "6"
    },
    fullWidth: {
        width: "100%"
    }

});

export default function HomeViewPDF(props) {
    const context = React.useContext(UserContext)
    const burl = context.getBurl()
    const list = props.data
    const document = HomeViewPDFFile(burl, list)
    // return (
    //     <PDFViewer>
    //         {document}
    //     </PDFViewer>
    // )
    return (
        <PDFDownloadLink document={document} fileName={props.title} width={"100%"} height={"92%"}>
            {({ blob, url, loading, error }) =>
                loading ? 'Chargement...' : <DownloadButton />
            }
        </PDFDownloadLink >
    )
}

function DownloadButton() {
    return (
        <button className={"btn btn-warning"}>
            <BsDownload />
        </button>
    )
}

function HomeViewPDFFile(burl, list) {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    {
                        list?.map(data => {
                            const images = data.images.map((image) => {
                                return burl + "/uploads/" + image
                            })
                            const formattedText = data.text.split('\n');
                            return (
                                <View>
                                    <Text style={styles.category}>{data.title}</Text>
                                    <div hidden={!images || images?.length === 0} className={"home-view-image-container col"} >
                                        <Gallery images={images} />
                                    </div>
                                    <View>
                                        {
                                            formattedText.map(text => {
                                                return (
                                                    <>
                                                        <Text style={styles.text}>{text}</Text>
                                                    </>
                                                )
                                            })
                                        }
                                        {
                                            images.length > 0 ? (
                                                <>
                                                    <Text style={styles.text}>Images :</Text>
                                                    <View style={styles.imageContainer}>
                                                        {images.map((url, index) => (
                                                            <View style={styles.imageDiv}>
                                                                <Image style={styles.image} src={url} />
                                                            </View>
                                                        ))}
                                                    </View>
                                                </>
                                            ) : ""
                                        }

                                    </View>
                                </View>
                                // <HomeViewCard title={data.title} images={images} text={formattedText} />
                            )
                        })
                    }
                    {/* {list.map((item, indexCategory) => {
                        const images = item.images.map((image) => {
                            return burl + "/uploads/" + image
                        })
                        return (
                            <>
                                <Text style={styles.sectionText}>{item.title}</Text>
                                {item.content.map((item, indexSection) => {
                                    return (
                                        <>

                                            <Text style={styles.category}>{item.title}</Text>
                                            <View style={styles.categoryView}>
                                                {item.type === "text" ? (
                                                    item.fields.map((field, indexSingle) => {
                                                        if (field.type === "check") {
                                                            switch (field.value) {
                                                                case 'false':
                                                                    field.value = false
                                                                    break;
                                                                case 'true':
                                                                    field.value = true
                                                                default:
                                                                    break;
                                                            }
                                                        }
                                                        let inputDiv = `inputDiv${field.type}`;
                                                        let areaDiv = `areaDiv${field.type}`;
                                                        let iconDiv = `iconDiv${field.type}`;
                                                        return (
                                                            <>
                                                                <View break style={[styles.inputDiv, styles[inputDiv]]}>
                                                                    <View style={[styles.iconDiv, styles[iconDiv]]}>
                                                                        <Text style={styles.iconText}>{field.title}</Text>
                                                                    </View>
                                                                    <View style={[styles.areaDiv, styles[areaDiv]]}>
                                                                        {field.type === "input" ? (
                                                                            <Text style={styles.input}>{field.value}</Text>
                                                                        )
                                                                            : field.type === "textarea" ? (
                                                                                <Text style={styles.input}>{field.value}</Text>
                                                                            )
                                                                                : field.type === "check" ? (
                                                                                    <Image src={field.value ? "https://i.ibb.co/sQTd23q/check-Black.png" : "https://i.ibb.co/LzK7SdM/cross-svgrepo-com-1.png"}
                                                                                        style={[styles.input, styles.inputImage]}></Image>
                                                                                ) : (
                                                                                    <Text style={styles.input}>{field.value === "" ? field.options[0] : field.value}</Text>
                                                                                )}
                                                                    </View>
                                                                </View>

                                                            </>)


                                                    })
                                                ) : (

                                                    <>
                                                        {
                                                            item.fields.map((field, indexSingle) => {
                                                                let cssClass
                                                                item.value ? cssClass = "" : cssClass = " d-none"
                                                                return (

                                                                    <View break style={[styles.inputDiv, styles.inputDiv + field.type]}>
                                                                        <View style={styles.iconDiv}>
                                                                            <Text style={styles.iconText}>{field.title}</Text>
                                                                        </View>
                                                                        <View style={[styles.areaDiv,]}>
                                                                            {field.type === "input" ? (
                                                                                <Text style={styles.input}>{field.value}</Text>
                                                                            )
                                                                                : field.type === "textarea" ? (
                                                                                    <Text style={styles.input}>{field.value}</Text>
                                                                                )
                                                                                    : field.type === "check" ? (
                                                                                        <Image src={field.value ? "https://i.ibb.co/sQTd23q/check-Black.png" : "https://i.ibb.co/LzK7SdM/cross-svgrepo-com-1.png"}
                                                                                            style={[styles.input, styles.inputImage]}></Image>
                                                                                    ) : (
                                                                                        <Text style={styles.input}>{field.value === "" ? field.options[0] : field.value}</Text>
                                                                                    )}
                                                                        </View>
                                                                    </View>

                                                                )

                                                            })
                                                        }
                                                    </>
                                                )}
                                            </View>
                                        </>
                                    );
                                })}
                                <Text style={styles.category}>Images</Text>
                                <View style={styles.imageContainer}>
                                    {images.map((url, index) => (
                                        <View style={styles.imageDiv}>
                                            <Image style={styles.image} src={url} />
                                        </View>
                                    ))}
                                </View>
                            </>
                        )
                    })} */}
                </View>
            </Page>
        </Document>
    )
}

