import React, { useState, useEffect, useContext } from 'react';
import Spinner from "../../Components/Spinner";
import UserContext from '../../dataContext';
import "../../assets/scss/Billing/bills.scss";
import "../../assets/scss/Billing/billingPopup.scss";
import "../../assets/scss/Billing/table.scss";
import { BsCloudDownloadFill, BsFillPencilFill, BsSearch, } from "react-icons/bs";
import PopUp from './Common/PopUp';
import ProductsPopUp from './Common/ProductsPopUp';
import ClientPopUp from './Common/ClientPopUp';
import MyDocument from './PDFs/CommonPDF';
import { PDFViewer, usePDF } from '@react-pdf/renderer';

export default function Bills() {
    const [loading, setLoading] = useState(true);
    const [bills, setBills] = useState([]);
    const userContext = useContext(UserContext);
    const [rateLimiter, setRateLimiter] = useState(0);
    const [search, setSearch] = useState("");
    const [showPopUp, setShowPopUp] = useState(false);
    const [popUpContent, setPopUpContent] = useState({});
    const [contacts, setContacts] = useState([]);
    const [showClientPopUp, setShowClientPopUp] = useState(false);
    const [clientId, setClientId] = useState("");
    const [reference, setReference] = useState("");
    const [action, setAction] = useState("");
    const [showProductPopUp, setShowProductPopUp] = useState(false);
    const [document, setDocument] = useState([]);
    const [siret, setSiret] = useState("");
    const [payingMethod, setPayingMethod] = useState("");
    const [clientName, setClientName] = useState("");
    const [fullName, setFullName] = useState("");
    const [address, setAddress] = useState({
        roadAndNumber: "",
        complement: "",
        zipCode: "",
        city: "",
        country: ""
    });
    const [fullAddress, setFullAddress] = useState({
        roadAndNumber: "",
        zipCode: "",
        city: "",
        country: ""
    });
    const [fullSecondAddress, setFullSecondAddress] = useState({
        roadAndNumber: "",
        zipCode: "",
        city: "",
        country: ""
    });
    const [billingAddress, setBillingAddress] = useState({
        roadAndNumber: "",
        zipCode: "",
        city: "",
        country: "",
        isTheSameAsFullAddress: false
    });
    const [isTheSameAsFullAddress, setIsTheSameAsFullAddress] = useState(false);

    useEffect(() => {
        if (bills.length > 0 || rateLimiter === 1) return;
        userContext.apiReducer("getBills")
            .then((response) => {
                setBills(response);
                setLoading(false);
                setRateLimiter(1);
                console.log(response, "response");
            })
            .catch((err) => {
                console.log(err, "error");
            });
    }, [bills, rateLimiter, userContext]);

    useEffect(() => {
        if (contacts.length > 0) return;
        userContext.apiReducer("getRealClients")
            .then((response) => {
                let filteredResponse = response.clients.filter((client) => client !== false);
                let sortedContacts = sortContacts(filteredResponse, "abc", "clientId");
                sortedContacts = sortContacts(sortedContacts, "abc", "lastName");
                setContacts(sortedContacts);
            })
            .catch((err) => {
                console.log(err, "error");
            });
    }, [contacts, userContext]);

    function sortContacts(list, method, key) {
        switch (method) {
            case "abc":
                if (key) {
                    list.sort((a, b) => {
                        if (a[key] < b[key]) return -1;
                        if (a[key] > b[key]) return 1;
                        return 0;
                    });
                }
                break;
            default:
                break;
        }
        return list;
    }

    function handleModif(modified, action) {
        switch (action) {
            case "addBill":
                setBills([...bills, modified]);
                break;
            case "modifyBill":
                setBills(bills.map(bill => bill._id === modified._id ? modified : bill));
                break;
            default:
                break;
        }
    }

    function formatDate(dateString, type) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        switch (type) {
            case 1:
                return `${day}/${month}/${year}`;
            case 2:
                return `${year}-${month}-${day}`;
            default:
                return `${day}/${month}/${year}`;
        }
    }

    const addProduct = (product) => {
        product.quantity = 1;
        setDocument([...document, product]);
    }

    if (loading) {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Factures</h1>
                    </div>
                </div>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                <h2 className="text-center">Chargement des factures...</h2>
            </div>
        );
    } else {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0 bills"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Factures</h1>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className="search-and-stuff d-flex justify-content-between align-items-center">
                            <div className="search">
                                <input type="text" id="formControlLg" className="form-control form-control-lg" placeholder='Rechercher un facture...' onInput={(e) => setSearch(e.target.value)} />
                            </div>
                            <button className="btn btn-primary ms-2" onClick={(e) => {
                                e.preventDefault();
                                setShowPopUp(true);
                                setAction("addBill")
                                setPopUpContent({ what: { type: "Devis", title: "Créer une facture" }, thing: { object: {} } });
                            }}>+</button>

                        </div>
                        <table className='table-maxxing'>
                            <thead>
                                <tr>
                                    <th scope="col"><span>État</span></th>
                                    <th scope="col"><span>Numéro</span></th>
                                    <th scope="col"><span>Date</span></th>
                                    <th scope="col"><span>Validité</span></th>
                                    <th scope="col"><span>Client</span></th>
                                    <th scope="col"><span>Référence</span></th>
                                    <th scope="col"><span>Montant HT</span></th>
                                    <th scope="col"><span>Montant TTC</span></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {bills?.map((bill, index) => {
                                    return (<BillSingle bill={bill} setPopUpContent={setPopUpContent} setAction={setAction} formatDate={formatDate} setShowPopUp={setShowPopUp} />
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div className='pdfffff'>
                    <PDFViewer>
                        <MyDocument bill={bills[0]} type={"bill"} />
                    </PDFViewer>
                </div> */}

                <PopUp
                    showPopUp={showPopUp}
                    popUpContent={popUpContent}
                    setShowPopUp={setShowPopUp}
                    userContext={userContext}
                    handleModif={handleModif}
                    formatDate={formatDate}
                    setShowClientPopUp={setShowClientPopUp}
                    clientPopUpId={clientId}
                    action={action}
                    setShowProductPopUp={setShowProductPopUp}
                    document={document}
                    setDocument={setDocument}
                    showProductPopUp={showProductPopUp}
                    reference={reference}
                    setReference={setReference}
                    siret={siret}
                    setSiret={setSiret}
                    address={address}
                    setAddress={setAddress}
                    type={"bill"}
                    payingMethod={payingMethod}
                    setPayingMethod={setPayingMethod}
                    fullAddress={fullAddress}
                    setFullAddress={setFullAddress}
                    fullSecondAddress={fullSecondAddress}
                    setFullSecondAddress={setFullSecondAddress}
                    billingAddress={billingAddress}
                    setBillingAddress={setBillingAddress}
                    isTheSameAsFullAddress={isTheSameAsFullAddress}
                    setIsTheSameAsFullAddress={setIsTheSameAsFullAddress}
                    setClientMail={setClientName}
                    clientMail={clientName}
                    fullName={fullName}
                    setFullName={setFullName}
                    setClientId={setClientId}
                    clientId={clientId}
                />

                <ProductsPopUp
                    showProductPopUp={showProductPopUp}
                    setShowProductPopUp={setShowProductPopUp}
                    addProduct={addProduct}
                    userContext={userContext}
                />

                <ClientPopUp
                    showClientPopUp={showClientPopUp}
                    setShowClientPopUp={setShowClientPopUp}
                    contacts={contacts}
                    setSiret={setSiret}
                    setFullAddress={setFullAddress}
                    setClientId={setClientId}
                    payingMethod={payingMethod}
                    setPayingMethod={setPayingMethod}
                    setFullSecondAddress={setFullSecondAddress}
                    setBillingAddress={setBillingAddress}
                    setIsTheSameAsFullAddress={setIsTheSameAsFullAddress}
                    setClientMail={setClientName}
                    setFullName={setFullName}
                />
            </div>
        )
    }
}

const BillSingle = ({ bill, index, setPopUpContent, setAction, formatDate, setShowPopUp }) => {
    const [PDFInstance, updatePDFInstance] = usePDF({ document: <MyDocument bill={bill} type={"bill"} /> })
    return (
        <tr key={index}>
            <td className={`less status ${bill.documentState === "Brouillon" ? "gray" : "green"}`}>{bill.documentState}</td>
            <td>{bill.code}</td>
            <td>{formatDate(bill.date)}</td>
            <td>{formatDate(bill.validity)}</td>
            <td>{bill.fullName}</td>
            <td>{bill.reference}</td>
            <td>{bill.totals?.totalPreTax}€</td>
            <td>{bill.totals?.total.toFixed()}€</td>
            <td>
                <button onClick={(e) => {
                    e.preventDefault();
                    setShowPopUp(true);
                    setAction("modifyBill")
                    setPopUpContent({ what: { type: "Devis", title: "Modifier un devis" }, thing: { object: bill } });
                }}>{bill.documentState === "Facturé" ? <BsSearch /> : <BsFillPencilFill />}</button>
                <a href={PDFInstance.url} download={`${bill.code}.pdf`}><BsCloudDownloadFill /></a>
            </td>
        </tr>
    )
}