import React from 'react'
import {Doughnut} from "react-chartjs-2";

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            position: 'right'
        },
        tooltip: {
            callbacks: {
                label: (tooltipItem) => {
                    return tooltipItem.label + " : " + tooltipItem.parsed + " €";
                }
            }
        }
    },
}
    , plugins = [{
    beforeDraw: function (chart) {
        let width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
        ctx.restore();
        let fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        let text = chart.config._config.data.datasets[0].data.reduce((a, b) => a + b, 0).toString() + " €",
            textX = Math.round((width - ctx.measureText(text).width) / 2 - chart.boxes[0].width / 2),
            textY = chart.boxes[0].height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
    }
}]

export default function ChartsPie(props) {
    return (
        <Doughnut data={props.data} options={options} plugins={plugins}/>
    )
}