import React from "react";
import UserContext from '../dataContext'
import { BsPersonCheckFill } from "react-icons/bs";
import { slugify } from "../Utils/functions";
import Spinner from "../Components/Spinner";

export default function Archives() {
    const userContext = React.useContext(UserContext)
    const [search, setSearch] = React.useState('')

    if (userContext.loading) {
        return (
            <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div className={"col-auto\n"}>
                        <h1>Archives</h1>
                    </div>
                </div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Téléphone</th>
                            <th>Email</th>
                            <th>Professionel</th>
                            <th>Client abonné</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={6}>
                                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    } else if (!userContext.loading) {
        const clients = userContext.data.people.Client?.sort((a, b) => a.lastName > b.lastName ? 1 : -1)
        const concierges = userContext.data.people.Concierge?.sort((a, b) => a.lastName > b.lastName ? 1 : -1)
        const prestataires = userContext.data.people.Prestataires?.sort((a, b) => a.lastName > b.lastName ? 1 : -1)
        return (
            <>
                <div className={"col border cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                    <div className={"row justify-content-between"}>
                        <div>
                            <h1>Archives</h1>
                            <br></br>
                            <h2 className="text-center">Filtrer dans les profils</h2>
                            <input type={"search"} id={"form1"}
                                className={"form-control w-48 mx-auto"} placeholder={"Rechercher parmis les profils"}
                                name={"search"}
                                autoComplete={"off"}
                                onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-around">
                    <div className={"w-48 border cardsContainer mx-1 p-2 mt-5"}>
                        <div className={"row justify-content-between"}>
                            <div className={"col-auto\n"}>
                                <h1>Clients archivés</h1>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Téléphone</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clients?.map((client, index) => {
                                    if (client.archive && (search === "" || slugify(client.lastName).includes(slugify(search)) || slugify(client.name).includes(slugify(search)))) {
                                        return (
                                            <tr key={index}>
                                                <td>{client.lastName}</td>
                                                <td>{client.name}</td>
                                                <td>{client.phone}</td>
                                                <td>{client.email}</td>
                                                <td><button className={'btn btn-outline-dark'} onClick={() => {
                                                    userContext.setError({
                                                        level: 'warning',
                                                        message: `Êtes vous sûr de vouloir restaurer le profil de ${client.name} ${client.lastName} ?`,
                                                        btn1Text: 'Annuler',
                                                        btn2Text: 'Confirmer',
                                                        btn2Action: () => {
                                                            userContext.apiReducer('modifyClient', { archive: false }, { email: client.email })
                                                                .then(() => {
                                                                    userContext.setError({
                                                                        level: 'warning',
                                                                        message: `Le profil de ${client.name} ${client.lastName} a bien été restauré.`,
                                                                        btn1Text: "D'accord",
                                                                        btn2hidden: true
                                                                    })
                                                                })
                                                        }
                                                    })
                                                }}><BsPersonCheckFill /></button></td>
                                            </tr>
                                        )
                                    }
                                    else return null
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className={"w-48 border cardsContainer mx-1 p-2 mt-5"}>
                        <div className={"row justify-content-between"}>
                            <div className={"col-auto\n"}>
                                <h1>Concierges archivés</h1>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Téléphone</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {concierges?.map((concierge, index) => {
                                    if (concierge.archive && (search === "" || slugify(concierge.lastName).includes(slugify(search)) || slugify(concierge.name).includes(slugify(search)))) {
                                        return (
                                            <tr key={index}>
                                                <td>{concierge.lastName}</td>
                                                <td>{concierge.name}</td>
                                                <td>{concierge.phone}</td>
                                                <td>{concierge.email}</td>
                                                <td><button className={'btn btn-outline-dark'} onClick={() => {
                                                    userContext.setError({
                                                        level: 'warning',
                                                        message: `Êtes vous sûr de vouloir restaurer le profil de ${concierge.name} ${concierge.lastName} ?`,
                                                        btn1Text: 'Annuler',
                                                        btn2Text: 'Confirmer',
                                                        btn2Action: () => {
                                                            userContext.apiReducer('modifyConcierge', { archive: false }, { email: concierge.email })
                                                                .then(() => {
                                                                    userContext.setError({
                                                                        level: 'warning',
                                                                        message: `Le profil de ${concierge.name} ${concierge.lastName} a bien été restauré.`,
                                                                        btn1Text: "D'accord",
                                                                        btn2hidden: true
                                                                    })
                                                                })
                                                        }
                                                    })
                                                }}><BsPersonCheckFill /></button></td>
                                            </tr>
                                        )
                                    }
                                    else return null
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className={"w-48 border cardsContainer mx-1 p-2 mt-5"}>
                        <div className={"row justify-content-between"}>
                            <div className={"col-auto\n"}>
                                <h1>Prestataires archivés</h1>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Téléphone</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {prestataires?.map((prestataire, index) => {
                                    if (prestataire.archive && (search === "" || slugify(prestataire.lastName)?.includes(slugify(search)) || slugify(prestataire.name)?.includes(slugify(search)))) {
                                        return (
                                            <tr key={index}>
                                                <td>{prestataire.lastName}</td>
                                                <td>{prestataire.name}</td>
                                                <td>{prestataire.phone}</td>
                                                <td>{prestataire.email}</td>
                                                <td><button className={'btn btn-outline-dark'} onClick={() => {
                                                    userContext.setError({
                                                        level: 'warning',
                                                        message: `Êtes vous sûr de vouloir restaurer le profil de ${prestataire.name} ${prestataire.lastName} ?`,
                                                        btn1Text: 'Annuler',
                                                        btn2Text: 'Confirmer',
                                                        btn2Action: () => {
                                                            userContext.apiReducer('modifyProvider', { archive: false }, { email: prestataire.email })
                                                                .then(() => {
                                                                    userContext.setError({
                                                                        level: 'warning',
                                                                        message: `Le profil de ${prestataire.name} ${prestataire.lastName} a bien été restauré.`,
                                                                        btn1Text: "D'accord",
                                                                        btn2hidden: true
                                                                    })
                                                                })
                                                        }
                                                    })
                                                }}><BsPersonCheckFill /></button></td>
                                            </tr>
                                        )
                                    }
                                    else return null
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
    else { console.log("exeption") }
}