import React, { useEffect, useState } from "react";
import UserContext from '../dataContext'
import Spinner from '../Components/Spinner';
import "../assets/scss/secteurs.scss";
import Secteur from "../Components/Gestion/Secteurs/Secteur";



export default function Secteurs() {
    const [ownerSearch, setOwnerSearch] = useState("")
    const [sectorsSearch, setSectorsSearch] = useState("")
    const [areLinksBeingEdited, setAreLinksBeingEdited] = useState(false)
    const userContext = React.useContext(UserContext)
    const [sectors, setSectors] = useState(userContext.data.sectors)
    const [selectedOwners, setSelectedOwners] = useState([])
    const [selectedSector, setSelectedSector] = useState(null)
    const getSectors = () => {
        userContext.loading = true
        userContext.apiReducer("getSectors")
            .then((response) => {
                userContext.data.sectors = response
                userContext.loading = false
                setSectors(response)
            })
    }

    if (!userContext.data.sectors) {
        getSectors()
        if (userContext.data.sectors === undefined) {
            userContext.data.sectors = []
        }
        else setSectors(userContext.data.sectors)
    }

    const handleOwnerClick = (e) => {
        const ownerDiv = e.target.closest('.cards.owner')
        if (!areLinksBeingEdited) return
        const ownerData = { mail: ownerDiv.dataset.mail, lastName: ownerDiv.dataset.name }
        const ownerExists = selectedOwners.some(owner => owner.email === ownerData.mail && owner.lastName === ownerData.lastName)
        if (ownerExists) {
            const updatedOwners = selectedOwners.filter((owner) => owner.email !== ownerData.mail || owner.lastName !== ownerData.lastName)
            setSelectedOwners(updatedOwners)
            ownerDiv.classList.remove('selected')
            return
        } else {
            setSelectedOwners(prev => [...prev, ownerData])
            const owner = ownerDiv
            owner.classList.toggle('selected')
        }
    };

    const deleteSector = (index) => {
        const updatedSectors = [...sectors];
        updatedSectors.splice(index, 1);
        setSectors(updatedSectors);
    };

    // useEffect to reset the selectedOwners when areLinksBeingEdited is toggled off and add selected to the owners divs that are in the selectedSector
    useEffect(() => {
        if (!areLinksBeingEdited) {
            setSelectedOwners([])
            const owners = document.querySelectorAll('.owner')
            owners.forEach(owner => {
                owner.classList.remove('selected')
            }
            )
        }
        else {
            selectedSector.clients.forEach(client => {
                const owner = document.querySelector(`.owner[data-mail="${client.email}"]`)
                owner?.classList.add('selected')
            })
            setSelectedOwners(selectedSector.clients)
        }
    }, [areLinksBeingEdited, selectedSector, setSelectedOwners])

    if (userContext.loading) {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Secteurs</h1>
                    </div>
                </div>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                <h2 className="text-center">Chargement...</h2>
            </div>
        )
    } else if (!userContext.loading) {
        return (
            <>
                <div className="secteurs">
                    <div className={"row justify-content-center"}>
                        <div className="">
                            <h1 className="blue fw-bold text-center">Secteurs</h1>
                            <div className={"cardsContainer cardShadow mx-auto p-2 mt-5"}> {/* div1 */}
                                <div className={"row justify-content-between gap-2"}>
                                    <div className="lists row flex-column align-items-center text-center">
                                        <div className="location">
                                            <h2 className="blue fw-bold">Liste des secteurs</h2>
                                            <div className="sectorsParent">
                                                <input type={"search"} id={"form1"}
                                                    className={"form-control mx-auto"} placeholder={"Rechercher parmis les secteurs"}
                                                    name={"search"} onChange={(e) => setSectorsSearch(e.target.value)}
                                                    autoComplete={"off"} />
                                                <div className="sectors">
                                                    {
                                                        sectors?.map((sector, index) => {
                                                            return (
                                                                <Secteur key={index} index={index} selectedOwners={selectedOwners}
                                                                    originalSector={sector} userContext={userContext} deleteSector={deleteSector}
                                                                    areLinksBeingEdited={areLinksBeingEdited} setAreLinksBeingEdited={setAreLinksBeingEdited}
                                                                    setSelectedSector={setSelectedSector} setSectors={setSectors}
                                                                    hidden={!(sector.name.toLowerCase().includes(sectorsSearch.toLowerCase()) || sector.description.toLowerCase().includes(sectorsSearch.toLowerCase()) || sector.file.toLowerCase().includes(sectorsSearch.toLowerCase()))} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="addSector">
                                                    <button className="btn btn-primary" onClick={() => {
                                                        setSectors([...sectors, { name: "Nouveau secteur", description: "", file: "", newSector: true }])
                                                    }}>Ajouter un secteur</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="owners">
                                            <h2 className="blue fw-bold">Propriétaires / propriétés</h2>
                                            <input type={"search"} id={"form1"}
                                                className={"form-control mx-auto"} placeholder={"Rechercher parmis les propriétaires"}
                                                name={"search"}
                                                autoComplete={"off"}
                                                onChange={(e) => setOwnerSearch(e.target.value)} />
                                            {
                                                userContext.data.people.Client.map((client, index) => {
                                                    if (client.archive || client.professional) { return null } else {
                                                        return (
                                                            <div hidden={!(client.name.toLowerCase().includes(ownerSearch.toLowerCase()) || client.lastName.toLowerCase().includes(ownerSearch.toLowerCase()))}
                                                                key={index} className={"cards owner" + (areLinksBeingEdited ? " editing" : "")} data-mail={client.email} data-name={client.lastName} onClick={handleOwnerClick}>
                                                                <div className="owner">
                                                                    <h4><span>{client.lastName}</span> {client.name}</h4>
                                                                    <p>{client.phone}</p>
                                                                    <p>{client.email}</p>
                                                                </div>
                                                                <div className="properties">
                                                                    {
                                                                        client.properties.map((property, index) => {
                                                                            let address = property.address.number + " " + property.address.road + ", " + property.address.zip + " " + property.address.city + ((property.address.other) ? `, ${property.address.other}` : "")
                                                                            return (
                                                                                <div key={index} className="property">
                                                                                    <h4>{property.name}</h4>
                                                                                    <p>{address}</p>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cardsContainer cardShadow p-2 mt-5 table-div"> {/* div2 */}
                                <div className={"row justify-content-between gap-2"}>
                                    <h2 className="blue fw-bold text-center">Liens des secteurs enregistrés</h2>
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nom du secteur</th>
                                                <th scope="col">Clients associés</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sectors?.map((sector, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="sector"><b>{sector.name}</b></td>
                                                            <td>
                                                                {
                                                                    sector?.clients?.map((client, index) => {
                                                                        return (
                                                                            <div key={index}>
                                                                                <p>{client.lastName} {client.name}</p>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    else { console.log("exeption") }
}