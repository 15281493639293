import { useEffect, useState, useContext } from "react"
import userContext from "../../../../../../dataContext"

export default function InventorySignature({ signatureDefault, who, inOrOut, signatureConsent }) {
    const Context = useContext(userContext)
    signatureDefault = signatureDefault || ""
    const [signature,] = useState(signatureDefault)

    useEffect(() => {
    }, [signature]);
    return (
        <div>
            <div className="signatures">
                {
                    signature
                        ? <img className={"signature"} alt='signature'
                            src={signature.startsWith('data:image') ? signature : Context.getBurl() + "/" + signature} />
                        : null
                } <br />
                <input value={signature} name={inOrOut + "-sign-" + who} type="hidden" />
                <input readOnly className="hiddenInput" type="text" defaultValue={signature === "" ? "" : "Une signature est renseignée"} required />
                {!(signatureConsent === "true" && typeof signatureConsent === "string") ? <div></div> : <p>Le locataire ne souhaite pas signer l'état des lieux.</p>}
            </div>
        </div>
    )
}