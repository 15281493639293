import * as Icons from 'react-icons/bs'
import "../../../assets/scss/sidebar.scss"

export default function MenuLink(props) {
    const Icon = Icons[props.icon];
    return (
        <li className={window.location.pathname === props.reference ? "active" : ""}>
            <a href={props.reference} className='header-link'>
                {/* <i className="fas fa-briefcase"/> */}
                {Icon ? <Icon /> : null}
                <span>{props.name}</span>
            </a>
        </li>
    )
}