import { useContext, useEffect, useState } from 'react'
import UserContext from '../../dataContext'
import Spinner from '../../Components/Spinner'
import "../../assets/scss/Billing/products.scss"
import { BsCloudDownloadFill, BsFillPencilFill, BsXLg } from 'react-icons/bs'

export default function Products() {
    const userContext = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    const [activeSidebar, setActiveSidebar] = useState(false)
    const [sidebarContent, setSidebarContent] = useState(null)
    const [search, setSearch] = useState("")

    // useEffect(() => {
    //     console.log(products)
    // }, [products])
    useEffect(() => {
        userContext.apiReducer("getProducts").then(productsList => {
            setLoading(false)
            productsList = sortProducts(productsList, "abc", "code")
            setProducts(productsList)
        })
    }, [userContext])

    function sortProducts(list, method, key) {
        switch (method) {
            case "abc":
                if (key) {
                    list.sort((a, b) => {
                        if (a[key] < b[key]) return -1;
                        if (a[key] > b[key]) return 1;
                        return 0;
                    });
                }
                break;

            default:
                break;
        }
        return list
    }

    function capitalizeText(text) {
        return text.toLowerCase().replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }

    if (loading) {
        return (
            <div className={"col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Produits</h1>
                    </div>
                </div>
                <Spinner strokeWidth={9} width={40} color={"#003952"} />
                <h2 className="text-center">Chargement des produits...</h2>
            </div>
        )
    }
    else if (!loading) {
        return (
            <div className={"products col cardsContainer mx-1 p-2 mt-3 mt-md-0"}>
                <div className={"row justify-content-between"}>
                    <div>
                        <h1 className="blue fw-bold text-center">Produits</h1>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className="search-and-stuff d-flex justify-content-between align-items-center">
                            <button className="btn btn-primary ms-2" onClick={(e) => {
                                e.preventDefault();
                            }}><BsCloudDownloadFill /></button>
                            <div className="search">
                                <input type="text" id="formControlLg" className="form-control form-control-lg" placeholder='Rechercher un produit...' onInput={(e) => setSearch(e.target.value)} />
                            </div>
                            <button className="btn btn-primary ms-2" onClick={(e) => {
                                e.preventDefault();
                            }}>+</button>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th scope='col'>
                                        <span>

                                        </span>
                                    </th>
                                    <th scope='col'>
                                        <span>
                                            Code
                                        </span>
                                    </th>
                                    <th scope='col'>
                                        <span>
                                            Libellé
                                        </span>
                                    </th>
                                    <th scope='col'>
                                        <span>
                                            Type
                                        </span>
                                    </th>
                                    <th scope='col'>
                                        <span>
                                            Famille
                                        </span>
                                    </th>
                                    <th scope='col'>
                                        <span>
                                            Prix HT (€)
                                        </span>
                                    </th>
                                    <th scope='col'>
                                        <span>
                                            Prix TTC (€)
                                        </span>
                                    </th>
                                    <th scope='col'>
                                        <span>
                                            Unité
                                        </span>
                                    </th>
                                    <th scope='col'>
                                        <span>

                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {products?.map((product, index) => {
                                    // console.log(product, "product")
                                    if (!product.code) return null
                                    else if (search && !product.label.toLowerCase().includes(search.toLowerCase())) return null
                                    return (
                                        <tr data-num-ligne="0" class="" key={index} onClick={() => {
                                            setActiveSidebar(true)
                                            setSidebarContent({ thing: product, what: "Produit" })
                                        }} >
                                            <td>
                                                <div class="display-flex flex-align-items-center">
                                                    <input type="checkbox" name={"j-select-changement-statut-" + index} class="rca-legacy j-select-changement-statut" data-id="15612346" />
                                                </div>
                                            </td>
                                            <td data-label="Code" class="lesser " title="Afficher l'aperçu de l'article">
                                                <span class="recherche-selected  j-dont-close-sliding-panel"></span>{product.code?.toString().padStart(8, '0')}
                                            </td>
                                            <td data-label="Libellé" class="full" title="Afficher l'aperçu de l'article">
                                                <span class="recherche-selected "></span>{product.label}
                                            </td>
                                            <td data-label="Type" class="lesser capitalize-text" title="Afficher l'aperçu de l'article">
                                                <span class="recherche-selected  j-dont-close-sliding-panel"></span>{capitalizeText(product.type)}
                                            </td>
                                            <td data-label="Famille" class="lesser" title="Afficher l'aperçu de l'article">
                                                <span class="recherche-selected  j-dont-close-sliding-panel"></span>{product.family}</td>
                                            <td data-label="Prix HT (€)" class="lesser text-right" title="Afficher l'aperçu de l'article">
                                                {product.price}
                                            </td>
                                            <td data-label="Prix TTC (€)" class="lesser text-right" title="Afficher l'aperçu de l'article">
                                                {product.priceFull}
                                            </td>
                                            <td data-label="Unité" class="lesser" title="Afficher l'aperçu de l'article">
                                                <span class="recherche-selected  j-dont-close-sliding-panel"></span>
                                            </td>
                                            <td class="text-right width-1">
                                                <a href='/billing/products' title='Modifier'><BsFillPencilFill /></a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id='product-sidebar' className={'col' + (activeSidebar ? " active" : "")}>
                    <div className='header'>
                        <button className="close-sidebar" onClick={() => setActiveSidebar(false)}><BsXLg /></button>
                        <button className="edit-sidebar" onClick={() => setActiveSidebar(false)}><BsFillPencilFill /></button>


                        {sidebarContent ? <h2>{sidebarContent.what}</h2> : ""}
                    </div>
                    {sidebarContent ?
                        <div className='content'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h2>{sidebarContent.thing.code + " - " + sidebarContent.thing.label}</h2>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <ul>
                                        <li><span>Description : </span>{sidebarContent.thing.description}</li>
                                        <li><span>Type :</span> {capitalizeText(sidebarContent.thing.type)}</li>
                                        <li><span>Famille :</span> {sidebarContent.thing.family}</li>
                                        <li><span>Prix HT :</span> {sidebarContent.thing.price}€</li>
                                        <li><span>Prix TTC :</span> {sidebarContent.thing.priceFull}€</li>
                                        <li><span>TVA :</span> {sidebarContent.thing.taxes}% </li>
                                        <li><span>Unité :</span> {sidebarContent.thing.quantity}</li>
                                        <li><span>Compte comtable :</span> {sidebarContent.thing.account}</li>
                                        <li><span>Actif :</span> {sidebarContent.thing.state ? "Oui" : "Non"}</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        : ""}
                </div>
            </div>
        )
    }
}