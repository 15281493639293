import React from 'react'

export default function SendButton(props) {
    if (!props.loading) {
        return (
            <button className={"btn btn-light w-100"} {...props}>{props.children}</button>
        )
    } else {
        return (
            <div className={"btn btn-light w-100"} {...props}>
                <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }
}